import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, { useState, useContext, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'

import {
  Header,
  Icon,
  Dimmer,
  Loader,
  Modal,
  Button,
  Message,
} from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'
import {
  NOT_PAYMENT_TICKET_STATUS,
  OFFER_DISABLED_STATUS,
} from '../lib/constants'

import ResponsiveTable from './ResponsiveTable'

const SORT_OPTIONS = [
  {
    key: 'offerTitle',
    text: 'オファー',
    value: 'offer.title',
  },
  {
    key: 'coachName',
    text: 'コーチ',
    value: 'offfer.coach.nickname',
  },
  {
    key: 'status',
    text: 'ステータス',
    value: 'status',
  },
  {
    key: 'createdAt',
    text: '作成日時',
    value: 'createdAt',
  },
  {
    key: 'price',
    text: '単価',
    value: 'price',
  },
  {
    key: 'duration',
    text: '時間',
    value: 'duration',
  },
  {
    key: 'number',
    text: '個数',
    value: 'number',
  },
]

const SUCCESS_MESSAGE = 'お支払いが完了しました。'
const ERROR_MESSAGE =
  'お支払いに失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default () => {
  const { db, currentUser, functions } = useContext(FirebaseContext)
  const [tickets, setTickets] = useState({})
  const [isBusy, setIsBusy] = useState(true)
  const [resultMessage, setResultMessage] = useState(null)
  const [selectedTicket, setSelectedTicket] = useState('')
  const [cardInfo, setCardInfo] = useState({})

  useEffect(() => {
    const ticketsUnsub = db
      .collection('tickets')
      .where('player.id', '==', _.get(currentUser, 'uid', ''))
      .onSnapshot((snap) => setTickets(querySnapToObj(snap)))
    ;(async () => {
      if (!currentUser) {
        setCardInfo({})
        return
      }
      const { data } = await functions.httpsCallable(
        'getCustomerInfoInStripe'
      )()
      setCardInfo(data)
      setIsBusy(false)
    })()
    return () => ticketsUnsub()
  }, [db, currentUser, functions])

  if (!currentUser) return <Redirect to='/' />

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const onSubmitPayment = async (ticket) => {
    setIsBusy(true)
    try {
      await functions.httpsCallable('paymentTicket')(ticket)
      setResultMessage(SUCCESS_MESSAGE)
    } catch (e) {
      console.error(e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusy(false)
    setSelectedTicket('')
  }

  const onSubmitCancel = async (ticketId) => {
    setIsBusy(true)
    try {
      await functions.httpsCallable('cancelTicket')({ ticketId })
    } catch (e) {
      console.error(e)
    }
    setIsBusy(false)
  }

  const paymentModalContent = (ticket) => (
    <>
      <Modal.Header content='【確認】必ずコーチング直前に支払い処理をしてください。' />
      <Modal.Content>
        <Header>チケット情報</Header>
        <p>オファー: {ticket.offer.title}</p>
        <p>コーチ: {ticket.offer.coach.nickname}</p>
        <p>時間: {numeral(ticket.duration).format('0,0')}分</p>
        <p>単価: {numeral(ticket.price).format('0,0')}円</p>
        <p>個数: {numeral(ticket.number).format('0,0')}個</p>
        <strong>
          合計:
          {numeral(ticket.number * ticket.price).format('0,0')}円/
          {numeral(ticket.number * ticket.duration).format('0,0')}分
        </strong>
        <Header>クレジットカード情報</Header>
        <p>
          {cardInfo.brand} ****{cardInfo.last4}
        </p>
        <p>
          有効期限: {moment(cardInfo.exp_month).format('MM')}/
          {cardInfo.exp_year}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color='teal'
          onClick={() =>
            onSubmitPayment({
              ticketId: ticket.id,
              offerId: ticket.offer.id,
              coachId: ticket.offer.coach.id,
              description: `${ticket.offer.title} / ${ticket.offer.coach.nickname}`,
              amount: ticket.price * ticket.number,
            })
          }>
          <Icon name='checkmark' />
          お支払いをする
        </Button>
      </Modal.Actions>
    </>
  )

  return (
    <>
      {isBusy && (
        <Dimmer active page>
          <Loader>処理中</Loader>
        </Dimmer>
      )}

      <Header as='h2' color='teal' textAlign='center'>
        <Icon name='handshake outline' />
        <Header.Content>予約・購入済みのチケット</Header.Content>
      </Header>

      {messageType}

      <ResponsiveTable
        data={tickets}
        columns={[
          {
            text: 'オファー',
            name: 'offer.title',
            itemRender: (ticket) => (
              <Link to={`/offers/${_.get(ticket, 'offer.id')}`}>
                {_.get(ticket, 'offer.title')}
              </Link>
            ),
          },
          {
            text: 'コーチ',
            name: 'offfer.coach.nickname',
            itemRender: (ticket) => (
              <Link to={`/users/${_.get(ticket, 'offer.coach.id')}`}>
                {_.get(ticket, 'offer.coach.nickname')}
              </Link>
            ),
          },
          {
            text: 'ステータス',
            name: 'status',
            itemRender: (ticket) => _.get(ticket, 'status'),
          },
          {
            text: '作成日時',
            name: 'createdAt',
            itemRender: (ticket) =>
              _.get(ticket, 'createdAt') &&
              moment(ticket.createdAt.toDate()).format('YYYY/MM/DD HH:mm'),
          },
          {
            text: '単価',
            name: 'price',
            itemRender: (tikect) =>
              _.get(tikect, 'price') && (
                <>{numeral(tikect.price).format('0,0')} &nbsp;円</>
              ),
          },
          {
            text: '時間',
            name: 'duration',
            itemRender: (ticket) =>
              _.get(ticket, 'duration') && (
                <>{numeral(ticket.duration).format('0,0')} &nbsp;分</>
              ),
          },
          {
            text: '個数',
            name: 'number',
            itemRender: (ticket) => <>{_.get(ticket, 'number', 0)}&nbsp;個</>,
          },
          {
            text: 'レビュー投稿',
            name: 'toPostReviewPage',
            textAlign: 'center',
            itemRender: (ticket) => (
              <Link to={`/new-review/reviewee/${ticket.offer.coach.id}/coach`}>
                <Icon name='edit' style={{ cursor: 'pointer' }} />
              </Link>
            ),
          },
          {
            text: 'お支払い',
            name: 'toCheckoutPage',
            textAlign: 'center',
            itemRender: (ticket) => {
              if (ticket.offer.status === OFFER_DISABLED_STATUS) {
                return
              }
              return _.get(ticket, 'status') === NOT_PAYMENT_TICKET_STATUS ? (
                <Modal
                  open={selectedTicket === ticket.id}
                  onOpen={async () => setSelectedTicket(ticket.id)}
                  onClose={() => setSelectedTicket('')}
                  trigger={
                    <Icon
                      name='share square'
                      style={{ cursor: 'pointer', color: '#0066CC' }}
                    />
                  }>
                  {paymentModalContent(ticket)}
                </Modal>
              ) : (
                <Icon name='check' />
              )
            },
          },
          {
            text: 'キャンセル',
            name: 'cancelTicket',
            textAlign: 'center',
            itemRender: (ticket) => {
              if (ticket.offer.status === OFFER_DISABLED_STATUS) {
                return
              }
              return (
                _.get(ticket, 'status') === NOT_PAYMENT_TICKET_STATUS && (
                  <Modal
                    trigger={
                      <Icon
                        name='share square'
                        style={{ cursor: 'pointer', color: '#0066CC' }}
                      />
                    }>
                    <Header content='チケット予約のキャンセル' />
                    <Modal.Content>
                      <p>この操作は取り消しできませんが実行しますか？</p>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color='teal'
                        onClick={() => onSubmitCancel(ticket.id)}>
                        <Icon name='checkmark' />
                        はい
                      </Button>
                    </Modal.Actions>
                  </Modal>
                )
              )
            },
          },
        ]}
        sortOptions={SORT_OPTIONS}
        defaultSortColumn={'createdAt'}
        defaultSortDirection={'descending'}
        searchTargets={(ticket) => [
          ticket.offer.title,
          ticket.status,
          ticket.offer.coach.nickname,
        ]}
      />
    </>
  )
}
