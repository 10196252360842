export const TICKET_STATUS = ['未決済', '未消費', '消費済み', 'キャンセル']

export const NOT_PAYMENT_AND_CANCEL_TICKET_STATUS = ['未決済', 'キャンセル']

export const NOT_CONSUMED_AND_CONSUMED_TICKET_STATUS = ['未消費', '消費済み']

export const NOT_PAYMENT_TICKET_STATUS = '未決済'

export const TAG_TYPE = ['ゲームタイトル', 'ターゲット', 'プラットフォーム']

// タグ作成時に画像が必要なタグ名
export const TAG_THAT_REQUIRE_IMGAE = 'ゲームタイトル'

// オファー作成時必須タグ
export const REQUIRE_TAG = 'ゲームタイトル'

// ゲームタイトルのタグ名
export const GAMETITLE_TAG_NAME = 'ゲームタイトル'

export const OFFER_STATUS = ['有効', '無効']

export const OFFER_ABLED_STATUS = '有効'

export const OFFER_DISABLED_STATUS = '無効'

export const NEWS_STATUS = ['有効', '無効']

export const NEWS_DISABLED_STATUS = '無効'

export const NEWS_ABLED_STATUS = '有効'
