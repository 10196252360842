// import moment from 'moment'
import React, { useEffect, useState, useContext } from 'react'
// import { Link } from 'react-router-dom'
import { Header, Icon, Divider, Button } from 'semantic-ui-react'
import OfferList from './OfferList'
import IntroVideos from './IntroVideos'
import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'
import { OFFER_ABLED_STATUS } from '../lib/constants'
// import NewsUpdates from './NewsUpdates'

export default () => {
  const { db } = useContext(FirebaseContext)
  const [offers, setOffers] = useState({})

  useEffect(() => {
    ;(async () => {
      const offerSnap = await db
        .collection('offers')
        .where('status', '==', OFFER_ABLED_STATUS)
        .orderBy('createdAt', 'desc')
        .limit(3)
        .get()

      setOffers(querySnapToObj(offerSnap))
    })()
    return () => {}
  }, [db])

  return (
    <>
      <div
      // style={{
      //   borderTop: 'thick solid teal',
      //   marginTop: 5,
      //   marginBottom: 20,
      //   width: '100%',
      //   height: 0,
      //   paddingTop:
      //     'calc(300 / 1280 * 100%)' /* calc(画像高さ ÷ 画像横幅 × 100%) */,
      //   background:
      //     'url(https://images.unsplash.com/photo-1534488972407-5a4aa1e47d83?ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&h=300&q=80) center center / cover no-repeat',
      // }}
      />
      <IntroVideos />
      {/* <Header as='h2' color='teal' textAlign='center'>
        <Icon name='file alternate' />
        <Header.Content id='newsUpdateHeader'>NEWS UPDATE</Header.Content>
      </Header>
      <NewsUpdates />
      <div style={{ textAlign: 'right' }}>
        <a href='https://beefreee.jp/blog/' rel='noreferrer' target='_blank'>
          <Button>VIEW MORE</Button>
        </a>
      </div> */}
      {/* <List size='large' relaxed='very'>
        {_.map(news, (v) => (
          <List.Item key={v.id}>
            {moment(v.createdAt.toDate()).format('YYYY.MM.DD')}
            <Link to={`/news?id=${v.id}`} style={{ marginLeft: 10 }}>
              {v.title}
            </Link>
          </List.Item>
        ))}
      </List>

      <div style={{ textAlign: 'right' }}>
        <Link to='/news'>
          <Button>VIEW MORE</Button> ---2021/07/21 Blogsページ故障のため、NEWS機能停止----
        </Link>
      </div> */}

      <Divider style={{ marginTop: 10 }} />

      <Header as='h2' color='teal' textAlign='center'>
        <Icon name='gamepad' />
        <Header.Content>最新のオファー</Header.Content>
      </Header>
      {/* <OfferList offers={offers} /> */}
      <OfferList offers={offers} />
      <br></br>
      <div style={{ textAlign: 'right' }}>
        <a href='https://joz.jp/categories' rel='noreferrer' target='_blank'>
          <Button>VIEW MORE</Button>
        </a>
      </div>
    </>
  )
}
