import _ from 'lodash'
import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'

import { Tab, Header, Icon } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'

import MyOfferList from './MyOfferList'
import CreateOffer from './CreateOffer'

export default () => {
  const { claims } = useContext(FirebaseContext)
  if (!_.get(claims, 'isCoach', false)) return <Redirect to='/' />
  const panes = [
    {
      menuItem: '作成済みのオファー',
      render: () => (
        <Tab.Pane as='div'>
          <MyOfferList />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'オファー作成',
      render: () => (
        <Tab.Pane as='div'>
          <CreateOffer />
        </Tab.Pane>
      ),
    },
  ]
  return (
    <>
      <Header as='h2' color='teal' textAlign='center'>
        <Icon name='book' />
        <Header.Content>オファー管理</Header.Content>
      </Header>
      <Tab
        style={{ color: '#00000' }}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </>
  )
}
