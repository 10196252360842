import _ from 'lodash'
import moment from 'moment'
import LinesEllipsis from 'react-lines-ellipsis'
import React, { useEffect, useState, useContext } from 'react'
import { Link, Redirect } from 'react-router-dom'

import { Header, Icon, Table, Form } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'

export default () => {
  const { db, claims } = useContext(FirebaseContext)
  const [reviews, setReviews] = useState({})
  const [searchFilter, setSearchFilter] = useState('')
  const [sortColumn, setSortColumn] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState('descending')

  useEffect(() => {
    const reviewsUnsub = db
      .collection('reviews')
      .onSnapshot((snap) => setReviews(querySnapToObj(snap)))

    return () => {
      reviewsUnsub()
    }
  }, [db])

  if (!_.get(claims, 'isAdmin', false)) return <Redirect to='/' />

  const handleSort = (clickedColumn) => {
    if (clickedColumn !== sortColumn) {
      setSortColumn(clickedColumn)
      setSortDirection('descending')
      return
    }
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
  }

  const reviewsStatsRows = _(reviews)
    .filter(
      searchFilter
        ? (review) => {
            // serchFilterの大文字、小文字を無視して検索
            const regexp = new RegExp(searchFilter, 'gi')
            return review.message.match(regexp)
          }
        : (review) => review
    )
    .sortBy(sortColumn)
    .thru((v) => (sortDirection === 'descending' ? _.reverse(v) : v))
    .map((review) => (
      <Table.Row key={review.id}>
        <Table.Cell>{review.title}</Table.Cell>
        <Table.Cell>
          <LinesEllipsis
            text={review.message}
            ellipsis='...'
            trimRight
            basedOn='letters'
          />
        </Table.Cell>
        <Table.Cell collapsing>
          <Link to={`/users/${review.reviewer.id}`}>
            {review.reviewer.nickname}
          </Link>
        </Table.Cell>
        <Table.Cell collapsing>
          <Link to={`/users/${review.reviewee.id}`}>
            {review.reviewee.nickname}
          </Link>
        </Table.Cell>
        <Table.Cell>{review.rating}</Table.Cell>
        <Table.Cell>
          {review.createdAt &&
            moment(review.createdAt.toDate()).format('YYYY/MM/DD HH:mm')}
        </Table.Cell>
        <Table.Cell textAlign='center'>
          {!review.isVisible && <Icon name='check' />}
        </Table.Cell>
        <Table.Cell textAlign='center'>
          <Link to={`/admin/reviews/${review.id}/edit`}>
            <Icon name='edit' style={{ cursor: 'pointer' }} />
          </Link>
        </Table.Cell>
      </Table.Row>
    ))
    .value()

  return (
    <>
      <Header as='h2' color='teal' textAlign='center'>
        <Icon name='comment outline' />
        <Header.Content>レビュー管理</Header.Content>
      </Header>

      <Form>
        <Form.Input
          iconPosition='left'
          icon='search'
          placeholder='本文で検索...'
          name='searchFilter'
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
      </Form>

      <Table compact='very' size='small' celled structured sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'title' ? sortDirection : null}
              onClick={() => handleSort('title')}>
              タイトル
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'message' ? sortDirection : null}
              onClick={() => handleSort('message')}>
              本文
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'reviewer.nickname' ? sortDirection : null}
              onClick={() => handleSort('reviewer.nickname')}>
              投稿者
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'reviewee.nickname' ? sortDirection : null}
              onClick={() => handleSort('reviewee.nickname')}>
              対象者
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'rating' ? sortDirection : null}
              onClick={() => handleSort('rating')}>
              評価
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'createdAt' ? sortDirection : null}
              onClick={() => handleSort('createdAt')}>
              作成日時
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign='center'
              sorted={sortColumn === 'isVisible' ? sortDirection : null}
              onClick={() => handleSort('isVisible')}>
              非表示
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>編集</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{reviewsStatsRows}</Table.Body>
      </Table>
    </>
  )
}
