import _ from 'lodash'
import moment from 'moment'
import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Icon, Table, Dropdown } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'
import { NEWS_STATUS } from '../lib/constants'

const NEWS_STATUS_OPTIONS = _.map(NEWS_STATUS, (s) => ({
  key: s,
  value: s,
  text: s,
}))

export default () => {
  const { db } = useContext(FirebaseContext)
  const [news, setNews] = useState({})

  useEffect(() => {
    const newsUnsub = db
      .collection('news')
      .orderBy('createdAt', 'desc')
      .onSnapshot((snap) => setNews(querySnapToObj(snap)))
    return () => newsUnsub()
  }, [db])

  const changeStatus = (v, value) => {
    try {
      db.doc(`news/${v.id}`).update({
        status: value,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const newsRows = _.map(news, (v) => (
    <Table.Row key={v.id}>
      <Table.Cell style={{ color: 'black' }}>{v.title}</Table.Cell>
      <Table.Cell>
        <Dropdown
          value={v.status}
          options={NEWS_STATUS_OPTIONS}
          onChange={(e, { value }) => changeStatus(v, value)}
        />
      </Table.Cell>
      <Table.Cell style={{ color: 'black' }}>
        {moment(v.createdAt.toDate()).format('YYYY/MM/DD HH:mm')}
      </Table.Cell>
      <Table.Cell style={{ color: 'black' }} textAlign='center'>
        <Link to={`/admin/news/${v.id}/edit`}>
          <Icon name='edit' style={{ cursor: 'pointer' }} />
        </Link>
      </Table.Cell>
    </Table.Row>
  ))

  return (
    <>
      <Table compact celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>タイトル</Table.HeaderCell>
            <Table.HeaderCell>ステータス</Table.HeaderCell>
            <Table.HeaderCell>作成日</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>編集</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{newsRows}</Table.Body>
      </Table>
    </>
  )
}
