import _ from 'lodash'

export const querySnapToObj = (snap) =>
  _.transform(
    snap.docs,
    (result, doc) => {
      result[doc.id] = _.assign(doc.data(), { id: doc.id })
      return result
    },
    {}
  )

export const putIdInData = (data) =>
  _.transform(
    data,
    (result, v, k) => {
      result[k] = Object.assign(v, { id: k })
      return result
    },
    {}
  )

export const authErrorMessage = (error) => {
  switch (error.code) {
    case 'auth/email-already-in-use':
      return 'メールアドレスが既に使用されています。'
    case 'auth/invalid-email':
      return '無効なメールアドレスです。'
    case 'auth/user-disabled':
      return 'そのユーザは凍結されています。'
    case 'auth/user-not-found':
      return 'そのメールアドレスは未登録です。'
    default:
      return error.code
  }
}
