import React, { Component } from 'react'

class IntroVideos extends Component {
  render() {
    return (
      <div>
        <div>
          <div onContextMenu={(e) => e.preventDefault()}>
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{
                width: '100%',
                borderradius: '20px',
              }}>
              <source
                borderradius='20px'
                src='./assets/vid1.mp4'
                type='video/mp4'></source>
            </video>
          </div>
        </div>
      </div>
    )
  }
}
export default IntroVideos
