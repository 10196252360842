import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import papa from 'papaparse'
import React, { useContext, useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import {
  Header,
  Icon,
  Table,
  Dropdown,
  Label,
  Form,
  Menu,
  Button,
} from 'semantic-ui-react'
import { DatesRangeInput } from 'semantic-ui-calendar-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'
import { TICKET_STATUS, GAMETITLE_TAG_NAME } from '../lib/constants'

import TagLabels from './TagLabels'

const TICKET_STATUS_OPTIONS = _.map(TICKET_STATUS, (s) => ({
  key: s,
  value: s,
  text: s,
}))

export default () => {
  const { firebase, db, claims } = useContext(FirebaseContext)
  const [tickets, setTickets] = useState({})
  const [users, setUsers] = useState({})
  const [usersPublic, setUsersPublic] = useState({})
  const [tags, setTags] = useState({})
  const [selectedCoachId, setSelectedCoachId] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [sortColumn, setSortColumn] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState('descending')
  const [selectedFilterDateType, setSelectedFilterDateType] = useState(
    '作成日時'
  )
  const [datesRange, setDatesRange] = useState('')
  const [columnVisibilities, setColumnVisibilities] = useState({
    offer: {
      label: 'オファー',
      csvHeader: 'オファー',
      isActive: true,
    },
    coach: {
      label: 'コーチ',
      csvHeader: 'コーチ',
      isActive: true,
    },
    player: {
      label: 'プレイヤー',
      csvHeader: 'プレイヤー',
      isActive: true,
    },
    playerEmail: {
      label: (
        <>
          プレイヤー
          <br />
          メールアドレス
        </>
      ),
      csvHeader: 'プレイヤーメールアドレス',
      isActive: false,
    },
    tags: {
      label: 'タグ',
      csvHeader: 'タグ',
      isActive: false,
    },
    status: {
      label: 'ステータス',
      csvHeader: 'ステータス',
      isActive: true,
    },
    price: {
      label: '単価(円)',
      csvHeader: '単価(円)',
      isActive: false,
    },
    duration: {
      label: '時間(分)',
      csvHeader: '時間(分)',
      isActive: false,
    },
    number: {
      label: '個数',
      csvHeader: '個数',
      isActive: false,
    },
    paymentAmount: {
      label: '決済額(円)',
      csvHeader: '決済額(円)',
      isActive: true,
    },
    createdAt: {
      label: '作成日時',
      csvHeader: '作成日時',
      isActive: true,
    },
    paidAt: {
      label: '決済日時',
      csvHeader: '決済日時',
      isActive: true,
    },
    consumedAt: {
      label: '消費日時',
      csvHeader: '消費日時',
      isActive: true,
    },
  })

  useEffect(() => {
    const tagsUnsub = db
      .collection('tags')
      .onSnapshot((snap) => setTags(querySnapToObj(snap)))
    const ticketsUnsub = db
      .collection('tickets')
      .onSnapshot((snap) => setTickets(querySnapToObj(snap)))
    const usersUnsub = db
      .collection('users')
      .onSnapshot((snap) => setUsers(querySnapToObj(snap)))
    const usersPublicUnsub = db
      .collection('usersPublic')
      .onSnapshot((snap) => setUsersPublic(querySnapToObj(snap)))
    return () => {
      tagsUnsub()
      ticketsUnsub()
      usersUnsub()
      usersPublicUnsub()
    }
  }, [db])

  if (!_.get(claims, 'isAdmin', false)) return <Redirect to='/' />

  const coachOptions = _(users)
    .filter('isCoach')
    .map((v) => ({
      key: v.id,
      value: v.id,
      text: _.get(usersPublic, `${v.id}.nickname`, '---'),
    }))
    .value()

  const filterDateTypeOptions = _(columnVisibilities)
    .pick(['createdAt', 'paidAt', 'consumedAt'])
    .map((v) => ({
      key: v.label,
      value: v.label,
      text: v.label,
    }))
    .value()

  const selectableTags = _(tags)
    .sortBy((tag) => `${tag.type}${tag.category.name}`)
    .map((tag) => (
      <Label
        key={tag.id}
        tag
        color={_.includes(selectedTags, tag.id) ? 'blue' : 'grey'}
        size='mini'
        style={{ marginRight: 5, marginBottom: 5, cursor: 'pointer' }}
        onClick={() => {
          const selectedTagIds = _.includes(selectedTags, tag.id)
            ? _.filter(selectedTags, (n) => n !== tag.id)
            : _.concat(selectedTags, tag.id)
          setSelectedTags(selectedTagIds)
        }}>
        {tag.type === GAMETITLE_TAG_NAME
          ? `${tag.category.name}・${tag.label}`
          : tag.label}
      </Label>
    ))
    .value()

  const changeStatus = (ticket, value) => {
    try {
      db.doc(`tickets/${ticket.id}`).update({
        status: value,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleSort = (clickedColumn) => {
    if (clickedColumn !== sortColumn) {
      setSortColumn(clickedColumn)
      setSortDirection('descending')
      return
    }
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
  }

  const filterDateRange = (data, field) => {
    const start = moment(datesRange.split('-')[0], 'YYYY-MM-DD')
    const end = moment(datesRange.split('-')[1], 'YYYY-MM-DD').endOf('day')
    return _.filter(
      data,
      datesRange
        ? (v) =>
            v[field] &&
            moment(v[field].toDate(), 'YYYY-MM-DD').isBetween(start, end)
        : (v) => v
    )
  }

  const ticketsStatsRows = () =>
    _(tickets)
      .filter((ticket) =>
        selectedCoachId ? ticket.offer.coach.id === selectedCoachId : ticket
      )
      .filter(
        !_.isEmpty(selectedTags)
          ? (ticket) =>
              _.some(
                _.map(selectedTags, (tag) =>
                  _.includes(_.keys(ticket.offer.tags), tag)
                )
              )
          : (offer) => offer
      )
      .thru((ticket) =>
        filterDateRange(
          ticket,
          { 作成日時: 'createdAt', 決済日時: 'paidAt', 消費日時: 'consumedAt' }[
            selectedFilterDateType
          ]
        )
      )
      .sortBy(sortColumn)
      .thru((v) => (sortDirection === 'descending' ? _.reverse(v) : v))
      .map((ticket) => (
        <Table.Row key={ticket.id}>
          {columnVisibilities.offer.isActive && (
            <Table.Cell>
              <Link to={`/offers/${ticket.offer.id}`}>
                {ticket.offer.title}
              </Link>
            </Table.Cell>
          )}
          {columnVisibilities.coach.isActive && (
            <Table.Cell>
              <Link to={`/users/${ticket.offer.coach.id}`}>
                {ticket.offer.coach.nickname}
              </Link>
            </Table.Cell>
          )}
          {columnVisibilities.player.isActive && (
            <Table.Cell>
              <Link to={`/users/${ticket.player.id}`}>
                {ticket.player.nickname}
              </Link>
            </Table.Cell>
          )}
          {columnVisibilities.playerEmail.isActive && (
            <Table.Cell>
              {_.get(users[ticket.player.id], 'email', '---')}
            </Table.Cell>
          )}
          {columnVisibilities.tags.isActive && (
            <Table.Cell>
              <TagLabels tags={ticket.offer.tags} />
            </Table.Cell>
          )}
          {columnVisibilities.status.isActive && (
            <Table.Cell>
              <Dropdown
                value={ticket.status}
                options={TICKET_STATUS_OPTIONS}
                onChange={(e, { value }) => changeStatus(ticket, value)}
              />
            </Table.Cell>
          )}
          {columnVisibilities.price.isActive && (
            <Table.Cell textAlign='right'>
              {numeral(ticket.price).format('0,0')}
            </Table.Cell>
          )}
          {columnVisibilities.duration.isActive && (
            <Table.Cell textAlign='right'>
              {numeral(ticket.duration).format('0,0')}
            </Table.Cell>
          )}
          {columnVisibilities.number.isActive && (
            <Table.Cell textAlign='right'>
              {numeral(ticket.number).format('0,0')}
            </Table.Cell>
          )}
          {columnVisibilities.paymentAmount.isActive && (
            <Table.Cell textAlign='right'>
              {numeral(ticket.price * ticket.number).format('0,0')}
            </Table.Cell>
          )}
          {columnVisibilities.createdAt.isActive && (
            <Table.Cell>
              {ticket.createdAt &&
                moment(ticket.createdAt.toDate()).format('YYYY/MM/DD HH:mm')}
            </Table.Cell>
          )}
          {columnVisibilities.paidAt.isActive && (
            <Table.Cell>
              {ticket.paidAt &&
                moment(ticket.paidAt.toDate()).format('YYYY/MM/DD HH:mm')}
            </Table.Cell>
          )}
          {columnVisibilities.consumedAt.isActive && (
            <Table.Cell>
              {ticket.consumedAt &&
                moment(ticket.consumedAt.toDate()).format('YYYY/MM/DD HH:mm')}
            </Table.Cell>
          )}
        </Table.Row>
      ))
      .value()

  const columnToggleButtons = () => {
    const items = _.map(columnVisibilities, (v, key) => (
      <Menu.Item
        key={`toggleButton-${key}`}
        active={v.isActive}
        onClick={() =>
          setColumnVisibilities({
            ...columnVisibilities,
            [key]: {
              ...columnVisibilities[key],
              isActive: !columnVisibilities[key].isActive,
            },
          })
        }>
        {v.label}
      </Menu.Item>
    ))

    return (
      <Menu compact size='tiny'>
        {items}
      </Menu>
    )
  }

  const downloadCsv = () => {
    const fileName = `${moment().format('YYYYMMDDHHmmss')}_チケット一覧.csv`
    const fields = _.map(columnVisibilities, (v) => v.csvHeader)
    const data = _(tickets)
      .thru((ticket) =>
        _.isEqual(selectedFilterDateType, '作成日時')
          ? filterDateRange(ticket, 'createdAt')
          : ticket
      )
      .map((ticket) => {
        const tags = _.map(ticket.offer.tags, (v) => v.label).join(',')
        return [
          ticket.offer.title,
          ticket.offer.coach.nickname,
          ticket.player.nickname,
          _.get(users[ticket.player.id], 'email', '---'),
          tags,
          ticket.status,
          ticket.price,
          ticket.duration,
          ticket.number,
          ticket.price * ticket.number,
          ticket.createdAt
            ? moment(ticket.createdAt.toDate()).format('YYYY/MM/DD HH:mm')
            : '',
          ticket.paidAt
            ? moment(ticket.paidAt.toDate()).format('YYYY/MM/DD HH:mm')
            : '',
          ticket.consumedAt
            ? moment(ticket.consumedAt.toDate()).format('YYYY/MM/DD HH:mm')
            : '',
        ]
      })
      .value()
    const bom = new Uint8Array([0xef, 0xbb, 0xbf])
    const csv = papa.unparse({
      fields,
      data,
    })
    const blob = new Blob([bom, csv], { type: 'text/plain' })
    if (window.navigator.msSaveBlob) {
      // IE,Edge
      window.navigator.msSaveBlob(blob, fileName)
    } else {
      // IE,Edge以外
      const objectURL = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      document.body.appendChild(link)
      link.href = objectURL
      link.download = fileName
      link.click()
      document.body.removeChild(link)
    }
  }

  return (
    <>
      <Header as='h2' color='teal' textAlign='center'>
        <Icon name='handshake outline' />
        <Header.Content>チケット管理</Header.Content>
      </Header>

      <Form>
        <Form.Dropdown
          clearable
          placeholder='コーチを選択'
          search
          selection
          options={coachOptions}
          onChange={(e, { value }) => setSelectedCoachId(value)}
        />
        <Form.Group>
          <Form.Dropdown
            width='4'
            value={selectedFilterDateType}
            search
            selection
            options={filterDateTypeOptions}
            onChange={(e, { value }) => setSelectedFilterDateType(value)}
          />
          <DatesRangeInput
            width='12'
            allowSameEndDate
            dateFormat='YYYY/MM/DD'
            name='datesRange'
            placeholder='日時を指定する'
            value={datesRange}
            iconPosition='left'
            onChange={(e, { value }) => setDatesRange(value)}
          />
        </Form.Group>
      </Form>

      <div style={{ marginTop: 15, marginBottom: 30 }}>{selectableTags}</div>
      <div style={{ marginBottom: 30 }}>
        <Button onClick={() => downloadCsv()}>
          <Icon name='download' />
          CSVダウンロード
        </Button>
      </div>

      {columnToggleButtons()}

      <Table compact='very' size='small' celled structured sortable>
        <Table.Header>
          <Table.Row style={{background:'transparent'}}>
            {columnVisibilities.offer.isActive && (
              <Table.HeaderCell
                sorted={sortColumn === 'offer.title' ? sortDirection : null}
                onClick={() => handleSort('offer.title')}>
                {columnVisibilities.offer.label}
              </Table.HeaderCell>
            )}
            {columnVisibilities.coach.isActive && (
              <Table.HeaderCell
                sorted={
                  sortColumn === 'offer.coach.nickname' ? sortDirection : null
                }
                onClick={() => handleSort('offer.coach.nickname')}>
                {columnVisibilities.coach.label}
              </Table.HeaderCell>
            )}
            {columnVisibilities.player.isActive && (
              <Table.HeaderCell
                sorted={sortColumn === 'player.nickname' ? sortDirection : null}
                onClick={() => handleSort('player.nickname')}>
                プレイヤー
              </Table.HeaderCell>
            )}
            {columnVisibilities.playerEmail.isActive && (
              <Table.HeaderCell>
                {columnVisibilities.playerEmail.label}
              </Table.HeaderCell>
            )}
            {columnVisibilities.tags.isActive && (
              <Table.HeaderCell
                sorted={sortColumn === 'tags' ? sortDirection : null}
                onClick={() => handleSort('tags')}>
                {columnVisibilities.tags.label}
              </Table.HeaderCell>
            )}
            {columnVisibilities.status.isActive && (
              <Table.HeaderCell
                sorted={sortColumn === 'status' ? sortDirection : null}
                onClick={() => handleSort('status')}>
                {columnVisibilities.status.label}
              </Table.HeaderCell>
            )}
            {columnVisibilities.price.isActive && (
              <Table.HeaderCell
                sorted={sortColumn === 'price' ? sortDirection : null}
                onClick={() => handleSort('price')}>
                {columnVisibilities.price.label}
              </Table.HeaderCell>
            )}
            {columnVisibilities.duration.isActive && (
              <Table.HeaderCell
                sorted={sortColumn === 'duration' ? sortDirection : null}
                onClick={() => handleSort('duration')}>
                {columnVisibilities.duration.label}
              </Table.HeaderCell>
            )}
            {columnVisibilities.number.isActive && (
              <Table.HeaderCell
                sorted={sortColumn === 'number' ? sortDirection : null}
                onClick={() => handleSort('number')}>
                {columnVisibilities.number.label}
              </Table.HeaderCell>
            )}
            {columnVisibilities.paymentAmount.isActive && (
              <Table.HeaderCell>
                {columnVisibilities.paymentAmount.label}
              </Table.HeaderCell>
            )}
            {columnVisibilities.createdAt.isActive && (
              <Table.HeaderCell
                sorted={sortColumn === 'createdAt' ? sortDirection : null}
                onClick={() => handleSort('createdAt')}>
                {columnVisibilities.createdAt.label}
              </Table.HeaderCell>
            )}
            {columnVisibilities.paidAt.isActive && (
              <Table.HeaderCell
                sorted={sortColumn === 'paidAt' ? sortDirection : null}
                onClick={() => handleSort('paidAt')}>
                {columnVisibilities.paidAt.label}
              </Table.HeaderCell>
            )}
            {columnVisibilities.consumedAt.isActive && (
              <Table.HeaderCell
                sorted={sortColumn === 'consumedAt' ? sortDirection : null}
                onClick={() => handleSort('consumedAt')}>
                {columnVisibilities.consumedAt.label}
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>{ticketsStatsRows()}</Table.Body>
      </Table>
    </>
  )
}
