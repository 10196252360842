import _ from 'lodash'
import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { Tab, Header, Icon } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'

import AdminAdminUserList from './AdminAdminUserList'
import AdminAdminUserSignUp from './AdminAdminUserSignUp'

export default () => {
  const { claims } = useContext(FirebaseContext)
  if (!_.get(claims, 'isAdmin', false)) return <Redirect to='/' />

  const panes = [
    {
      menuItem: '管理者アカウント一覧',
      render: () => (
        <Tab.Pane as='div'>
          <AdminAdminUserList />
        </Tab.Pane>
      ),
    },
    {
      menuItem: '管理者アカウント作成',
      render: () => (
        <Tab.Pane as='div'>
          <AdminAdminUserSignUp />
        </Tab.Pane>
      ),
    },
  ]
  return (
    <>
      <Header as='h2' color='teal' style={{ marginBottom: 20 }}>
        <Icon name='address book outline' />
        管理者アカウント管理
      </Header>
      <div style= {{
        backgroundColor: 'white',
      }}>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </div>
    </>
  )
}
