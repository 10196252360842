import _ from 'lodash'
import moment from 'moment'
import React, { useState, useEffect, useContext } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Header, Table, Input, Icon } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'

export default () => {
  const { db, claims } = useContext(FirebaseContext)
  const [usersPublic, setUsersPublic] = useState({})
  const [users, setUsers] = useState({})
  const [searchFilter, setSearchFilter] = useState('')
  const [sortColumn, setSortColumn] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState('descending')

  useEffect(() => {
    const usersPublicUnsub = db
      .collection('usersPublic')
      .onSnapshot((snap) => setUsersPublic(querySnapToObj(snap)))
    const usersUnsub = db
      .collection('users')
      .where('isAdmin', '==', false)
      .onSnapshot((snap) => setUsers(querySnapToObj(snap)))
    return () => {
      usersPublicUnsub()
      usersUnsub()
    }
  }, [db])

  if (!_.get(claims, 'isAdmin', false)) return <Redirect to='/' />

  const handleSort = (clickedColumn) => {
    if (clickedColumn !== sortColumn) {
      setSortColumn(clickedColumn)
      setSortDirection('descending')
      return
    }
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
  }

  const customerRows = _(usersPublic)
    .filter((user) => !_.get(users, `${user.id}.isAdmin`, true))
    .filter((user) => {
      if (_.isEmpty(searchFilter)) {
        return true
      }
      const targets = _.values(
        _.pick(user, ['firstName', 'lastName', 'nickname'])
      )
      targets.push(users[user.id].email)
      // serchFilterの大文字、小文字を無視して検索
      const regexp = new RegExp(searchFilter, 'gi')
      for (const v of targets) {
        if (regexp.test(v)) {
          return true
        }
      }
      return false
    })
    .sortBy(sortColumn)
    .thru((v) => (sortDirection === 'descending' ? _.reverse(v) : v))
    .map((user) => (
      <Table.Row key={user.id}>
        <Table.Cell>{_.get(user, 'lastName', '---')}</Table.Cell>
        <Table.Cell>{_.get(user, 'firstName', '---')}</Table.Cell>
        <Table.Cell>
          <Link to={`/users/${user.id}`}>{_.get(user, 'nickname', '---')}</Link>
        </Table.Cell>
        <Table.Cell>{_.get(users[user.id], 'email', '---')}</Table.Cell>
        <Table.Cell>
          {_.get(user, 'createdAt')
            ? moment(user.createdAt.toDate()).format('YYYY/MM/DD HH:mm')
            : '--:--'}
        </Table.Cell>
        <Table.Cell textAlign='center'>
          {_.get(users, `${user.id}.isCoach`) && <Icon name='check' />}
        </Table.Cell>
        <Table.Cell textAlign='center'>
          {_.get(users, `${user.id}.disabled`) && <Icon name='check' />}
        </Table.Cell>
        <Table.Cell textAlign='center'>
          <Link to={`/users/${user.id}/settings`}>
            <Icon name='edit' style={{ cursor: 'pointer' }} />
          </Link>
        </Table.Cell>
      </Table.Row>
    ))
    .value()

  return (
    <>
      <Header as='h2' color='teal' style={{ marginTop: 10 }}>
        <Icon name='address book outline' />
        顧客アカウント管理
      </Header>
      <Input
        fluid
        iconPosition='left'
        icon='search'
        placeholder='検索...'
        name='searchFilter'
        value={searchFilter}
        onChange={(e) => setSearchFilter(e.target.value)}
      />
      <Table compact celled structured sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'lastName' ? sortDirection : null}
              onClick={() => handleSort('lastName')}>
              名前(姓)
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'firstName' ? sortDirection : null}
              onClick={() => handleSort('firstName')}>
              名前(名)
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'nickname' ? sortDirection : null}
              onClick={() => handleSort('nickname')}>
              ニックネーム
            </Table.HeaderCell>
            <Table.HeaderCell>メールアドレス</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'createdAt' ? sortDirection : null}
              onClick={() => handleSort('createdAt')}>
              作成日時
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>コーチ</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>凍結</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>編集</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{customerRows}</Table.Body>
      </Table>
    </>
  )
}
