import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Message, Rating } from 'semantic-ui-react'

const MAX_RATING = 5

export default ({ reviews }) =>
  _.map(reviews, (review, key) => (
    <Message key={key}>
      <Message.Content style={{ color: 'black' }}>
        {_.get(review, 'reviewer.nickname', '---')}
      </Message.Content>
      <Message.Content style={{ color: 'black' }}>
        {_.get(review, 'createdAt', '') &&
          moment(review.createdAt.toDate()).format('YYYY/MM/DD')}
      </Message.Content>
      <Message.Header style={{ color: 'black' }}>
        <Rating
          defaultRating={_.get(review, 'rating', 0)}
          maxRating={MAX_RATING}
          disabled
        />
        {review.title}
      </Message.Header>
      <p style={{ color: 'black', whiteSpace: 'pre-wrap' }}>
        {_.get(review, 'message', '---')}
      </p>
    </Message>
  ))
