import _ from 'lodash'
import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Image, Grid, Header, Icon, Divider } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'
import {
  responsiveStyle,
  Media,
  MediaContextProvider,
} from '../lib/responsiveStyle'

export default () => {
  const { db } = useContext(FirebaseContext)
  const [categories, setCategories] = useState({})
  useEffect(() => {
    const categoriesUnsub = db.collection('categories').onSnapshot((snap) => {
      setCategories(querySnapToObj(snap))
    })
    return () => categoriesUnsub()
  }, [db])

  const categoryRows = _(categories)
    .sortBy({ name: 'その他' })
    .map((category) => (
      <Grid.Column key={category.id}>
        <Link to={`/categories/${category.id}`}>
          <Image
            bordered
            src={category.image}
            style={{
              cursor: 'pointer',
            }}
            size='medium'
          />
        </Link>
        <Header
          as='h1'
          textAlign='center'
          style={{ letterSpacing: 7, marginTop: 15, color: '#fff' }}>
          {category.name}
        </Header>
        <Divider hidden />
      </Grid.Column>
    ))
    .value()

  return (
    <>
      <Header as='h2' color='teal' textAlign='center' style={{ marginTop: 10 }}>
        <Icon name='search' />
        <Header.Content>オファーを探す</Header.Content>
      </Header>
      <Divider hidden />
      <style>{responsiveStyle}</style>
      <MediaContextProvider>
        <Grid as={Media} relaxed columns={4} greaterThanOrEqual='computer'>
          {categoryRows}
        </Grid>
        <Grid as={Media} relaxed columns={2} lessThan='computer'>
          {categoryRows}
        </Grid>
      </MediaContextProvider>
    </>
  )
}
