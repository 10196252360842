import PDF from 'react-pdf-js'
import React from 'react'
import { Segment, Divider, Header } from 'semantic-ui-react'

const TOS_FILE_PATH = '/assets/SpecifiedCommercial.pdf'

export default () => {
  return (
    <>
      <Divider hidden />
      <Header as='h2' color='teal'>
        特定商取引法に基づく表示
      </Header>
      <Segment color='teal' compact>
        <PDF file={TOS_FILE_PATH} />
      </Segment>
      <Divider hidden />
    </>
  )
}
