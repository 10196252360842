import PDF from 'react-pdf-js'
import React, { useState } from 'react'
import { Button, Icon, Header, Segment, Divider } from 'semantic-ui-react'

const TOS_FILE_PATH = '/assets/JOZにおける個人情報の取り扱いについて.pdf'

export default () => {
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)

  const isButtonDisable = (limitPage) => page === limitPage

  return (
    <>
      <Header as='h2' color='teal' id='tosHeader'>
        JOZにおける個人情報の取り扱いについて
      </Header>
      <Button.Group>
        <Button
          disabled={isButtonDisable(1)}
          icon
          labelPosition='left'
          onClick={() => setPage(page - 1)}>
          <Icon name='left arrow' />
          前ページ
        </Button>
        <Button.Or text={`${page}/${pages}`} />
        <Button
          disabled={isButtonDisable(pages)}
          icon
          labelPosition='right'
          onClick={() => setPage(page + 1)}>
          次ページ
          <Icon name='right arrow' />
        </Button>
      </Button.Group>
      <Segment color='teal' compact>
        <PDF
          file={TOS_FILE_PATH}
          onDocumentComplete={(pages) => setPages(pages)}
          page={page}
        />
      </Segment>
      <Divider hidden />
    </>
  )
}
