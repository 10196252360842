import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

import { FirebaseContext } from './context'

import MaintenanceScreen from '../components/MaintenanceScreen'
import NoDocUserSetting from '../components/NoDocUserSetting'

export const FirebaseInitWrapper = ({ children }) => {
  const { initializing } = useContext(FirebaseContext)
  if (initializing)
    return (
      <Dimmer active>
        <Loader>FIRESTORE LOADING...</Loader>
      </Dimmer>
    )
  return children
}

export const MaintenanceMode = ({ children }) => {
  const { db } = useContext(FirebaseContext)
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const unsubscribe = db.doc('configs/site').onSnapshot((snap) => {
      setIsMaintenanceMode(snap.data().isMaintenanceMode)
      setIsLoaded(true)
    })
    return () => unsubscribe()
  })
  if (!isLoaded)
    return (
      <Dimmer active>
        <Loader>LOADING...</Loader>
      </Dimmer>
    )
  if (isMaintenanceMode) return <MaintenanceScreen />
  return children
}

export const CheckUserDoc = ({ children }) => {
  const { db, currentUser, claims } = useContext(FirebaseContext)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isUserDocCreated, setIsUserDocCreated] = useState(false)
  useEffect(() => {
    if (!currentUser) {
      setIsLoaded(true)
      return
    }
    const unsubscribe = db
      .doc(`users/${currentUser.uid}`)
      .onSnapshot((snap) => {
        setIsUserDocCreated(snap.exists)
        setIsLoaded(true)
      })
    return () => unsubscribe()
  }, [currentUser, db])
  if (!isLoaded)
    return (
      <Dimmer active>
        <Loader>CHECK USER DOC...</Loader>
      </Dimmer>
    )
  if (
    _.every([
      currentUser,
      !isUserDocCreated,
      _.get(claims, 'firebase.sign_in_provider') !== 'password',
    ])
  ) {
    console.log('sign in user no doc')
    return <NoDocUserSetting />
  }
  return children
}
