import _ from 'lodash'
import Ajv from 'ajv'
import React, { useState, useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Header,
  Form,
  Message,
  Container,
  Divider,
  Button,
  Icon,
  Loader,
  Dimmer,
} from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { authErrorMessage } from '../lib/misc'
import { EMAIL_VALIDATION_SCHEMA } from '../lib/validationSchema'

const ajv = new Ajv({ removeAdditional: true })

const EmailSignInHandler = () => {
  // If this page is opened by Email-Sign-In URL
  const { auth } = useContext(FirebaseContext)

  useEffect(() => {
    if (!auth.isSignInWithEmailLink(window.location.href)) return

    const query = new URLSearchParams(window.location.search)
    ;(async () => {
      try {
        await auth.signInWithEmailLink(query.get('email'), window.location.href)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [auth])

  return null
}

export default () => {
  const { auth, currentUser, firebase, functions } = useContext(FirebaseContext)
  const [errorMessage, setErrorMessage] = useState(null)
  const [email, setEmail] = useState('')
  const [sentEmailSignInLink, setSentEmailSignInLink] = useState(false)
  const [loading, setLoading] = useState(false)

  if (currentUser) {
    return <Redirect to='/' />
  }

  const isReady = () => {
    if (loading) return false
    const validate = ajv.compile(EMAIL_VALIDATION_SCHEMA)

    return validate({ email })
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      await functions.httpsCallable('sendEmailSigninLink')({
        email,
        origin: window.location.origin,
      })
      setSentEmailSignInLink(true)
    } catch (e) {
      console.error('ログインリンク送信失敗 : ', e)
      if (e.code === 'not-found')
        setErrorMessage(
          'このメールアドレスは登録されていません。新規登録からアカウント作成をしてください。'
        )
      else
        setErrorMessage(
          'ログイン用リンクの送信に失敗しました。再度時間をおいてお試しください。'
        )

      setLoading(false)
    }
  }

  const onGoogleSsoButtonClick = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider()
      await auth.signInWithRedirect(provider)
    } catch (e) {
      console.error('Googleアカウントログイン失敗 : ', e)
      setErrorMessage(authErrorMessage(e))
    }
  }

  if (window.location.hash === '#email-signin') {
    return (
      <>
        <Dimmer active>
          <Loader>ログイン中...</Loader>
        </Dimmer>
        <EmailSignInHandler />
      </>
    )
  }

  return (
    <Container style={{ marginTop: 50, width: '400px' }} textAlign='center'>
      <Header as='h2' color='teal' id='signInHeader'>
        ログイン
      </Header>

      {!_.isEmpty(errorMessage) && <Message negative>{errorMessage}</Message>}
      {sentEmailSignInLink && (
        <Message success>
          ログイン用リンクを送信しました。
          <br />
          メールをご確認ください。
        </Message>
      )}

      <Form size='large'>
        <Form.Input
          icon='mail'
          iconPosition='left'
          placeholder='メールアドレス'
          name='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: 10, marginTop: 10 }}
          maxLength={EMAIL_VALIDATION_SCHEMA.properties.email.maxLength}
        />
        <Form.Button
          disabled={!isReady()}
          primary
          type='submit'
          content='ログイン用URLを送信する'
          onClick={onSubmit}
        />
      </Form>

      <Divider
        horizontal
        style={{ marginTop: 25, marginBottom: 25, color: 'white' }}>
        または
      </Divider>

      <Button
        color='google plus'
        style={{ marginBottom: 15 }}
        onClick={onGoogleSsoButtonClick}>
        <Icon name='google plus' /> Googleアカウントでログイン
      </Button>
    </Container>
  )
}
