const CONTACT_VALIDATION_SCHEMA = {
  description: 'お問い合わせフォーム用バリデーション',
  additionalProperties: false,
  type: 'object',
  properties: {
    email: {
      type: 'string',
      format: 'email',
      maxLength: 100,
    },
    category: {
      type: 'string',
      minLength: 1,
      maxLength: 100,
    },
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 1000,
    },
  },
}

const SIGNUP_VALIDATION_SCHEMA = {
  description: '新規登録フォーム用バリデーション(admin,customer)',
  type: 'object',
  properties: {
    email: {
      type: 'string',
      format: 'email',
      maxLength: 100,
    },
    firstName: {
      type: 'string',
      minLength: 1,
      maxLength: 20,
    },
    lastName: {
      type: 'string',
      minLength: 1,
      maxLength: 20,
    },
    nickname: {
      type: 'string',
      minLength: 1,
      maxLength: 50,
    },
    discord: {
      type: 'string',
      maxLength: 100,
    },
    twitter: {
      type: 'string',
      maxLength: 100,
    },
  },
}

const CUSTOMER_MYPROFILE_VALIDATION_SCHEMA = {
  description: 'userPublic更新用バリデーション(coach,customer)',
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
      minLength: 1,
      maxLength: 20,
    },
    lastName: {
      type: 'string',
      minLength: 1,
      maxLength: 20,
    },
    nickname: {
      type: 'string',
      minLength: 1,
      maxLength: 50,
    },
    description: {
      type: 'string',
      maxLength: 2000,
    },
    sns: {
      type: 'object',
      properties: {
        youtube: {
          type: 'string',
          maxLength: 200,
        },
        twitter: {
          type: 'string',
          maxLength: 100,
        },
        twitch: {
          type: 'string',
          maxLength: 200,
        },
        discord: {
          type: 'string',
          maxLength: 100,
        },
      },
    },
  },
}

const EMAIL_VALIDATION_SCHEMA = {
  description: 'email入力フォームのみのバリデーション(signin, emailUpdate)',
  additionalProperties: false,
  type: 'object',
  properties: {
    email: {
      type: 'string',
      format: 'email',
      maxLength: 100,
    },
  },
}

const DISCORD_VALIDATION_SCHEMA = {
  description: 'discord webhookUrl入力フォームのバリデーション',
  additionalProperties: false,
  type: 'object',
  properties: {
    discordWebhookUrl: {
      type: 'string',
      maxLength: 200,
    },
  },
}

const REVIEW_VALIDATION_SCHEMA = {
  description: 'レビュー投稿フォーム用バリデーション',
  additionalProperties: false,
  type: 'object',
  properties: {
    title: {
      type: 'string',
      minLength: 1,
      maxLength: 50,
    },
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 500,
    },
    rating: {
      type: 'number',
      minimum: 1,
      maximum: 5,
    },
    revieweeId: {
      type: 'string',
    },
    revieweeRole: {
      type: 'string',
    },
  },
}

const ADMIN_MYPROFILE_VALIDATION_SCHEMA = {
  description: 'プロフィール編集フォーム用バリデーション(admin)',
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
      minLength: 1,
      maxLength: 20,
    },
    lastName: {
      type: 'string',
      minLength: 1,
      maxLength: 20,
    },
  },
}

const OFFER_VALIDATION_SCHEMA = {
  description: 'オファー作成、編集フォーム用バリデーション',
  additionalProperties: false,
  type: 'object',
  properties: {
    title: {
      type: 'string',
      minLength: 1,
      maxLength: 50,
    },
    description: {
      type: 'string',
      minLength: 1,
      maxLength: 1000,
    },
    tagCheckList: {
      type: 'object',
    },
    price: {
      type: 'number',
      minimum: 1000,
      maximum: 100000,
      multipleOf: 100,
    },
    duration: {
      type: 'number',
      minimum: 1,
      maximum: 1000,
    },
  },
}

const NEWS_VALIDATION_SCHEMA = {
  description: 'お知らせ作成、編集フォーム用バリデーション',
  additionalProperties: false,
  type: 'object',
  properties: {
    title: {
      type: 'string',
      minLength: 1,
      maxLength: 100,
    },
    description: {
      type: 'string',
      minLength: 1,
      maxLength: 2000,
    },
  },
}

const TAG_VALIDATION_SCHEMA = {
  description: 'タグ作成フォーム用バリデーション',
  type: 'object',
  properties: {
    type: {
      type: 'string',
    },
    category: {
      type: 'string',
      minLength: 1,
      maxLength: 50,
    },
    label: {
      type: 'string',
      minLength: 1,
      maxLength: 50,
    },
  },
}

const RESERVE_TICKET_VALIDATION_SCHEMA = {
  description: 'チケット予約フォーム用バリデーション',
  additionalProperties: false,
  type: 'object',
  properties: {
    number: {
      type: 'number',
      minimum: 1,
      maximum: 10,
    },
    offerId: {
      type: 'string',
    },
  },
}

const CATEGORY_VALIDATION_SCHEMA = {
  description: 'カテゴリ作成フォーム用バリデーション',
  type: 'object',
  properties: {
    name: {
      type: 'string',
      minLength: 1,
      maxLength: 50,
    },
  },
}

module.exports = {
  CONTACT_VALIDATION_SCHEMA,
  SIGNUP_VALIDATION_SCHEMA,
  EMAIL_VALIDATION_SCHEMA,
  CUSTOMER_MYPROFILE_VALIDATION_SCHEMA,
  DISCORD_VALIDATION_SCHEMA,
  REVIEW_VALIDATION_SCHEMA,
  ADMIN_MYPROFILE_VALIDATION_SCHEMA,
  OFFER_VALIDATION_SCHEMA,
  NEWS_VALIDATION_SCHEMA,
  TAG_VALIDATION_SCHEMA,
  RESERVE_TICKET_VALIDATION_SCHEMA,
  CATEGORY_VALIDATION_SCHEMA,
}
