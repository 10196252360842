import _ from 'lodash'
import Ajv from 'ajv'
import React, { useContext, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Header, Form, Message, Icon, Dimmer, Loader } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { NEWS_VALIDATION_SCHEMA } from '../lib/validationSchema'

const ajv = new Ajv({ removeAdditional: true })

const SUCCESS_MESSAGE = 'お知らせを更新しました。'
const ERROR_MESSAGE =
  'お知らせの更新に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default ({ match }) => {
  const { db, claims } = useContext(FirebaseContext)
  const [news, setNews] = useState({})
  const [resultMessage, setResultMessage] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    const newsUnsub = db
      .doc(`news/${match.params.newsId}`)
      .onSnapshot((snap) => setNews(snap.data()))
    return () => newsUnsub()
  }, [db, match.params.newsId])

  if (!_.get(claims, 'isAdmin', false)) return <Redirect to='/' />

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const isReady = () => {
    const validate = ajv.compile(NEWS_VALIDATION_SCHEMA)
    return validate(_.pick(news, ['title', 'description']))
  }

  const onSubmit = async () => {
    setIsBusy(true)
    try {
      await db.doc(`news/${match.params.newsId}`).update(news)
      setResultMessage(SUCCESS_MESSAGE)
    } catch (e) {
      console.error('お知らせの更新に失敗しました。 : ', e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusy(false)
  }

  return (
    <>
      {isBusy && (
        <Dimmer active page>
          <Loader>お知らせ更新中</Loader>
        </Dimmer>
      )}
      {messageType}

      <Header as='h2' color='teal' style={{ marginBottom: 20 }}>
        <Icon name='edit' />
        お知らせ更新フォーム
      </Header>
      <Form>
        <Form.Input
          placeholder={`タイトル(${NEWS_VALIDATION_SCHEMA.properties.title.maxLength}文字まで)`}
          name='title'
          value={_.get(news, 'title', '')}
          onChange={(e) => setNews({ ...news, title: e.target.value })}
          maxLength={NEWS_VALIDATION_SCHEMA.properties.title.maxLength}
        />
        <Form.TextArea
          style={{ height: 200 }}
          placeholder={`お知らせをご記入ください。(${NEWS_VALIDATION_SCHEMA.properties.description.maxLength}文字まで)`}
          name='description'
          value={_.get(news, 'description', '')}
          onChange={(e) => setNews({ ...news, description: e.target.value })}
          maxLength={NEWS_VALIDATION_SCHEMA.properties.description.maxLength}
        />

        <Form.Button
          disabled={!isReady()}
          primary
          type='submit'
          content='更新'
          onClick={onSubmit}
        />
      </Form>
    </>
  )
}
