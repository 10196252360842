import _ from 'lodash'
import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { Tab, Header, Icon } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'

import AdminCreateNews from './AdminCreateNews'
import AdminNewsList from './AdminNewsList'

export default () => {
  const { claims } = useContext(FirebaseContext)
  if (!_.get(claims, 'isAdmin', false)) return <Redirect to='/' />

  const panes = [
    {
      menuItem: 'お知らせ一覧',
      render: () => (
        <Tab.Pane as='div'>
          <AdminNewsList />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'お知らせ作成',
      render: () => (
        <Tab.Pane as='div'>
          <AdminCreateNews />
        </Tab.Pane>
      ),
    },
  ]
  return (
    <>
      <Header as='h2' color='teal'>
        <Icon name='newspaper outline' />
        お知らせ管理
      </Header>
      <Tab
        style={{ color: 'black', background: '#fff' }}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </>
  )
}
