import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState, useContext } from 'react'
import { Header, Icon, List, Grid } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'
import { NEWS_ABLED_STATUS } from '../lib/constants'

export default ({ location, history }) => {
  const { db } = useContext(FirebaseContext)
  const [news, setNews] = useState({})

  useEffect(() => {
    const newsUnsub = db
      .collection('news')
      .where('status', '==', NEWS_ABLED_STATUS)
      .onSnapshot((snap) => setNews(querySnapToObj(snap)))
    return () => newsUnsub()
  }, [db])

  const listItems = (news) =>
    _(news)
      .orderBy('createdAt', 'desc')
      .map((v) => (
        <List.Item key={v.id}>
          <Grid columns={2}>
            <Grid.Column width={3} textAlign='right'>
              {moment(v.createdAt.toDate()).format('YYYY/MM/DD HH:mm')}
            </Grid.Column>
            <Grid.Column width={13}>
              <span
                style={{ cursor: 'pointer', color: '#0066c0' }}
                onClick={() =>
                  history.push(
                    _.get(queryString.parse(location.search), 'id') === v.id
                      ? '/news'
                      : `?id=${v.id}`
                  )
                }>
                {v.title}
              </span>

              {_.get(queryString.parse(location.search), 'id') === v.id && (
                <p
                  style={{
                    whiteSpace: 'pre-wrap',
                    marginTop: 10,
                  }}>
                  {v.description}
                </p>
              )}
            </Grid.Column>
          </Grid>
        </List.Item>
      ))
      .value()

  return (
    <>
      <Header as='h2' color='teal' textAlign='center'>
        <Icon name='plug' />
        <Header.Content id='newsListHeader'>NEWS UPDATE</Header.Content>
      </Header>
      <List size='large' divided relaxed='very'>
        {listItems(news)}
      </List>
    </>
  )
}
