import _ from 'lodash'
import Path from 'path'
import Dropzone from 'react-dropzone'
import React, { useState, useContext, useEffect } from 'react'
import { Table, Icon, Input, Image, Message } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'

const IMAGE_EXTENSION_WARNING_MESSAGE = 'JPG,PNGファイルを添付してください。'
const IMAGE_UPLOAD_ERROR_MESSAGE =
  'ファイルのアップロードに失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default () => {
  const { storage, db } = useContext(FirebaseContext)
  const [categories, setCategories] = useState({})
  const [updatingUid, setUpdatingUid] = useState(null)
  const [resultMessage, setResultMessage] = useState(null)

  useEffect(() => {
    const categoriesUnsub = db
      .collection('categories')
      .onSnapshot((snap) => setCategories(querySnapToObj(snap)))
    return () => categoriesUnsub()
  }, [db])

  const messageType = {
    [IMAGE_EXTENSION_WARNING_MESSAGE]: (
      <Message warning content={resultMessage} />
    ),
    [IMAGE_UPLOAD_ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const onImageFileDrop = async (acceptedFiles) => {
    const attachedFile = acceptedFiles[0]
    const extension = Path.extname(attachedFile.name)
    if (extension.match(/\.(png|jpg)$/i)) {
      try {
        const fileName = `${categories[updatingUid].name}${extension}`
        const storageRef = storage.ref().child(`categories/${fileName}`)
        await storageRef.put(attachedFile)
        await db.doc(`categories/${updatingUid}`).update({
          image: await storageRef.getDownloadURL(),
        })
        setResultMessage(null)
        setUpdatingUid(null)
      } catch (e) {
        console.error(e)
        setResultMessage(IMAGE_UPLOAD_ERROR_MESSAGE)
      }
    } else setResultMessage(IMAGE_EXTENSION_WARNING_MESSAGE)
  }

  const fileDropZone = (
    <Message size='mini' compact>
      <Dropzone onDrop={onImageFileDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>ここをクリックしてJPG,PNGファイルを選択してください。</p>
          </div>
        )}
      </Dropzone>
    </Message>
  )

  const onModifyRecordText = ({ key, target }) => {
    if (key !== 'Enter') {
      return
    }
    db.doc(`categories/${updatingUid}`).update({
      [target.name]: target.value,
    })
    setUpdatingUid(null)
  }

  const EditableCell = ({ rec, attr, type }) => {
    if (updatingUid === rec.id) {
      return {
        input: (
          <Input
            fluid
            name={attr}
            defaultValue={rec[attr]}
            onKeyPress={onModifyRecordText}
          />
        ),
        image: fileDropZone,
      }[type]
    }
    return {
      input: _.get(rec, attr, ''),
      image: <Image src={_.get(rec, attr, '')} size='tiny' />,
    }[type]
  }
  const tableRows = _.map(categories, (category) => (
    <Table.Row key={category.id}>
      <Table.Cell>
        <EditableCell rec={category} attr='name' type='input' />
      </Table.Cell>
      <Table.Cell>
        <EditableCell rec={category} attr='image' type='image' />
      </Table.Cell>
      <Table.Cell textAlign='center'>
        <Icon
          name='edit'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            category.id === updatingUid
              ? setUpdatingUid(null)
              : setUpdatingUid(category.id)
          }}
        />
      </Table.Cell>
    </Table.Row>
  ))

  return (
    <>
      {messageType}
      <Table compact celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>カテゴリ名</Table.HeaderCell>
            <Table.HeaderCell>画像</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>編集</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{tableRows}</Table.Body>
      </Table>
    </>
  )
}
