import _ from 'lodash'
import React, { useState, useContext, useEffect } from 'react'
import { Table, Icon, Input, Dropdown } from 'semantic-ui-react'

import { TAG_TYPE, GAMETITLE_TAG_NAME } from '../lib/constants'
import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'

const TAG_TYPE_OPTIONS = _.map(TAG_TYPE, (s) => ({
  key: s,
  value: s,
  text: s,
}))

export default () => {
  const { db } = useContext(FirebaseContext)
  const [tags, setTags] = useState({})
  const [categories, setCategories] = useState({})
  const [updatingUid, setUpdatingUid] = useState(null)

  useEffect(() => {
    const tagsUnsub = db
      .collection('tags')
      .onSnapshot((snap) => setTags(querySnapToObj(snap)))
    const categoriesUnsub = db
      .collection('categories')
      .onSnapshot((snap) => setCategories(querySnapToObj(snap)))
    return () => {
      tagsUnsub()
      categoriesUnsub()
    }
  }, [db])

  const categoryOptions = _.map(categories, (s) => ({
    key: s.id,
    value: s.name,
    text: s.name,
  }))

  const onModifyRecordText = ({ key, target }) => {
    if (key !== 'Enter') {
      return
    }
    db.doc(`tags/${updatingUid}`).update({
      [target.name]: target.value,
    })
    setUpdatingUid(null)
  }

  const onModifyRecordDropdown = (name, value) => {
    const ref = db.doc(`tags/${updatingUid}`)
    if (name === 'type') {
      ref.update({
        type: value,
      })
    }
    if (name === 'name') {
      ref.update({
        category: _.find(categories, { name: value }),
      })
    }
    setUpdatingUid(null)
  }

  const EditableCell = ({ id, rec, attr, type, options }) => {
    if (updatingUid === id) {
      return {
        input: (
          <Input
            fluid
            name={attr}
            defaultValue={rec[attr]}
            onKeyPress={onModifyRecordText}
          />
        ),
        dropdown: (
          <Dropdown
            fluid
            name={attr}
            value={rec[attr]}
            options={options}
            onChange={(e, { name, value }) =>
              onModifyRecordDropdown(name, value)
            }
          />
        ),
      }[type]
    }
    return _.get(rec, attr, '')
  }

  const tagRows = _(tags)
    .sortBy((tag) => `${tag.type}${tag.category.name}`)
    .map((tag) => (
      <Table.Row key={tag.id}>
        <Table.Cell>
          <EditableCell id={tag.id} rec={tag} attr='label' type='input' />
        </Table.Cell>
        <Table.Cell>
          {tag.type === GAMETITLE_TAG_NAME && (
            <EditableCell
              id={tag.id}
              rec={tag.category}
              attr='name'
              type='dropdown'
              options={categoryOptions}
            />
          )}
        </Table.Cell>
        <Table.Cell>
          <EditableCell
            id={tag.id}
            rec={tag}
            attr='type'
            type='dropdown'
            options={TAG_TYPE_OPTIONS}
          />
        </Table.Cell>
        <Table.Cell textAlign='center'>
          <Icon
            name='edit'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (tag.id === updatingUid) {
                setUpdatingUid(null)
              } else {
                setUpdatingUid(tag.id)
              }
            }}
          />
        </Table.Cell>
      </Table.Row>
    ))
    .value()

  return (
    <>
      <Table compact celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>タグ名</Table.HeaderCell>
            <Table.HeaderCell>カテゴリ</Table.HeaderCell>
            <Table.HeaderCell>タグ種別</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>編集</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{tagRows}</Table.Body>
      </Table>
    </>
  )
}
