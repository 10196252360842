import _ from 'lodash'
import Ajv from 'ajv'
import React, { useContext, useState } from 'react'
import { Header, Form, Message, Container } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { authErrorMessage } from '../lib/misc'
import { SIGNUP_VALIDATION_SCHEMA } from '../lib/validationSchema'

const ajv = new Ajv({ removeAdditional: true })

export default () => {
  const { functions } = useContext(FirebaseContext)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [nickname, setNickname] = useState('')
  const [twitter, setTwitter] = useState('')
  const [discord, setDiscord] = useState('')
  const [isTosChecked, setIsTosChecked] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const isReady = () => {
    const validate = ajv.compile(SIGNUP_VALIDATION_SCHEMA)
    if (!isTosChecked) {
      return false
    }
    if (!_.some([twitter, discord])) return false
    return validate({ firstName, lastName, nickname })
  }

  const onSubmit = async () => {
    try {
      await functions.httpsCallable('registerCustomer')({
        firstName,
        lastName,
        nickname,
        twitter,
        discord,
      })
    } catch (e) {
      console.error('アカウント登録失敗 : ', e)
      setErrorMessage(authErrorMessage(e))
    }
  }

  return (
    <Container style={{ marginTop: 50, width: '500px' }}>
      <Header as='h2' color='teal' id='signUpHeader' textAlign='center'>
        アカウント情報設定
      </Header>

      {!_.isEmpty(errorMessage) && <Message negative>{errorMessage}</Message>}

      <Form size='large'>
        <Form.Group widths='equal'>
          <Form.Input
            icon='user'
            iconPosition='left'
            placeholder='名前(姓)'
            name='lastName'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            maxLength={SIGNUP_VALIDATION_SCHEMA.properties.lastName.maxLength}
            width={8}
          />
          <Form.Input
            icon='user'
            iconPosition='left'
            placeholder='名前(名)'
            name='firstName'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            maxLength={SIGNUP_VALIDATION_SCHEMA.properties.firstName.maxLength}
            width={8}
          />
        </Form.Group>
        <Form.Input
          icon='user outline'
          iconPosition='left'
          placeholder='ニックネーム(表示名)'
          name='nickname'
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
          maxLength={SIGNUP_VALIDATION_SCHEMA.properties.nickname.maxLength}
        />
        <Form.Input
          icon='twitter'
          iconPosition='left'
          placeholder='TwitterID'
          name='twitter'
          value={twitter}
          onChange={(e) => setTwitter(e.target.value)}
          maxLength={SIGNUP_VALIDATION_SCHEMA.properties.twitter.maxLength}
        />
        <Form.Input
          icon='discord'
          iconPosition='left'
          placeholder='discordID'
          name='discord'
          value={discord}
          onChange={(e) => setDiscord(e.target.value)}
          maxLength={SIGNUP_VALIDATION_SCHEMA.properties.discord.maxLength}
        />
        <p style={{ opacity: 0.5 ,color:'white'}}>※TwitterID/DiscordID どちらか必須</p>
        <p style={{ opacity: 0.5 ,color:'white'}}>※コーチとプレイヤーが連絡を取り合う為にTwitterやDiscordのDMは解放しておいてください。 </p>
        <div style={{ textAlign: 'center' }}>
          <Form.Checkbox
            label={{
              children: (
                <div style={{color:'white'}}>
                  <a
                    rel='noopener noreferrer'
                    target='_blank'
                    href='https://kiyaku.joz.jp'>
                    利用規約
                  </a>
                  に同意する
                </div>
              ),
            }}
            checked={isTosChecked}
            onChange={() => setIsTosChecked(!isTosChecked)}
          />
          <Form.Button
            disabled={!isReady()}
            primary
            type='submit'
            content='アカウント情報を登録'
            onClick={onSubmit}
          />
        </div>
      </Form>
    </Container>
  )
}
