import _ from 'lodash'
import Ajv from 'ajv'
import Path from 'path'
import Dropzone from 'react-dropzone'
import React, { useEffect, useState, useContext, Fragment } from 'react'
import {
  Form,
  Button,
  Message,
  Dimmer,
  Loader,
  Modal,
  Grid,
  Checkbox,
  Segment,
  Header,
  Image,
  Icon,
} from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'
import { REQUIRE_TAG } from '../lib/constants'

import { CUSTOMER_MYPROFILE_VALIDATION_SCHEMA } from '../lib/validationSchema'

import {
  responsiveStyle,
  Media,
  MediaContextProvider,
} from '../lib/responsiveStyle'

const ajv = new Ajv({ removeAdditional: true })

const SUCCESS_MESSAGE = 'プロフィールを更新しました。'
const WARNING_MESSAGE = 'プロフィール画像はJPG,PNGのいずれかにしてください。'
const ERROR_MESSAGE =
  'プロフィールを更新に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default ({ uid }) => {
  const { db, storage, currentUser } = useContext(FirebaseContext)
  const [userPublic, setUserPublic] = useState({})
  const [tags, setTags] = useState({})
  const [isImageFileUploading, setIsImageFileUploading] = useState(false)
  const [isProfileUpdating, setIsProfileUpdating] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [resultMessage, setResultMessage] = useState(null)

  useEffect(() => {
    const userPublicUnsub = db
      .doc(`usersPublic/${uid}`)
      .onSnapshot((snap) => setUserPublic(snap.data()))

    const tagsUnsub = db
      .collection('tags')
      .onSnapshot((snap) => setTags(querySnapToObj(snap)))

    return () => {
      userPublicUnsub()
      tagsUnsub()
    }
  }, [db, uid])

  const onImageFileDrop = async (acceptedFiles) => {
    const imageFile = acceptedFiles[0]

    if (Path.extname(imageFile.name).match(/\.(png|jpg)$/i)) {
      try {
        setIsImageFileUploading(true)
        setResultMessage(null)

        const extension = Path.extname(imageFile.name)
        const uploadPath = `customers/${uid}`
        const fileName = `${uid}${extension}`
        const uploadFileRef = storage.ref().child(`${uploadPath}/${fileName}`)
        await uploadFileRef.put(imageFile)

        await db
          .doc(`usersPublic/${uid}`)
          .update({ image: await uploadFileRef.getDownloadURL() })

        setResultMessage(SUCCESS_MESSAGE)
      } catch (e) {
        console.error(e)
        setResultMessage(ERROR_MESSAGE)
      }
      setIsImageFileUploading(false)
    } else {
      setResultMessage(WARNING_MESSAGE)
    }
  }

  const fileDropZone = (
    <Message size='tiny' compact style={{ cursor: 'pointer' }}>
      <Dropzone onDrop={onImageFileDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => {
          return (
            <div {...getRootProps()}>
              <Message.Header style={{ color: 'black' }}>
                プロフィール画像を変更する
              </Message.Header>
              <input {...getInputProps()} />
            </div>
          )
        }}
      </Dropzone>
    </Message>
  )

  const isReady = () => {
    const validate = ajv.compile(CUSTOMER_MYPROFILE_VALIDATION_SCHEMA)
    if (!currentUser) return false
    if (!_.some(_.pick(userPublic.sns, ['twitter', 'discord']))) return false
    return validate(
      _.pick(userPublic, [
        'firstName',
        'lastName',
        'nickname',
        'sns',
        'description',
      ])
    )
  }

  const onClickEditProfile = async () => {
    setIsProfileUpdating(true)
    try {
      await db.doc(`usersPublic/${uid}`).update(userPublic)
      setResultMessage(SUCCESS_MESSAGE)
    } catch (e) {
      console.error('プロフィール更新失敗 : ', e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsProfileUpdating(false)
  }

  const tagCheckList = (tags) =>
    _.map(tags, (tag) => (
      <Grid.Column key={tag.id}>
        <Checkbox
          label={tag.label}
          checked={_.includes(_.keys(userPublic.interested), tag.id)}
          onChange={() => {
            const selectedTags = _.includes(
              _.keys(userPublic.interested),
              tag.id
            )
              ? _.omit(userPublic.interested, [tag.id])
              : { ...userPublic.interested, [tag.id]: tag }
            setUserPublic({ ...userPublic, interested: selectedTags })
          }}
        />
      </Grid.Column>
    ))

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [WARNING_MESSAGE]: <Message warning content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  return (
    <>
      {messageType}
      <Header as='h4' style={{ color: '#fff' }}>
        <Icon name='user' />
        プロフィール画像
      </Header>

      <Segment placeholder secondary>
        {isImageFileUploading && (
          <Dimmer active>
            <Loader>アップロード中</Loader>
          </Dimmer>
        )}
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
              <Image
                src={_.get(userPublic, 'image', '')}
                rounded
                size='small'
                style={{
                  height: 150,
                  width: 'auto',
                }}
              />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              textAlign={'center'}
              style={{
                marginTop: '20px',
                marginBottom: '20px',
              }}>
              {fileDropZone}
              <p style={{ color: 'black' }}>
                ※画像はJPG,PNGのいずれかである必要があります。
              </p>
              <p style={{ color: 'black' }}>
                ※公式のロゴ・画像などの使用は、著作権法に基づき禁止されています。
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Header as='h4' style={{ color: '#fff' }}>
        <Icon name='address card' />
        プロフィール設定
      </Header>

      <Segment secondary>
        {isProfileUpdating && (
          <Dimmer active>
            <Loader>プロフィール更新中</Loader>
          </Dimmer>
        )}
        <Form>
          <Form.Group>
            <Form.Input
              placeholder='山田'
              label='名前(姓)'
              name='lastName'
              value={_.get(userPublic, 'lastName', '')}
              onChange={(e) =>
                setUserPublic({ ...userPublic, lastName: e.target.value })
              }
              width={8}
              maxLength={
                CUSTOMER_MYPROFILE_VALIDATION_SCHEMA.properties.lastName
                  .maxLength
              }
            />
            <Form.Input
              placeholder='太郎'
              label='名前(名)'
              name='firstName'
              value={_.get(userPublic, 'firstName', '')}
              onChange={(e) =>
                setUserPublic({ ...userPublic, firstName: e.target.value })
              }
              width={8}
              maxLength={
                CUSTOMER_MYPROFILE_VALIDATION_SCHEMA.properties.firstName
                  .maxLength
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              placeholder='ジョーズ太郎'
              label='ニックネーム(表示名)'
              name='nickname'
              value={_.get(userPublic, 'nickname', '')}
              onChange={(e) =>
                setUserPublic({ ...userPublic, nickname: e.target.value })
              }
              width={8}
              maxLength={
                CUSTOMER_MYPROFILE_VALIDATION_SCHEMA.properties.nickname
                  .maxLength
              }
            />
          </Form.Group>

          <Form.Group>
            <Form.Input
              placeholder='TwitterID'
              label='Twitter'
              name='twitter'
              value={_.get(userPublic, 'sns.twitter', '')}
              onChange={(e) =>
                setUserPublic({
                  ...userPublic,
                  sns: { ...userPublic.sns, twitter: e.target.value },
                })
              }
              width={8}
              maxLength={
                CUSTOMER_MYPROFILE_VALIDATION_SCHEMA.properties.sns.properties
                  .twitter.maxLength
              }
            />
            <Form.Input
              placeholder='taro#1234'
              label='Discord'
              name='Discord'
              value={_.get(userPublic, 'sns.discord', '')}
              onChange={(e) =>
                setUserPublic({
                  ...userPublic,
                  sns: { ...userPublic.sns, discord: e.target.value },
                })
              }
              width={8}
              maxLength={
                CUSTOMER_MYPROFILE_VALIDATION_SCHEMA.properties.sns.properties
                  .discord.maxLength
              }
            />
          </Form.Group>
          <p>※Twitter/DiscordID どちらか必須</p>
          <p>※コーチとプレイヤーが連絡を取り合う為にTwitterやDiscordのDMは解放しておいてください。 </p>
          <Form.Group>
            <Form.Input
              placeholder='https://www.twitch.tv/'
              label='Twitch'
              name='twitch'
              value={_.get(userPublic, 'sns.twitch', '')}
              onChange={(e) =>
                setUserPublic({
                  ...userPublic,
                  sns: { ...userPublic.sns, twitch: e.target.value },
                })
              }
              width={8}
              maxLength={
                CUSTOMER_MYPROFILE_VALIDATION_SCHEMA.properties.sns.properties
                  .twitch.maxLength
              }
            />
            <Form.Input
              placeholder='https://www.youtube.com/'
              label='YouTube'
              name='youtube'
              value={_.get(userPublic, 'sns.youtube', '')}
              onChange={(e) =>
                setUserPublic({
                  ...userPublic,
                  sns: { ...userPublic.sns, youtube: e.target.value },
                })
              }
              width={8}
              maxLength={
                CUSTOMER_MYPROFILE_VALIDATION_SCHEMA.properties.sns.properties
                  .youtube.maxLength
              }
            />
          </Form.Group>
          <Form.Field>
            <Form.Group style={{ marginTop: 20 }}>
              <Form.TextArea
                placeholder='自己紹介'
                label='自己紹介'
                name='description'
                style={{ height: 200 }}
                value={_.get(userPublic, 'description', '')}
                onChange={(e) =>
                  setUserPublic({ ...userPublic, description: e.target.value })
                }
                width={16}
                maxLength={
                  CUSTOMER_MYPROFILE_VALIDATION_SCHEMA.properties.description
                    .maxLength
                }
              />
            </Form.Group>
          </Form.Field>
          <Button onClick={() => setIsModalOpen(!isModalOpen)}>
            タグを選択
          </Button>
          <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(!isModalOpen)}>
            <style>{responsiveStyle}</style>
            <MediaContextProvider>
              <Modal.Header>タグ選択</Modal.Header>
              <Modal.Content scrolling>
                <Header as='h3'>{REQUIRE_TAG}</Header>
                {_(tags)
                  .filter((tag) => tag.type === REQUIRE_TAG)
                  .groupBy((tag) => tag.category.name)
                  .map((group, key) => (
                    <Fragment key={key}>
                      <Header as='h4'>{key}</Header>
                      <Grid
                        as={Media}
                        columns={3}
                        greaterThanOrEqual='computer'>
                        {tagCheckList(_.sortBy(group, { label: 'その他' }))}
                      </Grid>
                      <Grid as={Media} columns={1} lessThan='computer'>
                        {tagCheckList(_.sortBy(group, { label: 'その他' }))}
                      </Grid>
                    </Fragment>
                  ))
                  .value()}

                <Header as='h4'>その他</Header>
                <Grid as={Media} columns={1} lessThan='computer'>
                  {tagCheckList(
                    _.filter(tags, (tag) => tag.type !== REQUIRE_TAG)
                  )}
                </Grid>
                <Grid as={Media} columns={3} greaterThanOrEqual='computer'>
                  {tagCheckList(
                    _.filter(tags, (tag) => tag.type !== REQUIRE_TAG)
                  )}
                </Grid>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  positive
                  icon='checkmark'
                  labelPosition='right'
                  content='決定'
                  onClick={() => setIsModalOpen(!isModalOpen)}
                />
              </Modal.Actions>
            </MediaContextProvider>
          </Modal>

          <Button
            disabled={!isReady()}
            floated='right'
            primary
            onClick={onClickEditProfile}>
            変更を保存
          </Button>
        </Form>
      </Segment>
    </>
  )
}
