import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, { useState, useContext, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Header, Icon, Table, Dropdown, Label, Form } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import TagLabels from './TagLabels'
import { querySnapToObj } from '../lib/misc'
import { OFFER_STATUS, GAMETITLE_TAG_NAME } from '../lib/constants'

const OFFER_STATUS_OPTIONS = _.map(OFFER_STATUS, (s) => ({
  key: s,
  value: s,
  text: s,
}))

export default () => {
  const { firebase, db, claims } = useContext(FirebaseContext)
  const [offers, setOffers] = useState({})
  const [users, setUsers] = useState({})
  const [usersPublic, setUsersPublic] = useState({})
  const [tags, setTags] = useState({})
  const [selectedCoachId, setSelectedCoachId] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [sortColumn, setSortColumn] = useState('updatedAt')
  const [sortDirection, setSortDirection] = useState('descending')

  useEffect(() => {
    const tagsUnsub = db
      .collection('tags')
      .onSnapshot((snap) => setTags(querySnapToObj(snap)))
    const offersUnsub = db
      .collection('offers')
      .onSnapshot((snap) => setOffers(querySnapToObj(snap)))
    const usersUnsub = db
      .collection('users')
      .where('isCoach', '==', true)
      .onSnapshot((snap) => setUsers(querySnapToObj(snap)))
    const usersPublicUnsub = db
      .collection('usersPublic')
      .onSnapshot((snap) => setUsersPublic(querySnapToObj(snap)))

    return () => {
      tagsUnsub()
      offersUnsub()
      usersUnsub()
      usersPublicUnsub()
    }
  }, [db])

  if (!_.get(claims, 'isAdmin', false)) return <Redirect to='/' />

  const coachOptions = _.map(users, (v) => ({
    key: v.id,
    value: v.id,
    text: _.get(usersPublic, `${v.id}.nickname`, '---'),
  }))

  const selectableTags = _(tags)
    .sortBy((tag) => `${tag.type}${tag.category.name}`)
    .map((tag) => (
      <Label
        key={tag.id}
        tag
        color={_.includes(selectedTags, tag.id) ? 'blue' : 'grey'}
        size='mini'
        style={{ marginRight: 5, marginBottom: 5, cursor: 'pointer' }}
        onClick={() => {
          const selectedTagIds = _.includes(selectedTags, tag.id)
            ? _.filter(selectedTags, (n) => n !== tag.id)
            : _.concat(selectedTags, tag.id)
          setSelectedTags(selectedTagIds)
        }}>
        {tag.type === GAMETITLE_TAG_NAME
          ? `${tag.category.name}・${tag.label}`
          : tag.label}
      </Label>
    ))
    .value()

  const handleSort = (clickedColumn) => {
    if (clickedColumn !== sortColumn) {
      setSortColumn(clickedColumn)
      setSortDirection('descending')
      return
    }
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
  }

  const changeStatus = (offer, value) => {
    try {
      db.doc(`offers/${offer.id}`).update({
        status: value,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
    } catch (e) {
      console.error(e)
    }
  }

  const offersStatsRows = _(offers)
    .filter((offer) =>
      selectedCoachId ? offer.coach.id === selectedCoachId : offer
    )
    .filter(
      !_.isEmpty(selectedTags)
        ? (offer) =>
            _.some(
              _.map(selectedTags, (tag) => _.includes(_.keys(offer.tags), tag))
            )
        : (offer) => offer
    )
    .filter((offer) =>
      selectedStatus ? offer.status === selectedStatus : offer
    )
    .sortBy(sortColumn)
    .thru((v) => (sortDirection === 'descending' ? _.reverse(v) : v))
    .map((offer) => (
      <Table.Row key={offer.id}>
        <Table.Cell>
          <Link to={`/offers/${offer.id}`}>{offer.title}</Link>
        </Table.Cell>
        <Table.Cell collapsing>
          <Link to={`/users/${offer.coach.id}`}>{offer.coach.nickname}</Link>
        </Table.Cell>
        <Table.Cell collapsing>
          <Dropdown
            value={offer.status}
            options={OFFER_STATUS_OPTIONS}
            onChange={(e, { value }) => changeStatus(offer, value)}
          />
        </Table.Cell>
        <Table.Cell>
          <TagLabels tags={offer.tags} />
        </Table.Cell>
        <Table.Cell style={{ color: 'black' }}>
          {offer.createdAt &&
            moment(offer.createdAt.toDate()).format('YYYY/MM/DD HH:mm')}
        </Table.Cell>
        <Table.Cell style={{ color: 'black' }}>
          {offer.updatedAt &&
            moment(offer.updatedAt.toDate()).format('YYYY/MM/DD HH:mm')}
        </Table.Cell>
        <Table.Cell style={{ color: 'black' }} textAlign='right'>
          {numeral(offer.bookedCount).format('0,0')}
        </Table.Cell>
        <Table.Cell style={{ color: 'black' }} textAlign='center'>
          <Link to={`/offers/${offer.id}/edit`}>
            <Icon name='edit' style={{ cursor: 'pointer' }} />
          </Link>
        </Table.Cell>
      </Table.Row>
    ))
    .value()

  return (
    <>
      <Header as='h2' color='teal' textAlign='center'>
        <Icon name='book' />
        <Header.Content>オファー管理</Header.Content>
      </Header>

      <Form>
        <Form.Dropdown
          clearable
          placeholder='コーチを選択'
          search
          selection
          options={coachOptions}
          onChange={(e, { value }) => setSelectedCoachId(value)}
        />
        <Form.Dropdown
          clearable
          placeholder='ステータスを選択'
          search
          selection
          options={OFFER_STATUS_OPTIONS}
          onChange={(e, { value }) => setSelectedStatus(value)}
        />
      </Form>

      <div style={{ marginTop: 15, marginBottom: 30 }}>{selectableTags}</div>

      <Table compact='very' size='small' celled structured sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'title' ? sortDirection : null}
              onClick={() => handleSort('title')}>
              タイトル
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'coach.nickname' ? sortDirection : null}
              onClick={() => handleSort('coach.nickname')}>
              コーチ
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'status' ? sortDirection : null}
              onClick={() => handleSort('status')}>
              ステータス
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'tags' ? sortDirection : null}
              onClick={() => handleSort('tags')}>
              タグ
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'createdAt' ? sortDirection : null}
              onClick={() => handleSort('createdAt')}>
              作成日時
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'updatedAt' ? sortDirection : null}
              onClick={() => handleSort('updatedAt')}>
              更新日時
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign='right'
              sorted={sortColumn === 'bookedCount' ? sortDirection : null}
              onClick={() => handleSort('bookedCount')}>
              チケット数
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>編集</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{offersStatsRows}</Table.Body>
      </Table>
    </>
  )
}
