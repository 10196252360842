import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import {
  Header,
  Segment,
  Button,
  Checkbox,
  Icon,
  Message,
  Loader,
  Dimmer,
} from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'

const SUCCESS_MESSAGE = 'アカウント情報を更新しました。'
const ERROR_MESSAGE =
  'アカウント情報の更新に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default ({ uid }) => {
  const { db } = useContext(FirebaseContext)
  const [user, setUser] = useState({})
  const [resultMessage, setResultMessage] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  useEffect(() => {
    const userUnsub = db
      .doc(`users/${uid}`)
      .onSnapshot((snap) => setUser(snap.data()))
    return () => userUnsub()
  }, [db, uid])

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const onSubmit = () => {
    setIsBusy(true)
    try {
      db.doc(`users/${uid}`).update(user)
      setIsEditMode(false)
      setResultMessage(SUCCESS_MESSAGE)
    } catch (e) {
      console.error(e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusy(false)
  }

  return (
    <>
      {messageType}

      <Header as='h4'>
        <Icon name='lock' />
        コーチ権限とアカウント凍結
      </Header>

      <Segment style={{ width: '80%' }} secondary>
        {isBusy && (
          <Dimmer active>
            <Loader>アカウント情報更新中</Loader>
          </Dimmer>
        )}

        <div style={{ display: 'inline-flex' }}>
          <p>コーチ</p>
          <Checkbox
            style={{ marginLeft: 30 }}
            disabled={!isEditMode}
            name='isCoach'
            checked={_.get(user, 'isCoach', false)}
            onChange={() =>
              setUser({ ...user, isCoach: !_.get(user, 'isCoach', true) })
            }
          />
          {!isEditMode && (
            <Icon
              name='edit'
              style={{ cursor: 'pointer', marginLeft: 50 }}
              onClick={() => setIsEditMode(true)}
            />
          )}
        </div>

        <div />

        <div style={{ display: 'inline-flex' }}>
          <p>凍結</p>

          <Checkbox
            style={{ marginLeft: 44, marginBottom: 10 }}
            name='disabled'
            disabled={!isEditMode}
            checked={_.get(user, 'disabled', false)}
            onChange={() =>
              setUser({ ...user, disabled: !_.get(user, 'disabled', true) })
            }
          />
        </div>

        <div>
          {isEditMode && (
            <Button primary onClick={onSubmit}>
              変更を保存
            </Button>
          )}
        </div>
      </Segment>
    </>
  )
}
