import Ajv from 'ajv'
import React, { useContext, useState } from 'react'
import { Form, Message, Dimmer, Loader } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { SIGNUP_VALIDATION_SCHEMA } from '../lib/validationSchema'
const ajv = new Ajv({ removeAdditional: true })

const SUCCESS_MESSAGE = 'アカウントを作成しました。'
const ERROR_MESSAGE =
  'アカウントの作成に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default () => {
  const { functions } = useContext(FirebaseContext)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [resultMessage, setResultMessage] = useState(null)

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const isReady = () => {
    const validate = ajv.compile(SIGNUP_VALIDATION_SCHEMA)
    return validate({ email, firstName, lastName })
  }

  const onSubmit = async () => {
    setIsBusy(true)
    try {
      await functions.httpsCallable('createAdmin')({
        email,
        firstName,
        lastName,
        origin: window.location.origin,
      })
      setResultMessage(SUCCESS_MESSAGE)
    } catch (e) {
      console.error('アカウント作成失敗 : ', e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusy(false)
  }

  return (
    <>
      {isBusy && (
        <Dimmer active page>
          <Loader>アカウント作成中</Loader>
        </Dimmer>
      )}

      {messageType}

      <Form>
        <Form.Group>
          <Form.Input
            icon='user'
            iconPosition='left'
            placeholder='名前(姓)'
            name='lastName'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            width={4}
            maxLength={SIGNUP_VALIDATION_SCHEMA.properties.lastName.maxLength}
          />
          <Form.Input
            icon='user'
            iconPosition='left'
            placeholder='名前(名)'
            name='firstName'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            width={4}
            maxLength={SIGNUP_VALIDATION_SCHEMA.properties.firstName.maxLength}
          />
        </Form.Group>

        <Form.Group>
          <Form.Input
            icon='mail'
            iconPosition='left'
            placeholder='メールアドレス'
            name='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            width={4}
            maxLength={SIGNUP_VALIDATION_SCHEMA.properties.email.maxLength}
          />
          <Form.Button
            disabled={!isReady()}
            primary
            type='submit'
            content='アカウント作成'
            onClick={onSubmit}
          />
        </Form.Group>
      </Form>
    </>
  )
}
