import _ from 'lodash'
import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { Tab, Header, Icon } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'

import AdminCreateTag from './AdminCreateTag'
import AdminTagList from './AdminTagList'
import AdminCategoryList from './AdminCategoryList'
import AdminCreateCategory from './AdminCreateCategory'

export default () => {
  const { claims } = useContext(FirebaseContext)
  if (!_.get(claims, 'isAdmin', false)) return <Redirect to='/' />

  const panes = [
    {
      menuItem: 'カテゴリ一覧',
      render: () => (
        <Tab.Pane as='div'>
          <AdminCategoryList />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'カテゴリ作成',
      render: () => (
        <Tab.Pane as='div'>
          <AdminCreateCategory />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'タグ一覧',
      render: () => (
        <Tab.Pane as='div'>
          <AdminTagList />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'タグ作成',
      render: () => (
        <Tab.Pane as='div'>
          <AdminCreateTag />
        </Tab.Pane>
      ),
    },
  ]
  return (
    <>
      <Header as='h2' color='teal'>
        <Icon name='tags' />
        カテゴリ・タグ管理
      </Header>
      <Tab style={{background:'#fff'}} menu={{ secondary: true, pointing: true }} panes={panes} />
    </>
  )
}
