import _ from 'lodash'
import Ajv from 'ajv'
import React, { useEffect, useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Header,
  Form,
  Message,
  Rating,
  Divider,
  Loader,
  Dimmer,
  Icon,
  Checkbox,
} from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'

import { REVIEW_VALIDATION_SCHEMA } from '../lib/validationSchema'

const ajv = new Ajv({ removeAdditional: true })

const DEFAULT_RATING = 3
const MAX_RATING = REVIEW_VALIDATION_SCHEMA.properties.rating.maximum
const SUCCESS_MESSAGE = 'レビューを更新しました。'
const ERROR_MESSAGE =
  'レビューの更新に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default ({ match }) => {
  const { db, claims } = useContext(FirebaseContext)
  const [review, setReview] = useState({})
  const [resultMessage, setResultMessage] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    const reviewUnsub = db
      .doc(`reviews/${match.params.reviewId}`)
      .onSnapshot((snap) => setReview(snap.data()))
    return () => reviewUnsub()
  }, [db, match.params.reviewId])

  if (!_.get(claims, 'isAdmin', false)) return <Redirect to='/' />

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const isReady = () => {
    const validate = ajv.compile(REVIEW_VALIDATION_SCHEMA)
    return validate(_.pick(review[('title', 'message')]))
  }

  const onSubmit = async () => {
    setIsBusy(true)
    try {
      await db.doc(`reviews/${match.params.reviewId}`).update(review)
      setResultMessage(SUCCESS_MESSAGE)
    } catch (e) {
      console.error('更新に失敗しました。', e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusy(false)
  }

  return (
    <div style={{backgroundColor:'white',padding:'10px',borderRadius:'5px'}}>
      {isBusy && (
        <Dimmer active page>
          <Loader>レビュー更新中</Loader>
        </Dimmer>
      )}

      <Header as='h2' color='teal'>
        <Icon name='edit' />
        レビュー更新フォーム
      </Header>

      {messageType}

      <Form style={{ marginTop: 20 }}>
        <Form.Input
          placeholder={`レビュータイトル(${REVIEW_VALIDATION_SCHEMA.properties.title.maxLength}文字まで)`}
          name='title'
          value={_.get(review, 'title', '')}
          onChange={(e) => setReview({ ...review, title: e.target.value })}
          maxLength={REVIEW_VALIDATION_SCHEMA.properties.title.maxLength}
        />
        <Form.TextArea
          style={{ height: 200 }}
          placeholder={`レビュー内容をご記入下さい。(${REVIEW_VALIDATION_SCHEMA.properties.message.maxLength}文字まで)`}
          name='message'
          value={_.get(review, 'message', '')}
          onChange={(e) => setReview({ ...review, message: e.target.value })}
          maxLength={REVIEW_VALIDATION_SCHEMA.properties.message.maxLength}
        />
        <Header as='h4'>評価</Header>
        <Rating
          icon='star'
          size='huge'
          maxRating={MAX_RATING}
          defaultRating={_.get(review, 'rating', DEFAULT_RATING)}
          onRate={(e, { rating }) => setReview({ ...review, rating })}
        />
        <Header as='h4'>非表示</Header>
        <Checkbox
          name='isVisible'
          checked={!_.get(review, 'isVisible', true)}
          onChange={() =>
            setReview({
              ...review,
              isVisible: !_.get(review, 'isVisible', true),
            })
          }
        />

        <Divider hidden />

        <Form.Button
          disabled={!isReady()}
          primary
          type='submit'
          content='投稿'
          onClick={onSubmit}
        />
      </Form>
    </div>
  )
}
