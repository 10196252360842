import _ from 'lodash'
import React, { useContext } from 'react'
import { Tab, Header, Icon } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'

import { FirebaseContext } from '../lib/context'

import AdminUserPublicSetting from './AdminUserPublicSetting'
import UserPrivacySetting from './UserPrivacySetting'

export default () => {
  const { currentUser, claims } = useContext(FirebaseContext)
  if (!_.get(claims, 'isAdmin', false)) return <Redirect to='/' />

  const panes = [
    {
      menuItem: 'プロフィール',
      render: () => (
        <Tab.Pane as='div' >
          <AdminUserPublicSetting />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'プライバシー',
      render: () => (
        <Tab.Pane as='div'>
          <UserPrivacySetting uid={currentUser.uid} />
        </Tab.Pane>
      ),
    },
  ]
  return (
    <>
      <Header as='h2' color='teal' textAlign='center'>
        <Icon name='setting' />
        <Header.Content>設定</Header.Content>
      </Header>
      <div style= {{
        backgroundColor: 'white',
      }}>
      <Tab menu={{ secondary: true, pointing: true }}  panes={panes}  />
      </div>
    </>
  )
}
