import _ from 'lodash'
import Ajv from 'ajv'
import React, { useState, useContext, useEffect } from 'react'
import {
  Form,
  Button,
  Message,
  Dimmer,
  Loader,
  Segment,
  Header,
  Icon,
} from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { ADMIN_MYPROFILE_VALIDATION_SCHEMA } from '../lib/validationSchema'

const ajv = new Ajv({ removeAdditional: true })

const SUCCESS_MESSAGE = 'プロフィールを更新しました。'
const ERROR_MESSAGE =
  'プロフィールを更新に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default () => {
  const { currentUser, db } = useContext(FirebaseContext)
  const [userPublic, setUserPublic] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [resultMessage, setResultMessage] = useState(null)

  useEffect(() => {
    const userPublicUnsub = db
      .doc(`usersPublic/${currentUser.uid}`)
      .onSnapshot((snap) => setUserPublic(snap.data()))
    return () => userPublicUnsub()
  }, [db, currentUser.uid])

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const isReady = () => {
    const validate = ajv.compile(ADMIN_MYPROFILE_VALIDATION_SCHEMA)
    return validate(_.pick(userPublic, ['firstName', 'lastName']))
  }

  const onClickEditProfile = async () => {
    setIsBusy(true)
    try {
      await db.doc(`usersPublic/${currentUser.uid}`).update(userPublic)
      setResultMessage(SUCCESS_MESSAGE)
    } catch (e) {
      console.log('プロフィール更新失敗 : ', e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusy(false)
  }

  return (
    <>
      {messageType}
      {isBusy && (
        <Dimmer active page>
          <Loader>プロフィール更新中</Loader>
        </Dimmer>
      )}
      <Header as='h4'>
        <Icon name='address card outline' />
        プロフィール設定
      </Header>
      <Segment secondary style={{ width: '100%' }}>
        <Form>
          <Form.Group>
            <Form.Input
              placeholder='山田'
              label='名前(姓)'
              name='lastName'
              value={_.get(userPublic, 'lastName', '')}
              onChange={(e) =>
                setUserPublic({ ...userPublic, lastName: e.target.value })
              }
              width={8}
            />
            <Form.Input
              placeholder='太郎'
              label='名前(名)'
              name='firstName'
              value={_.get(userPublic, 'firstName', '')}
              onChange={(e) =>
                setUserPublic({ ...userPublic, firstName: e.target.value })
              }
              width={8}
            />
          </Form.Group>
        </Form>

        <div style={{ textAlign: 'right' }}>
          <Button disabled={!isReady()} primary onClick={onClickEditProfile}>
            変更を保存
          </Button>
        </div>
      </Segment>
    </>
  )
}
