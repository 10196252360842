import _ from 'lodash'
import Ajv from 'ajv'
import Path from 'path'
import numeral from 'numeral'
import Dropzone from 'react-dropzone'
import React, { useState, useContext, useEffect, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Header,
  Form,
  Message,
  Modal,
  Button,
  Checkbox,
  Grid,
  Icon,
  Divider,
  Dimmer,
  Loader,
} from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'
import { GAMETITLE_TAG_NAME, REQUIRE_TAG } from '../lib/constants'
import { OFFER_VALIDATION_SCHEMA } from '../lib/validationSchema'

import {
  responsiveStyle,
  Media,
  MediaContextProvider,
} from '../lib/responsiveStyle'

const ajv = new Ajv({ removeAdditional: true })

const SUCCESS_MESSAGE = 'オファーを更新しました。'
const WARNING_MESSAGE = 'JPG,PNGファイルを添付してください。'
const ERROR_MESSAGE =
  'オファーの更新に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'
const PRICE_WARNING_MESSAGE = '100円未満は入力できません。'

export default ({ match }) => {
  const { db, storage, firebase, currentUser } = useContext(FirebaseContext)
  const [offer, setOffer] = useState({})
  const [tags, setTags] = useState({})
  const [resultMessage, setResultMessage] = useState(null)
  const [imageFile, setImageFile] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const offerUnsub = db
      .doc(`offers/${match.params.offerId}`)
      .onSnapshot((snap) => setOffer(snap.data()))
    const tagsUnsub = db
      .collection(`tags`)
      .onSnapshot((snap) => setTags(querySnapToObj(snap)))
    return () => {
      offerUnsub()
      tagsUnsub()
    }
  }, [db, match.params.offerId])

  if (!currentUser) return <Redirect to='/' />

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [WARNING_MESSAGE]: <Message warning content={resultMessage} />,
    [PRICE_WARNING_MESSAGE]: <Message warning content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const isReady = () => {
    const validate = ajv.compile(OFFER_VALIDATION_SCHEMA)
    if (!_.some(_.map(offer.tags, (tag) => tag.type === GAMETITLE_TAG_NAME)))
      return false
    return validate(
      _.pick(offer, ['title', 'description', 'price', 'duration'])
    )
  }

  const priceWarning = (e) => {
    // 下二桁が00以外の場合warning表示
    if (Number(e.target.value) % 100 !== 0) {
      setResultMessage(PRICE_WARNING_MESSAGE)
      return
    }
    setResultMessage(null)
  }

  const onImageFileDrop = (acceptedFiles) => {
    const attachedFile = acceptedFiles[0]
    if (Path.extname(attachedFile.name).match(/\.(png|jpg)$/i)) {
      setImageFile(attachedFile)
      setResultMessage(null)
    } else setResultMessage(WARNING_MESSAGE)
  }

  const fileDropZone = (
    <Message>
      <Dropzone onDrop={onImageFileDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => {
          let messageBody
          if (imageFile) {
            messageBody = (
              <p>
                {imageFile.name} / {numeral(imageFile.size).format('0.0 ib')}
              </p>
            )
          } else {
            messageBody = (
              <p>
                ここにJPG,PNGファイルをドラッグ＆ドロップするか、クリックして選択してください。<br></br>
                <span style={{color:'red'}}>※公式のロゴ・画像などの使用は、著作権法に基づき禁止されています。</span>
              </p>
            )
          }
          return (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Message.Header>オファー画像</Message.Header>
              {messageBody}
            </div>
          )
        }}
      </Dropzone>
    </Message>
  )

  const tagCheckList = (tags) =>
    _.map(tags, (tag) => (
      <Grid.Column key={tag.id}>
        <Checkbox
          label={tag.label}
          checked={_.includes(_.keys(offer.tags), tag.id)}
          onChange={() => {
            const selectedTags = _.includes(_.keys(offer.tags), tag.id)
              ? _.omit(offer.tags, [tag.id])
              : { ...offer.tags, [tag.id]: tag }
            setOffer({ ...offer, tags: selectedTags })
          }}
        />
      </Grid.Column>
    ))

  const onSubmit = async () => {
    setIsBusy(true)
    try {
      const updateData = {
        ...offer,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }

      if (imageFile) {
        const extension = Path.extname(imageFile.name)
        const uploadPath = `customers/${currentUser.uid}/offers`
        const fileName = `${match.params.offerId}${extension}`
        await storage.ref().child(`${uploadPath}/${fileName}`).put(imageFile)

        const downloadUrl = await storage
          .ref()
          .child(`${uploadPath}/${fileName}`)
          .getDownloadURL()

        _.assign(updateData, { image: downloadUrl })
      }

      await db.doc(`offers/${match.params.offerId}`).update(updateData)
      setResultMessage(SUCCESS_MESSAGE)
    } catch (e) {
      console.error('オファー更新失敗 : ', e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusy(false)
  }

  const handleChangedOnlyNumber = (e) => {
    if (!e.target.value.match(/^([0-9\\s])*$/)) return false
    if (
      e.target.name === 'price' &&
      e.target.value > OFFER_VALIDATION_SCHEMA.properties.price.maximum
    )
      return false
    if (
      e.target.name === 'duration' &&
      e.target.value > OFFER_VALIDATION_SCHEMA.properties.duration.maximum
    )
      return false

    if (_.includes(['price', 'duration'], e.target.name))
      setOffer({ ...offer, [e.target.name]: _.toNumber(e.target.value) })
  }

  return (
    <div style={{backgroundColor:'white',padding:'10px',borderRadius:'5px'}}>
      {isBusy && (
        <Dimmer active page>
          <Loader>オファー更新中</Loader>
        </Dimmer>
      )}

      <Header as='h2' color='teal' style={{ marginBottom: 20 }}>
        <Icon name='edit' />
        オファー更新フォーム
      </Header>

      {messageType}

      <Form>
        <Form.Input
          placeholder={`オファー名(${OFFER_VALIDATION_SCHEMA.properties.title.maxLength}文字まで)`}
          name='title'
          value={_.get(offer, 'title', '')}
          onChange={(e) => setOffer({ ...offer, title: e.target.value })}
          maxLength={OFFER_VALIDATION_SCHEMA.properties.title.maxLength}
        />
        <Form.TextArea
          style={{ height: 200 }}
          placeholder={`説明(${OFFER_VALIDATION_SCHEMA.properties.description.maxLength}文字まで)`}
          name='description'
          value={_.get(offer, 'description', '')}
          onChange={(e) => setOffer({ ...offer, description: e.target.value })}
          maxLength={OFFER_VALIDATION_SCHEMA.properties.description.maxLength}
        />
        <Form.Group widths='equal'>
          <Form.Input
            label={`単価(円)(${numeral(
              OFFER_VALIDATION_SCHEMA.properties.price.minimum
            ).format('0,0')}〜${numeral(
              OFFER_VALIDATION_SCHEMA.properties.price.maximum
            ).format('0,0')}円)`}
            name='price'
            value={_.get(offer, 'price', '')}
            onChange={(e) => {
              handleChangedOnlyNumber(e)
              priceWarning(e)
            }}
          />
          <Form.Input
            label={`時間(分)(${numeral(
              OFFER_VALIDATION_SCHEMA.properties.duration.maximum
            ).format('0,0')}分まで)`}
            name='duration'
            value={_.get(offer, 'duration', '')}
            onChange={(e) => handleChangedOnlyNumber(e)}
          />
        </Form.Group>
        <p>※100円未満は入力できません</p>
        <Divider hidden />
        <div
          style={{
            height: 300,
            cursor: 'pointer',
            backgroundImage: `url(${_.get(offer, 'image', '')})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <Button onClick={() => setIsModalOpen(!isModalOpen)}>タグを選択</Button>
        {fileDropZone}
        <Form.Button
          disabled={!isReady()}
          primary
          type='submit'
          content='更新'
          onClick={onSubmit}
        />
      </Form>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)}>
        <style>{responsiveStyle}</style>
        <MediaContextProvider>
          <Modal.Header>タグ選択</Modal.Header>
          <Modal.Content scrolling>
            <Header as='h3'>{REQUIRE_TAG}(必須)</Header>
            {_(tags)
              .filter((tag) => tag.type === REQUIRE_TAG)
              .groupBy((tag) => tag.category.name)
              .map((group, key) => (
                <Fragment key={key}>
                  <Header as='h4'>{key}</Header>
                  <Grid as={Media} columns={3} greaterThanOrEqual='computer'>
                    {tagCheckList(_.sortBy(group, { label: 'その他' }))}
                  </Grid>
                  <Grid as={Media} columns={1} lessThan='computer'>
                    {tagCheckList(_.sortBy(group, { label: 'その他' }))}
                  </Grid>
                </Fragment>
              ))
              .value()}

            <Header as='h4'>その他(任意)</Header>
            <Grid as={Media} columns={1} lessThan='computer'>
              {tagCheckList(_.filter(tags, (tag) => tag.type !== REQUIRE_TAG))}
            </Grid>
            <Grid as={Media} columns={3} greaterThanOrEqual='computer'>
              {tagCheckList(_.filter(tags, (tag) => tag.type !== REQUIRE_TAG))}
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              icon='checkmark'
              labelPosition='right'
              content='決定'
              onClick={() => setIsModalOpen(!isModalOpen)}
            />
          </Modal.Actions>
        </MediaContextProvider>
      </Modal>
    </div>
  )
}
