import _ from 'lodash'
import Ajv from 'ajv'
import React, { useState, useContext, useEffect } from 'react'
import {
  Header,
  Segment,
  Button,
  Form,
  Icon,
  Message,
  Loader,
  Dimmer,
} from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import {
  EMAIL_VALIDATION_SCHEMA,
  DISCORD_VALIDATION_SCHEMA,
} from '../lib/validationSchema'

const ajv = new Ajv({ removeAdditional: true })

const EMAIL_CHANGE_MESSAGE =
  '変更後のメールアドレスに確認メールを送信しました。'
const NOTIFICATION_INFO_UPDATE_MESSAGE = '通知情報の更新が完了しました。'
const ERROR_MESSAGE =
  '処理に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default ({ uid }) => {
  const { db, functions, claims } = useContext(FirebaseContext)
  const [user, setUser] = useState({})
  const [isEmailEdit, setIsEmailEdit] = useState(false)
  const [isDiscordWebhookUrlEdit, setIsDiscordWebhookUrlEdit] = useState(false)
  const [isBusyLoginInfo, setIsBusyLoginInfo] = useState(false)
  const [isBusydiscordInfo, setIsBusydiscordInfo] = useState(false)
  const [resultMessage, setResultMessage] = useState(null)

  useEffect(() => {
    const userUnsub = db
      .doc(`users/${uid}`)
      .onSnapshot((snap) => setUser(snap.data()))

    return () => userUnsub()
  }, [db, uid])

  const messageType = {
    [EMAIL_CHANGE_MESSAGE]: <Message success content={resultMessage} />,
    [NOTIFICATION_INFO_UPDATE_MESSAGE]: (
      <Message success content={resultMessage} />
    ),
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const onEmailUpdateButtonClick = async () => {
    setIsBusyLoginInfo(true)
    try {
      await functions.httpsCallable('updateEmail')(_.pick(user, ['email']))
      setIsEmailEdit(false)
      setResultMessage(EMAIL_CHANGE_MESSAGE)
    } catch (e) {
      console.error(e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusyLoginInfo(false)
  }

  const onNotificationInfoUpdateButtonClick = async () => {
    setIsBusydiscordInfo(true)
    try {
      await functions.httpsCallable('updateCoachDiscordWebhookUrl')(
        _.pick(user, ['discordWebhookUrl'])
      )
      setIsDiscordWebhookUrlEdit(false)
      setResultMessage(NOTIFICATION_INFO_UPDATE_MESSAGE)
    } catch (e) {
      console.error(e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusydiscordInfo(false)
  }

  const isEmailUpdateButtonReady = () => {
    const validate = ajv.compile(EMAIL_VALIDATION_SCHEMA)
    return validate(_.pick(user, ['email']))
  }

  const isNotificationInfoUpdateButtonReady = () => {
    const validate = ajv.compile(DISCORD_VALIDATION_SCHEMA)
    return validate(_.pick(user, ['discordWebhookUrl']))
  }

  return (
    <>
      {messageType}
      <Header as='h4'>
        <Icon name='privacy' />
        ログイン情報
      </Header>

      <Segment secondary>
        {isBusyLoginInfo && (
          <Dimmer active>
            <Loader>処理中</Loader>
          </Dimmer>
        )}

        {_.get(claims, 'firebase.sign_in_provider') === 'password' ? (
          <>
            <p>メールアドレス</p>
            {isEmailEdit ? (
              <Form>
                <Form.Group widths={10}>
                  <Form.Input
                    placeholder='example@example.com'
                    name='email'
                    value={_.get(user, 'email', '')}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                    maxLength={
                      EMAIL_VALIDATION_SCHEMA.properties.email.maxLength
                    }
                    width={6}
                  />
                  <Form.Button
                    disabled={!isEmailUpdateButtonReady()}
                    primary
                    width={4}
                    onClick={onEmailUpdateButtonClick}>
                    変更を保存
                  </Form.Button>
                </Form.Group>
              </Form>
            ) : (
              <div style={{ display: 'inline-flex' }}>
                <p
                  style={{
                    fontWeight: 'bold',
                    marginRight: 20,
                    marginLeft: 20,
                  }}>
                  {_.get(user, 'email', '')}
                </p>
                <Icon
                  name='edit'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsEmailEdit(true)}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <p style={{ color: 'black' }}>Googleアカウントでログイン中</p>
            <p
              style={{
                color: 'black',
                fontWeight: 'bold',
                marginLeft: 20,
              }}>
              {_.get(user, 'email', '')}
            </p>
          </>
        )}
      </Segment>

      {_.get(claims, 'isCoach', false) && (
        <>
          <Header as='h4'>
            <Icon name='bell outline' />
            通知
          </Header>
          <Segment secondary>
            {isBusydiscordInfo && (
              <Dimmer active>
                <Loader>更新中</Loader>
              </Dimmer>
            )}

            <p>Discord WebhookUrl</p>
            {isDiscordWebhookUrlEdit ? (
              <Form>
                <Form.Input
                  placeholder='https://discordapp.com/api/webhooks/xxxxxxx/xxxxxxxxxxxxxx'
                  name='discordWebhookUrl'
                  value={_.get(user, 'discordWebhookUrl', '')}
                  onChange={(e) =>
                    setUser({ ...user, discordWebhookUrl: e.target.value })
                  }
                  maxLength={
                    DISCORD_VALIDATION_SCHEMA.properties.discordWebhookUrl
                      .maxLength
                  }
                />
                <div style={{ textAlign: 'right' }}>
                  <Button
                    disabled={!isNotificationInfoUpdateButtonReady()}
                    primary
                    onClick={onNotificationInfoUpdateButtonClick}>
                    変更を保存
                  </Button>
                </div>
              </Form>
            ) : (
              <div style={{ display: 'inline-flex', width: '80%' }}>
                <p
                  style={{
                    fontWeight: 'bold',
                    marginRight: 20,
                    marginLeft: 20,
                    overflow: 'hidden',
                  }}>
                  {_.get(user, 'discordWebhookUrl', '')
                    ? _.get(user, 'discordWebhookUrl', '')
                    : '設定されていません'}
                </p>
                <Icon
                  name='edit'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsDiscordWebhookUrlEdit(true)}
                />
              </div>
            )}
          </Segment>
        </>
      )}
    </>
  )
}
