import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Icon } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'
import { OFFER_STATUS } from '../lib/constants'
import ResponsiveTable from './ResponsiveTable'

const OFFER_STATUS_OPTIONS = _.map(OFFER_STATUS, (s) => ({
  key: s,
  value: s,
  text: s,
}))

const SORT_OPTIONS = [
  {
    key: 'title',
    text: 'タイトル',
    value: 'title',
  },
  {
    key: 'status',
    text: 'ステータス',
    value: 'status',
  },
  {
    key: 'createdAt',
    text: '作成日時',
    value: 'createdAt',
  },
  {
    key: 'updatedAt',
    text: '更新日時',
    value: 'updatedAt',
  },
  {
    key: 'bookedCount',
    text: 'チケット数',
    value: 'bookedCount',
  },
]

export default () => {
  const { db, currentUser, firebase } = useContext(FirebaseContext)
  const [offers, setOffers] = useState({})

  useEffect(() => {
    const offersUnsub = db
      .collection('offers')
      .where('coach.id', '==', currentUser.uid)
      .onSnapshot((snap) => setOffers(querySnapToObj(snap)))
    return () => offersUnsub()
  }, [db, currentUser.uid])

  const changeStatus = (offer, value) => {
    db.doc(`offers/${offer.id}`).update({
      status: value,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }

  return (
    <>
      <ResponsiveTable
        data={offers}
        columns={[
          {
            text: 'タイトル',
            name: 'title',
            itemRender: (offer) => {
              return <Link to={`/offers/${offer.id}`}>{offer.title}</Link>
            },
          },
          {
            text: 'ステータス',
            name: 'status',
            itemRender: (offer) => {
              return (
                <Dropdown
                  value={offer.status}
                  options={OFFER_STATUS_OPTIONS}
                  onChange={(e, { value }) => changeStatus(offer, value)}
                />
              )
            },
          },
          {
            text: '作成日時',
            name: 'createdAt',
            itemRender: (offer) => {
              return (
                offer.createdAt &&
                moment(offer.createdAt.toDate()).format('YYYY/MM/DD HH:mm')
              )
            },
          },
          {
            text: '更新日時',
            name: 'updatedAt',
            itemRender: (offer) => {
              return (
                offer.updatedAt &&
                moment(offer.updatedAt.toDate()).format('YYYY/MM/DD HH:mm')
              )
            },
          },
          {
            text: 'チケット数',
            name: 'bookedCount',
            itemRender: (offer) => {
              return numeral(offer.bookedCount).format('0,0')
            },
          },
          {
            text: '編集',
            name: 'edit',
            itemRender: (offer) => (
              <Link to={`/offers/${offer.id}/edit`}>
                <Icon name='edit' style={{ cursor: 'pointer' }} />
              </Link>
            ),
          },
        ]}
        sortOptions={SORT_OPTIONS}
        defaultSortColumn={'updatedAt'}
        defaultSortDirection={'descending'}
        searchTargets={(offer) => {
          return [offer.title]
        }}
      />
    </>
  )
}
