import _ from 'lodash'
import Path from 'path'
import numeral from 'numeral'
import Dropzone from 'react-dropzone'
import Ajv from 'ajv'
import React, { useContext, useState, useEffect } from 'react'
import { Form, Message, Dimmer, Loader } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { CATEGORY_VALIDATION_SCHEMA } from '../lib/validationSchema'
import { querySnapToObj } from '../lib/misc'

const ajv = new Ajv({ removeAdditional: true })

const SUCCESS_MESSAGE = 'カテゴリを作成しました。'
const IMAGE_EXTENSION_WARNING_MESSAGE = 'JPG,PNGファイルを添付してください。'
const DUPLICATE_WARNING_MESSAGE = '同一カテゴリ名が存在します。'
const ERROR_MESSAGE =
  'カテゴリの作成に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default () => {
  const { db, storage } = useContext(FirebaseContext)
  const [categories, setCategories] = useState('')
  const [name, setName] = useState('')
  const [imageFile, setImageFile] = useState(null)
  const [resultMessage, setResultMessage] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    const categoriesUnsub = db
      .collection('categories')
      .onSnapshot((snap) => setCategories(querySnapToObj(snap)))
    return () => categoriesUnsub()
  }, [db])

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [DUPLICATE_WARNING_MESSAGE]: <Message warning content={resultMessage} />,
    [IMAGE_EXTENSION_WARNING_MESSAGE]: (
      <Message warning content={resultMessage} />
    ),
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const onImageFileDrop = (acceptedFiles) => {
    const attachedFile = acceptedFiles[0]
    if (Path.extname(attachedFile.name).match(/\.(png|jpg)$/i)) {
      setImageFile(attachedFile)
      setResultMessage(null)
    } else setResultMessage(IMAGE_EXTENSION_WARNING_MESSAGE)
  }

  const fileDropZone = (
    <Message size='mini'>
      <Dropzone onDrop={onImageFileDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => {
          let messageBody
          if (imageFile) {
            messageBody = (
              <p>
                {imageFile.name} / {numeral(imageFile.size).format('0.0 ib')}
              </p>
            )
          } else {
            messageBody = (
              <p>
                ここにJPG,PNGファイルをドラッグ＆ドロップするか、クリックして選択してください。
              </p>
            )
          }
          return (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Message.Header>カテゴリ画像</Message.Header>
              {messageBody}
            </div>
          )
        }}
      </Dropzone>
    </Message>
  )

  const isReady = () => {
    const validate = ajv.compile(CATEGORY_VALIDATION_SCHEMA)
    return validate({ name })
  }

  const onSubmit = async () => {
    setIsBusy(true)
    if (!_.find(categories, { name })) {
      try {
        const extension = Path.extname(imageFile.name)
        const fileName = `${name}${extension}`
        const storageRef = storage.ref().child(`categories/${fileName}`)
        await storageRef.put(imageFile)
        const downloadUrl = await storageRef.getDownloadURL()
        await db.collection('categories').add({ name, image: downloadUrl })
        setName('')
        setImageFile(null)
        setResultMessage(SUCCESS_MESSAGE)
      } catch (e) {
        console.error('カテゴリ作成失敗 : ', e)
        setResultMessage(ERROR_MESSAGE)
      }
    } else setResultMessage(DUPLICATE_WARNING_MESSAGE)
    setIsBusy(false)
  }

  return (
    <>
      {isBusy && (
        <Dimmer active page>
          <Loader>カテゴリ作成中</Loader>
        </Dimmer>
      )}

      {messageType}

      <Form>
        <Form.Input
          icon='tag'
          iconPosition='left'
          placeholder={`カテゴリ名${CATEGORY_VALIDATION_SCHEMA.properties.name.maxLength}文字まで)`}
          name='name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          maxLength={CATEGORY_VALIDATION_SCHEMA.properties.name.maxLength}
        />

        {fileDropZone}

        <Form.Button
          style={{ marginTop: 20 }}
          disabled={!isReady()}
          primary
          type='submit'
          content='カテゴリ作成'
          onClick={onSubmit}
        />
      </Form>
    </>
  )
}
