import { createMedia } from '@artsy/fresnel'

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
  },
})

const responsiveStyle = AppMedia.createMediaStyle()
const { Media, MediaContextProvider } = AppMedia

export { responsiveStyle, Media, MediaContextProvider }
