import React from 'react'
import { Container, Message } from 'semantic-ui-react'

export default () => {
  window.location = 'https://lp.joz.jp'
  return (
    <Container style={{ marginTop: 120 }}>
      <Message compact>
        現在、システムのメンテナンス中です。
        <br />
        お手数ですが、しばらく経ってから、画面をリロードしてください。
      </Message>
    </Container>
  )
}
