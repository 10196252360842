import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Icon } from 'semantic-ui-react'

import {
  responsiveStyle,
  Media,
  MediaContextProvider,
} from '../lib/responsiveStyle'

import TagLabels from './TagLabels'

export default ({ offers }) => {
  const offerList = _.map(offers, (offer) => (
    <Card
      style={{
        background: 'rgba(0, 20, 30, 0.74)',
        border: 'solid 1px transparent',
      }}
      key={offer.id}>
      <Link to={`/offers/${offer.id}`}>
        <div
          style={{
            height: 275,
            cursor: 'pointer',
            backgroundImage: `url(${_.get(offer, 'image', '')})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </Link>
      <Card.Content>
        <Card.Meta style={{ color: '#fff' }}>
          {offer.updatedAt &&
            moment(offer.updatedAt.toDate()).format('YYYY/MM/DD HH:mm')}
        </Card.Meta>
        <div>
          <TagLabels tags={offer.tags} />
        </div>
        <Card.Header
          style={{ height: '90px', textAlign: 'center', padding: '3px' }}>
          <Link style={{ fontSize: '20px' }} to={`/offers/${offer.id}`}>
            {offer.title}
          </Link>
        </Card.Header>
        <div>
          <Card.Description style={{ textAlign: 'center' }}>
            <Link to={`/offers/${offer.id}`}>
              <button
                style={{
                  border: '0',
                  cursor: 'pointer',
                  outline: '0',
                  background:
                    'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)',
                  height: '40px',
                  width: '50%',
                  borderRadius: '5px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}>
                予約
              </button>
            </Link>
          </Card.Description>
        </div>
      </Card.Content>

      <Card.Content extra textAlign='right'>
        <Link
          style={{ color: 'gray' }}
          to={`/users/${_.get(offer, 'coach.id', '')}`}>
          <Icon name='user' />
          {_.get(offer, 'coach.nickname', '---')}
        </Link>
      </Card.Content>
    </Card>
  ))

  return (
    <>
      <style>{responsiveStyle}</style>
      <MediaContextProvider>
        <Card.Group as={Media} itemsPerRow={1} lessThan='computer'>
          {offerList}
        </Card.Group>
        <Card.Group as={Media} itemsPerRow={3} greaterThanOrEqual='computer'>
          {offerList}
        </Card.Group>
      </MediaContextProvider>
    </>
  )
}
