import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Table, Input, Card, Dropdown, Divider } from 'semantic-ui-react'

import {
  responsiveStyle,
  Media,
  MediaContextProvider,
} from '../lib/responsiveStyle'

export default ({
  data,
  columns,
  sortOptions,
  defaultSortColumn,
  defaultSortDirection,
  searchTargets,
}) => {
  const [sortColumn, setSortColumn] = useState(defaultSortColumn)
  const [sortDirection, setSortDirection] = useState(defaultSortDirection)
  const [searchFilter, setSearchFilter] = useState('')

  const handleSort = (clickedColumn) => {
    if (clickedColumn !== sortColumn) {
      setSortColumn(clickedColumn)
      setSortDirection('descending')
      return
    }
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
  }

  const tableRowsRaw = _(data)
    .filter((v) => {
      if (_.isEmpty(searchFilter)) {
        return true
      }
      const targets = searchTargets(v)
      // serchFilterの大文字、小文字を無視して検索
      const regexp = new RegExp(searchFilter, 'gi')
      for (const v of targets) {
        if (regexp.test(v)) {
          return true
        }
      }
      return false
    })
    .sortBy(sortColumn)
    .thru((v) => (sortDirection === 'descending' ? _.reverse(v) : v))
    .value()

  const tableRows = _.map(tableRowsRaw, (item) => (
    <Table.Row key={_.get(item, 'id', '')}>
      {columns.map((c) => (
        <Table.Cell
          key={`${_.get(item, 'id', '')}-${c.name}`}
          textAlign={_.get(c, 'textAlign', 'left')}>
          {c.itemRender(item)}
        </Table.Cell>
      ))}
    </Table.Row>
  ))

  const tableRowsMobile = _.map(tableRowsRaw, (item) => (
    <Card key={_.get(item, 'id', '')} fluid>
      <Card.Content>
        {columns.map((c, i) => (
          <Fragment key={`${_.get(item, 'id', '')}-${c.name}`}>
            <Card.Header style={{ marginTop: i === 0 ? '' : '10px' }}>
              {c.text}
            </Card.Header>
            <Card.Description>{c.itemRender(item)}</Card.Description>
          </Fragment>
        ))}
      </Card.Content>
    </Card>
  ))

  return (
    <>
      <Input
        fluid
        iconPosition='left'
        icon='search'
        placeholder='検索...'
        name='searchFilter'
        value={searchFilter || ''}
        onChange={(e) => setSearchFilter(e.target.value)}
        style={{ marginTop: 10, marginBottom: 20 }}
      />
      <style>{responsiveStyle}</style>
      <MediaContextProvider>
        <Media lessThan='computer'>
          表示順：
          <Dropdown
            inline
            value={sortColumn}
            options={sortOptions}
            onChange={(e, { value }) => handleSort(value)}
          />
        </Media>
        <Divider hidden />
        <Media lessThan='computer'>{tableRowsMobile}</Media>

        <Media greaterThanOrEqual='computer'>
          <Table compact celled structured sortable>
            <Table.Header>
              <Table.Row>
                {columns.map((c) => (
                  <Table.HeaderCell
                    key={c.name}
                    sorted={sortColumn === c.name ? sortDirection : null}
                    onClick={() => handleSort(c.name)}>
                    {c.text}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>{tableRows}</Table.Body>
          </Table>
        </Media>
      </MediaContextProvider>
    </>
  )
}
