import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, { useState, useEffect, useContext } from 'react'
import { Link, Redirect } from 'react-router-dom'
import {
  Dropdown,
  Header,
  Icon,
  Table,
  Divider,
  Card,
  Button,
} from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import {
  TICKET_STATUS,
  NOT_PAYMENT_AND_CANCEL_TICKET_STATUS,
  NOT_CONSUMED_AND_CONSUMED_TICKET_STATUS,
} from '../lib/constants'
import { querySnapToObj } from '../lib/misc'

import {
  responsiveStyle,
  Media,
  MediaContextProvider,
} from '../lib/responsiveStyle'

import ResponsiveTable from './ResponsiveTable'

const TICKET_STATUS_OPTIONS = _.map(TICKET_STATUS, (s) => ({
  key: s,
  value: s,
  text: s,
}))

const NOT_CONSUMED_AND_CONSUMED_TICKET_STATUS_OPTIONS = _.map(
  NOT_CONSUMED_AND_CONSUMED_TICKET_STATUS,
  (s) => ({
    key: s,
    value: s,
    text: s,
  })
)

const NOT_PAYMENT_AND_CANCEL_TICKET_STATUS_OPTIONS = _.map(
  NOT_PAYMENT_AND_CANCEL_TICKET_STATUS,
  (s) => ({
    key: s,
    value: s,
    text: s,
  })
)

const SORT_OPTIONS = [
  {
    key: 'offerTitle',
    text: 'オファー',
    value: 'offer.title',
  },
  {
    key: 'playerName',
    text: 'プレイヤー',
    value: 'player.nickname',
  },
  {
    key: 'status',
    text: 'ステータス',
    value: 'status',
  },
  {
    key: 'createdAt',
    text: '作成日時',
    value: 'createdAt',
  },
  {
    key: 'price',
    text: '単価',
    value: 'price',
  },
  {
    key: 'duration',
    text: '時間',
    value: 'duration',
  },
  {
    key: 'number',
    text: '個数',
    value: 'number',
  },
]

export default () => {
  const { db, claims, currentUser, firebase } = useContext(FirebaseContext)
  const [tickets, setTickets] = useState({})
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [isStatsTableMobileOpen, setIsStatsTableMobileOpen] = useState(false)
  const [commissionRate, setCommissionRate] = useState(0)
  useEffect(() => {
    const ticketsUnsub = db
      .collection('tickets')
      .where('offer.coach.id', '==', currentUser.uid)
      .onSnapshot((snap) => setTickets(querySnapToObj(snap)))

    const commissionRateUnsub = db
      .doc('configs/site')
      .onSnapshot((snap) => setCommissionRate(snap.data().commissionRate))
    return () => {
      ticketsUnsub()
      commissionRateUnsub()
    }
  }, [db, currentUser.uid])

  if (!_.get(claims, 'isCoach', false)) return <Redirect to='/' />

  const ticketsByOfferTitle = _(tickets)
    .groupBy((ticket) => ticket.offer.title)
    .value()

  const offerTitles = _(tickets)
    .map((s) => s.offer.title)
    .uniq()
    .sort()
    .value()

  const changeStatus = (ticket, value) => {
    db.doc(`tickets/${ticket.id}`).update({
      status: value,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }

  const ticketsStatsTableRows = _.map(offerTitles, (title) => (
    <Table.Row key={title}>
      <Table.Cell>{title}</Table.Cell>
      {_.map(TICKET_STATUS, (status) => (
        <Table.Cell key={status} textAlign='right'>
          {numeral(
            _.countBy(
              ticketsByOfferTitle[title],
              (ticket) => ticket.status === status
            ).true
          ).format('0,0')}
          &nbsp;件
        </Table.Cell>
      ))}
      <Table.Cell key={title} textAlign='right'>
        {numeral(ticketsByOfferTitle[title].length).format('0,0')}
        &nbsp;件
      </Table.Cell>
    </Table.Row>
  ))

  const ticketsStatsTableRowsMobile = _.map(offerTitles, (title) => (
    <Card key={title} fluid>
      <Card.Content>
        <React.Fragment key={title}>
          <Card.Header style={{ marginTop: '10px' }}>オファー</Card.Header>
          <Card.Description>{title}</Card.Description>
          {_.map(TICKET_STATUS, (status) => {
            return (
              <React.Fragment key={status}>
                <Card.Header style={{ marginTop: '10px' }}>
                  {status}
                </Card.Header>
                <Card.Description>
                  {numeral(
                    _.countBy(
                      ticketsByOfferTitle[title],
                      (ticket) => ticket.status === status
                    ).true
                  ).format('0,0')}
                  &nbsp;件
                </Card.Description>
              </React.Fragment>
            )
          })}
          <Card.Header style={{ marginTop: '10px' }}>合計</Card.Header>
          <Card.Description>
            {numeral(ticketsByOfferTitle[title].length).format('0,0')}
            &nbsp;件
          </Card.Description>
        </React.Fragment>
      </Card.Content>
    </Card>
  ))

  return (
    <>
      <Header as='h2' color='teal' textAlign='center'>
        <Icon name='handshake outline' />
        <Header.Content>予約・売却済みのチケット</Header.Content>
      </Header>

      <style>{responsiveStyle}</style>
      <MediaContextProvider>
        <Media lessThan='computer'>
          <Header as='h3'>
            <Header.Content>
              オファー別チケット数
              <Button
                icon
                size='huge'
                style={{ marginLeft: 20 }}
                onClick={() =>
                  setIsStatsTableMobileOpen(!isStatsTableMobileOpen)
                }>
                {isStatsTableMobileOpen ? (
                  <Icon name='minus' />
                ) : (
                  <Icon name='plus' />
                )}
              </Button>
            </Header.Content>
          </Header>
          {isStatsTableMobileOpen && ticketsStatsTableRowsMobile}
        </Media>

        <Media greaterThanOrEqual='computer'>
          <Table compact celled structured>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>オファー</Table.HeaderCell>
                {_.map(TICKET_STATUS, (status) => (
                  <Table.HeaderCell key={status}>{status}</Table.HeaderCell>
                ))}
                <Table.HeaderCell textAlign='right'>合計</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{ticketsStatsTableRows}</Table.Body>
          </Table>
        </Media>
      </MediaContextProvider>

      <Divider hidden />

      <Dropdown
        fluid
        clearable
        placeholder='ステータスでフィルター'
        selection
        options={TICKET_STATUS_OPTIONS}
        value={selectedStatus}
        onChange={(e, { value }) => setSelectedStatus(value)}
      />
      <ResponsiveTable
        data={
          selectedStatus
            ? _.filter(tickets, (ticket) => ticket.status === selectedStatus)
            : tickets
        }
        columns={[
          {
            text: 'オファー',
            name: 'offer.title',
            itemRender: (ticket) => (
              <Link to={`/offers/${ticket.offer.id}`}>
                {ticket.offer.title}
              </Link>
            ),
          },
          {
            text: 'プレイヤー',
            name: 'player.nickname',
            itemRender: (ticket) => (
              <Link to={`/users/${ticket.player.id}`}>
                {ticket.player.nickname}
              </Link>
            ),
          },
          {
            text: 'ステータス',
            name: 'status',
            itemRender: (ticket) => (
              <Dropdown
                value={ticket.status}
                options={
                  _.includes(
                    NOT_PAYMENT_AND_CANCEL_TICKET_STATUS,
                    ticket.status
                  )
                    ? NOT_PAYMENT_AND_CANCEL_TICKET_STATUS_OPTIONS
                    : NOT_CONSUMED_AND_CONSUMED_TICKET_STATUS_OPTIONS
                }
                onChange={(e, { value }) => changeStatus(ticket, value)}
              />
            ),
          },
          {
            text: '作成日時',
            name: 'createdAt',
            itemRender: (ticket) =>
              ticket.createdAt &&
              moment(ticket.createdAt.toDate()).format('YYYY/MM/DD HH:mm'),
          },
          {
            text: '単価',
            name: 'price',
            itemRender: (tikect) =>
              _.get(tikect, 'price', '') && (
                <>{numeral(tikect.price).format('0,0')} &nbsp;円</>
              ),
          },
          {
            text: '時間',
            name: 'duration',
            itemRender: (ticket) =>
              _.get(ticket, 'duration', '') && (
                <>{numeral(ticket.duration).format('0,0')} &nbsp;分</>
              ),
          },
          {
            text: '個数',
            name: 'number',
            itemRender: (ticket) => <>{_.get(ticket, 'number', 0)}&nbsp;個</>,
          },
          {
            text: (
              <>
                手数料
                <br />
                差し引き金額
                <br />
                (手数料{commissionRate}%)
              </>
            ),
            name: 'commissionRate',
            itemRender: (ticket) => (
              <>
                {numeral(
                  ticket.price * ticket.number * (1 - commissionRate / 100) // 合計金額から手数料を引いた額を計算 commissionRate(%)
                ).format('0,0')}
                &nbsp;円
              </>
            ),
          },
          {
            text: 'レビュー投稿',
            name: 'toPostReviewPage',
            textAlign: 'center',
            itemRender: (ticket) => (
              <Link to={`/new-review/reviewee/${ticket.player.id}/player`}>
                <Icon name='edit' style={{ cursor: 'pointer' }} />
              </Link>
            ),
          },
        ]}
        sortOptions={SORT_OPTIONS}
        defaultSortColumn={'createdAt'}
        defaultSortDirection={'descending'}
        searchTargets={(ticket) => {
          return [ticket.offer.title, ticket.status, ticket.player.nickname]
        }}
      />
      <p>
        ※手数料差し引き金額(手数料{commissionRate}
        %)は振り込み手数料を含みません。
      </p>
    </>
  )
}
