import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Container } from 'semantic-ui-react'
import { StripeProvider, Elements } from 'react-stripe-elements'

import { WithFirebase } from './lib/hooks'
import { FirebaseContext } from './lib/context'
import StripeHookProvider from './lib/stripeHookProvider'
import { FirebaseInitWrapper, MaintenanceMode, CheckUserDoc } from './lib/hocs'

import TopMenu from './components/TopMenu'
import Footer from './components/Footer'
import TopPage from './components/TopPage'
import SignIn from './components/SignIn'
import SignUp from './components/SignUp'
import Contact from './components/Contact'
import Categories from './components/Categories'
import GameTitlesFilterByCategory from './components/GameTitlesFilterByCategory'
import OffersFilterByGameTitle from './components/OffersFilterByGameTitle'
import OfferDetail from './components/OfferDetail'
import EditOffer from './components/EditOffer'
import UserDetail from './components/UserDetail'
import NewsList from './components/NewsList'
import UserSettings from './components/UserSettings'
import BookedTicketList from './components/BookedTicketList'
import CreateReview from './components/CreateReview'
import AdminUserSettings from './components/AdminUserSettings'
import AdminOffersManagement from './components/AdminOffersManagement'
import AdminTicketsManagement from './components/AdminTicketsManagement'
import AdminReviewsManagement from './components/AdminReviewsManagement'
import AdminEditReview from './components/AdminEditReview'
import AdminCustomerUsersManagement from './components/AdminCustomerUsersManagement'
import AdminAdminUsersManagement from './components/AdminAdminUsersManagement'
import AdminNewsManagement from './components/AdminNewsManagement'
import AdminEditNews from './components/AdminEditNews'
import AdminCategoriesAndTagsManagements from './components/AdminCategoriesAndTagsManagements'
import MyOffersManagement from './components/MyOffersManagement'
import SoldTicketList from './components/SoldTicketList'
import PersonalInfoPdf from './components/PersonalInfoPdf'
import SpecifiedCommercialPdf from './components/SpecifiedCommercialPdf'
import TagManager from 'react-gtm-module'

const tagManagerArgs = { gtmId: 'GTM-5BSNL2S' }

TagManager.initialize(tagManagerArgs)

const Routes = () => (
  <Switch>
    <Route exact path='/' component={TopPage} />
    <Route path='/sign-in' component={SignIn} />
    <Route path='/sign-up' component={SignUp} />
    <Route path='/contact' component={Contact} />
    <Route exact path='/categories' component={Categories} />
    <Route
      exact
      path='/categories/:categoryId'
      component={GameTitlesFilterByCategory}
    />
    <Route
      path='/categories/:categoryId/game-title-tags/:tagId'
      component={OffersFilterByGameTitle}
    />
    <Route exact path='/offers/:offerId' component={OfferDetail} />
    <Route
      path='/new-review/reviewee/:revieweeId/:revieweeRole'
      component={CreateReview}
    />
    <Route path='/offers/:offerId/edit' component={EditOffer} />
    <Route exact path='/users/:uid' component={UserDetail} />
    <Route path='/users/:uid/settings' component={UserSettings} />
    <Route path='/news' component={NewsList} />
    <Route path='/tickets/booked' component={BookedTicketList} />
    <Route path='/admin/users/:userId' component={AdminUserSettings} />
    <Route path='/admin/offers/management' component={AdminOffersManagement} />
    <Route
      path='/admin/tickets/management'
      component={AdminTicketsManagement}
    />
    <Route
      path='/admin/reviews/management'
      component={AdminReviewsManagement}
    />
    <Route path='/admin/reviews/:reviewId/edit' component={AdminEditReview} />
    <Route
      path='/admin/customer-users/management'
      component={AdminCustomerUsersManagement}
    />
    <Route
      path='/admin/admin-users/management'
      component={AdminAdminUsersManagement}
    />
    <Route path='/admin/news/management' component={AdminNewsManagement} />
    <Route path='/admin/news/:newsId/edit' component={AdminEditNews} />
    <Route
      path='/admin/categories-tags/management'
      component={AdminCategoriesAndTagsManagements}
    />
    <Route path='/my-offers/management' component={MyOffersManagement} />
    <Route path='/tickets/sold' component={SoldTicketList} />
    <Route path='/personal-info' component={PersonalInfoPdf} />
    <Route path='/specified-commercial' component={SpecifiedCommercialPdf} />
  </Switch>
)
export default () => (
  <FirebaseContext.Provider value={WithFirebase()}>
    <FirebaseInitWrapper>
      <MaintenanceMode>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
          <Elements>
            <StripeHookProvider>
              <BrowserRouter>
                <Container style={{ marginTop: 25 }}>
                  <TopMenu style={{ marginTop: 25 }} />
                  <CheckUserDoc>
                    <Routes />
                  </CheckUserDoc>
                  <Footer />
                </Container>
              </BrowserRouter>
            </StripeHookProvider>
          </Elements>
        </StripeProvider>
      </MaintenanceMode>
    </FirebaseInitWrapper>
  </FirebaseContext.Provider>
)
