import _ from 'lodash'
import moment from 'moment'
import React, { useContext, useState, useEffect } from 'react'
import { Table, Input, Checkbox, Icon } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'

import { querySnapToObj } from '../lib/misc'

export default () => {
  const { db } = useContext(FirebaseContext)
  const [usersPublic, setUsersPublic] = useState({})
  const [users, setUsers] = useState({})
  const [updatingUid, setUpdatingUid] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')
  const [sortColumn, setSortColumn] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState('descending')

  useEffect(() => {
    const usersPublicUnsub = db
      .collection('usersPublic')
      .onSnapshot((snap) => setUsersPublic(querySnapToObj(snap)))
    const usersUnsub = db
      .collection('users')
      .where('isAdmin', '==', true)
      .onSnapshot((snap) => setUsers(querySnapToObj(snap)))
    return () => {
      usersPublicUnsub()
      usersUnsub()
    }
  }, [db])

  const handleSort = (clickedColumn) => {
    if (clickedColumn !== sortColumn) {
      setSortColumn(clickedColumn)
      setSortDirection('descending')
      return
    }
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
  }

  const onModifyRecordText = ({ key, target }) => {
    if (key !== 'Enter') {
      return
    }

    if (target.name === 'email') {
      db.doc(`users/${updatingUid}`).update({
        [target.name]: target.value,
      })
    } else {
      db.doc(`usersPublic/${updatingUid}`).update({
        [target.name]: target.value,
      })
    }

    setUpdatingUid(null)
  }

  const onModifyRecordCheck = (e, { name, checked }) => {
    db.doc(`users/${updatingUid}`).update({
      [name]: checked,
    })
    setUpdatingUid(null)
  }
  const EditableCell = ({ rec, attr }) => {
    if (updatingUid === rec.id) {
      return (
        <Input
          fluid
          name={attr}
          defaultValue={rec[attr]}
          onKeyPress={onModifyRecordText}
        />
      )
    }
    return _.get(rec, attr, '')
  }

  const adminRows = _(usersPublic)
    .filter((user) => _.get(users, `${user.id}.isAdmin`, false))
    .filter((user) => {
      if (_.isEmpty(searchFilter)) {
        return true
      }
      const targets = _.values(_.pick(user, ['firstName', 'lastName']))
      targets.push(users[user.id].email)
      // serchFilterの大文字、小文字を無視して検索
      const regexp = new RegExp(searchFilter, 'gi')
      for (const v of targets) {
        if (regexp.test(v)) {
          return true
        }
      }
      return false
    })
    .sortBy(sortColumn)
    .thru((v) => (sortDirection === 'descending' ? _.reverse(v) : v))
    .map((user) => (
      <Table.Row key={user.id}>
        <Table.Cell>
          <EditableCell rec={user} attr='lastName' />
        </Table.Cell>
        <Table.Cell>
          <EditableCell rec={user} attr='firstName' />
        </Table.Cell>
        <Table.Cell>
          <EditableCell rec={users[user.id]} attr='email' />
        </Table.Cell>
        <Table.Cell>
          {_.get(user, 'createdAt')
            ? moment(user.createdAt.toDate()).format('YYYY/MM/DD HH:mm')
            : '--:--'}
        </Table.Cell>
        <Table.Cell textAlign='center'>
          {updatingUid === user.id ? (
            <Checkbox
              name='disabled'
              checked={users[user.id].disabled}
              onChange={onModifyRecordCheck}
            />
          ) : (
            users[user.id].disabled && <Icon name='check' />
          )}
        </Table.Cell>
        <Table.Cell textAlign='center'>
          <Icon
            name='edit'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              user.id === updatingUid
                ? setUpdatingUid(null)
                : setUpdatingUid(user.id)
            }}
          />
        </Table.Cell>
      </Table.Row>
    ))
    .value()

  return (
    <>
      <Input
        fluid
        iconPosition='left'
        icon='search'
        placeholder='検索...'
        name='searchFilter'
        value={searchFilter}
        onChange={(e) => setSearchFilter(e.target.value)}
      />
      <Table compact celled structured sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'lastName' ? sortDirection : null}
              onClick={() => handleSort('lastName')}>
              名前(姓)
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'firstName' ? sortDirection : null}
              onClick={() => handleSort('firstName')}>
              名前(名)
            </Table.HeaderCell>
            <Table.HeaderCell>メールアドレス</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'createdAt' ? sortDirection : null}
              onClick={() => handleSort('createdAt')}>
              作成日時
            </Table.HeaderCell>

            <Table.HeaderCell
              textAlign='center'
              sorted={sortColumn === 'disabled' ? sortDirection : null}
              onClick={() => handleSort('disabled')}>
              凍結
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>編集</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{adminRows}</Table.Body>
      </Table>
    </>
  )
}
