import _ from 'lodash'
import React, { useEffect, useState, useContext } from 'react'
import { Input, Label, Header, Icon } from 'semantic-ui-react'

import OfferList from './OfferList'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'
import { GAMETITLE_TAG_NAME, OFFER_DISABLED_STATUS } from '../lib/constants'

export default ({ match }) => {
  const { db } = useContext(FirebaseContext)
  const [tags, setTags] = useState({})
  const [offers, setOffers] = useState({})
  const [searchFilter, setSearchFilter] = useState('')
  const [selectedTags, setSelectedTags] = useState([])

  useEffect(() => {
    const tagUnsub = db.collection('tags').onSnapshot((snap) => {
      setTags(querySnapToObj(snap))
    })
    const offerUnsub = db.collection('offers').onSnapshot((snap) => {
      setOffers(querySnapToObj(snap))
    })
    return () => {
      tagUnsub()
      offerUnsub()
    }
  }, [db])

  const selectableTags = _(tags)
    .filter((tag) => tag.type !== GAMETITLE_TAG_NAME)
    .map((tag) => (
      <Label
        key={tag.id}
        tag
        color={_.includes(selectedTags, tag.id) ? 'blue' : 'grey'}
        size='mini'
        style={{ marginRight: 5, marginBottom: 5, cursor: 'pointer' }}
        onClick={() =>
          setSelectedTags(
            _.includes(selectedTags, tag.id)
              ? _.filter(selectedTags, (n) => n !== tag.id)
              : _.concat(selectedTags, tag.id)
          )
        }>
        {tag.label}
      </Label>
    ))
    .value()

  return (
    <>
      <Header as='h2' color='teal' textAlign='center' style={{ marginTop: 10 }}>
        <Icon name='search' />
        <Header.Content>オファーを探す</Header.Content>
      </Header>

      <Input
        fluid
        iconPosition='left'
        icon='search'
        placeholder='検索...'
        name='searchFilter'
        value={searchFilter}
        onChange={(e) => setSearchFilter(e.target.value)}
        style={{ marginTop: 15 }}
      />
      <div style={{ marginTop: 15, marginBottom: 30 }}>{selectableTags}</div>

      <OfferList
        offers={_.filter(offers, (offer) => {
          if (
            _.some([
              offer.status === OFFER_DISABLED_STATUS,
              !_.includes(_.keys(offer.tags), match.params.tagId),
            ])
          ) {
            return false
          }
          if (!_.isEmpty(searchFilter)) {
            // serchFilterの大文字、小文字を無視して検索
            const regexp = new RegExp(searchFilter, 'gi')
            const targets = _.values({
              ..._.pick(offer, ['title', 'description']),
              ..._.pick(offer.coach, ['nickname']),
            })
            if (!_.some(_.map(targets, (target) => regexp.test(target)))) {
              return false
            }
          }
          if (!_.isEmpty(selectedTags)) {
            if (
              !_.some(
                _.map(selectedTags, (tag) =>
                  _.includes(_.keys(offer.tags), tag)
                )
              )
            ) {
              return false
            }
          }
          return true
        })}
      />
    </>
  )
}
