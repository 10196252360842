import _ from 'lodash'
import React from 'react'
import { Label } from 'semantic-ui-react'

import { GAMETITLE_TAG_NAME } from '../lib/constants'

export default ({ tags }) => {
  const items = _.map(tags, (tag) => (
    <Label
      key={tag.id}
      style={{ marginRight: 5, marginBottom: 5 }}
      tag
      color='blue'
      size='mini'>
      {tag.type === GAMETITLE_TAG_NAME
        ? `${tag.category.name}・${tag.label}`
        : tag.label}
    </Label>
  ))
  return <div>{items}</div>
}
