import Ajv from 'ajv'
import React, { useState, useContext } from 'react'
import { Form, Message, Dimmer, Loader } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { NEWS_VALIDATION_SCHEMA } from '../lib/validationSchema'
import { NEWS_ABLED_STATUS } from '../lib/constants'

const ajv = new Ajv({ removeAdditional: true })

const SUCCESS_MESSAGE = 'お知らせを作成しました。'
const ERROR_MESSAGE =
  'お知らせの作成に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default () => {
  const { db, firebase } = useContext(FirebaseContext)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [resultMessage, setResultMessage] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const isReady = () => {
    const validate = ajv.compile(NEWS_VALIDATION_SCHEMA)
    return validate({ title, description })
  }

  const onSubmit = async () => {
    setIsBusy(true)
    try {
      await db.collection('news').add({
        title,
        description,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        status: NEWS_ABLED_STATUS,
      })
      setResultMessage(SUCCESS_MESSAGE)
    } catch (e) {
      console.error('お知らせ作成失敗', e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusy(false)
  }

  return (
    <>
      {isBusy && (
        <Dimmer active page>
          <Loader>お知らせ作成中</Loader>
        </Dimmer>
      )}

      {messageType}

      <Form>
        <Form.Input
          placeholder={`タイトル(${NEWS_VALIDATION_SCHEMA.properties.title.maxLength}文字まで)`}
          name='title'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          maxLength={NEWS_VALIDATION_SCHEMA.properties.title.maxLength}
        />
        <Form.TextArea
          style={{ height: 200 }}
          placeholder={`お知らせをご記入ください。(${NEWS_VALIDATION_SCHEMA.properties.description.maxLength}文字まで)`}
          name='description'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          maxLength={NEWS_VALIDATION_SCHEMA.properties.description.maxLength}
        />
        <Form.Button
          disabled={!isReady()}
          primary
          type='submit'
          content='作成'
          onClick={onSubmit}
        />
      </Form>
    </>
  )
}
