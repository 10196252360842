import _ from 'lodash'
import React, { useContext } from 'react'
import { Tab, Header, Icon } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'

import { FirebaseContext } from '../lib/context'

import CustomerUserPublicSetting from './CustomerUserPublicSetting'
import UserPrivacySetting from './UserPrivacySetting'
import PaymentInfoSetting from './PaymentInfoSetting'
import AdminCustomerUserAuthoritySetting from './AdminCustomerUserAuthoritySetting'

const panStyle = {
  style: {
    marginTop: '30px',
    marginBottom: '30px',
  },
}

export default ({ match }) => {
  const { currentUser, claims } = useContext(FirebaseContext)

  if (!currentUser) return <Redirect to='/' />
  if (!_.get(claims, 'isAdmin', false))
    if (!(currentUser.uid === match.params.uid)) return <Redirect to='/' />

  const panes = [
    {
      menuItem: 'プロフィール',
      render: () => (
        <Tab.Pane as='div' {...panStyle}>
          <CustomerUserPublicSetting uid={match.params.uid} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'プライバシー',
      render: () => (
        <Tab.Pane as='div' {...panStyle}>
          <UserPrivacySetting uid={match.params.uid} />
        </Tab.Pane>
      ),
    },
    _.get(claims, 'isAdmin', false)
      ? {
          menuItem: '権限とアカウント凍結',
          render: () => (
            <Tab.Pane as='div' {...panStyle}>
              <AdminCustomerUserAuthoritySetting uid={match.params.uid} />
            </Tab.Pane>
          ),
        }
      : {
          menuItem: '決済情報',
          render: () => (
            <Tab.Pane as='div' {...panStyle}>
              <PaymentInfoSetting uid={match.params.uid} />
            </Tab.Pane>
          ),
        },
  ]

  return (
    <>
      <Header as='h2' color='teal' textAlign='center'>
        <Icon name='setting' />
        <Header.Content>設定</Header.Content>
      </Header>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        style={{ backgroundColor: '#fff', maxWidth: '100%' }}
      />
    </>
  )
}
