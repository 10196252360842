import _ from 'lodash'
import firebase from './firebase'
import { useEffect, useState } from 'react'

const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()
const functions = firebase.functions()

const FIREBASE_INIT = {
  firebase,
  auth,
  db,
  storage,
  functions,
}

export const WithFirebase = () => {
  const [initializing, setInitializing] = useState(!firebase.auth().currentUser)
  const [currentUser, setCurrentUser] = useState(null)
  const [claims, setClaims] = useState(null)
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        console.log(`sign in  ${user.uid}`)

        const tokenResult = await user.getIdTokenResult(true)
        setClaims(tokenResult.claims)

        console.log(
          `login provider: ${tokenResult.claims.firebase.sign_in_provider}`
        )
        console.log(`admin:${_.get(tokenResult.claims, 'isAdmin', false)}`)
        console.log(`coach:${_.get(tokenResult.claims, 'isCoach', false)}`)
      } else {
        console.log('sign out')
        setClaims(null)
      }

      setCurrentUser(user)
      setInitializing(false)
    })

    return () => unsubscribe()
  }, [])

  return { ...FIREBASE_INIT, initializing, currentUser, claims }
}
