import _ from 'lodash'
import Ajv from 'ajv'
import React, { useState, useEffect, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Header,
  Form,
  Message,
  Rating,
  Divider,
  Loader,
  Dimmer,
  Icon,
} from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { REVIEW_VALIDATION_SCHEMA } from '../lib/validationSchema'

const ajv = new Ajv({ removeAdditional: true })

const DEFAULT_RATING = 3
const MAX_RATING = REVIEW_VALIDATION_SCHEMA.properties.rating.maximum

const SUCCESS_MESSAGE = 'レビューを投稿しました。'
const ERROR_MESSAGE =
  'レビュー投稿に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default ({ match }) => {
  const { db, functions, currentUser } = useContext(FirebaseContext)
  const [reviewee, setReviewee] = useState({})
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [rating, setRating] = useState(DEFAULT_RATING)
  const [resultMessage, setResultMessage] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    const revieweeUnsub = db
      .doc(`usersPublic/${match.params.revieweeId}`)
      .onSnapshot((snap) => setReviewee(snap.data()))
    return () => revieweeUnsub()
  }, [db, match.params.revieweeId])

  if (!currentUser) return <Redirect to='/' />

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const isReady = () => {
    const validate = ajv.compile(REVIEW_VALIDATION_SCHEMA)
    if (!currentUser) {
      return false
    }
    return validate({ title, message })
  }

  const onSubmit = async () => {
    setIsBusy(true)
    try {
      await functions.httpsCallable('submitReview')({
        revieweeId: match.params.revieweeId,
        title,
        message,
        rating,
        revieweeRole: match.params.revieweeRole,
      })
      setResultMessage(SUCCESS_MESSAGE)
      setTitle('')
      setMessage('')
    } catch (e) {
      console.error('投稿に失敗しました。', e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusy(false)
  }

  return (
    <div style={{backgroundColor:'white',padding:'10px',borderRadius:'5px'}}>
      <div
        style={{
          borderTop: 'thick solid teal',
          marginTop: 5,
          marginBottom: 20,
          width: '100%',
          height: 0,
          paddingTop:
            'calc(300 / 1280 * 100%)' /* calc(画像高さ ÷ 画像横幅 × 100%) */,
          background:
            'url(https://images.unsplash.com/photo-1461773518188-b3e86f98242f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&h=300&q=80) center center / cover no-repeat',
        }}
      />
      {isBusy && (
        <Dimmer active page>
          <Loader>レビュー投稿中</Loader>
        </Dimmer>
      )}

      {messageType}

      <Header as='h2' color='teal'>
        <Icon name='edit' />
        レビュー投稿フォーム
      </Header>

      <Header as='h4'>レビュー対象者</Header>
      <p style={{ marginLeft: 10 }}>{_.get(reviewee, 'nickname', '--')}</p>

      <Form style={{ marginTop: 20 }}>
        <Form.Input
          placeholder={`レビュータイトル(${REVIEW_VALIDATION_SCHEMA.properties.title.maxLength}文字まで)`}
          name='title'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          maxLength={REVIEW_VALIDATION_SCHEMA.properties.title.maxLength}
        />
        <Form.TextArea
          style={{ height: 200 }}
          placeholder={`レビュー内容をご記入下さい。(${REVIEW_VALIDATION_SCHEMA.properties.message.maxLength}文字まで)`}
          name='message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          maxLength={REVIEW_VALIDATION_SCHEMA.properties.message.maxLength}
        />
        <Header as='h4'>評価</Header>
        <Rating
          icon='star'
          size='huge'
          maxRating={MAX_RATING}
          defaultRating={DEFAULT_RATING}
          onRate={(e, { rating }) => setRating(rating)}
        />

        <Divider hidden />

        <Form.Button
          disabled={!isReady()}
          primary
          type='submit'
          content='投稿'
          onClick={onSubmit}
        />
      </Form>
      <Divider hidden />
    </div>
  )
}
