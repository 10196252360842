import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Menu,
  Container,
  Icon,
  Dropdown,
  Divider,
  Image,
} from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'

import {
  responsiveStyle,
  Media,
  MediaContextProvider,
} from '../lib/responsiveStyle'

const ResponsiveMenuItem = (props) => (
  <>
    <Menu.Item
      as={Media}
      style={{
        alignSelf: 'flex-end',
      }}
      lessThan='computer'
      {...props}
    />
    <Menu.Item
      as={Media}
      style={{
        alignSelf: 'center',
      }}
      greaterThanOrEqual='computer'
      {...props}
    />
  </>
)

const RightAlignedDropdownItem = (props) => (
  <Dropdown.Item style={{ textAlign: 'right', width: '100%' }} {...props} />
)

const absoluteMenuStyle = {
  position: 'absolute',
  right: '0px',
  marginLeft: '-100px',
}

export default () => {
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const { auth, currentUser, claims } = useContext(FirebaseContext)

  const adminMenu = () => {
    return (
      <Dropdown
        item
        text='管理者用メニュー'
        style={{ color: '#fff', justifyContent: 'flex-end' }}>
        <Dropdown.Menu style={absoluteMenuStyle}>
          <RightAlignedDropdownItem>
            <Link
              to={`/admin/users/${currentUser.uid}`}
              style={{ color: 'black' }}>
              設定
            </Link>
          </RightAlignedDropdownItem>
          <RightAlignedDropdownItem>
            <Link to='/admin/offers/management' style={{ color: 'black' }}>
              オファー管理
            </Link>
          </RightAlignedDropdownItem>
          <RightAlignedDropdownItem>
            <Link to='/admin/tickets/management' style={{ color: 'black' }}>
              チケット管理
            </Link>
          </RightAlignedDropdownItem>
          <RightAlignedDropdownItem>
            <Link to='/admin/reviews/management' style={{ color: 'black' }}>
              レビュー管理
            </Link>
          </RightAlignedDropdownItem>
          <RightAlignedDropdownItem>
            <Link
              to='/admin/customer-users/management'
              style={{ color: 'black' }}>
              顧客アカウント管理
            </Link>
          </RightAlignedDropdownItem>
          <RightAlignedDropdownItem>
            <Link to='/admin/admin-users/management' style={{ color: 'black' }}>
              管理者アカウント管理
            </Link>
          </RightAlignedDropdownItem>
          <RightAlignedDropdownItem>
            <Link to='/admin/news/management' style={{ color: 'black' }}>
              お知らせ管理
            </Link>
          </RightAlignedDropdownItem>
          <RightAlignedDropdownItem>
            <Link
              to='/admin/categories-tags/management'
              style={{ color: 'black' }}>
              カテゴリ・タグ管理
            </Link>
          </RightAlignedDropdownItem>
          <RightAlignedDropdownItem onClick={() => auth.signOut()}>
            サインアウト
          </RightAlignedDropdownItem>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const nonAdminMenu = () => {
    return (
      <Dropdown
        item
        text='マイページ'
        style={{ color: '#fff', justifyContent: 'flex-end' }}>
        <Dropdown.Menu style={absoluteMenuStyle}>
          <RightAlignedDropdownItem>
            <Link
              to={`/users/${currentUser.uid}/settings`}
              style={{ color: 'black' }}>
              設定
            </Link>
          </RightAlignedDropdownItem>

          {_.get(claims, 'isCoach', false) && (
            <>
              <RightAlignedDropdownItem>
                <Link to='/my-offers/management' style={{ color: 'black' }}>
                  オファー管理
                </Link>
              </RightAlignedDropdownItem>
              <RightAlignedDropdownItem>
                <Link to='/tickets/sold' style={{ color: 'black' }}>
                  予約・売却済みのチケット
                </Link>
              </RightAlignedDropdownItem>
            </>
          )}
          <RightAlignedDropdownItem>
            <Link to='/tickets/booked' style={{ color: 'black' }}>
              予約・購入済みのチケット
            </Link>
          </RightAlignedDropdownItem>
          <RightAlignedDropdownItem onClick={() => auth.signOut()}>
            サインアウト
          </RightAlignedDropdownItem>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const menuContents = () => {
    const content = (() => {
      if (currentUser) {
        return _.get(claims, 'isAdmin', false) ? adminMenu() : nonAdminMenu()
      }
      return (
        <>
          <ResponsiveMenuItem>
            <Link
              to='/sign-up'
              style={{ color: '#fff' }}
              id='toSignUpInTopMenu'>
              新規登録
            </Link>
          </ResponsiveMenuItem>
          <ResponsiveMenuItem>
            <Link
              to='/sign-in'
              style={{ color: '#fff' }}
              id='toSignInInTopMenu'>
              ログイン
            </Link>
          </ResponsiveMenuItem>
        </>
      )
    })()

    return (
      <>
        <ResponsiveMenuItem>
          <Link
            to='/categories'
            style={{ color: '#fff' }}
            id='toOfferSearchInTopMenu'>
            オファーを探す
          </Link>
        </ResponsiveMenuItem>
        <ResponsiveMenuItem>
          <Link to='/contact' style={{ color: '#fff' }} id='toContactInTopMenu'>
            お問い合わせ
          </Link>
        </ResponsiveMenuItem>
        <ResponsiveMenuItem>
          <a
            rel='noopener noreferrer'
            target='_blank'
            style={{ color: '#fff' }}
            href='https://support.joz.jp'>
            利用マニュアル
          </a>
        </ResponsiveMenuItem>
        {_.get(claims, 'isCoach', false) && (
          <ResponsiveMenuItem>
            <a
              rel='noopener noreferrer'
              target='_blank'
              style={{ color: '#fff' }}
              href='https://joz.playse.io/student/login'>
              コーチ用eラーニング
            </a>
          </ResponsiveMenuItem>
        )}
        {content}
      </>
    )
  }

  return (
    <>
      <style>{responsiveStyle}</style>
      <MediaContextProvider>
        <Menu secondary>
          <ResponsiveMenuItem style={{ alignSelf: 'flex-start' }}>
            <Link to='/' id='titleInTopMenu'>
              <Image
                src='/assets/joz_logo.png'
                size='small'
                style={{ cursor: 'pointer' }}
              />
            </Link>
          </ResponsiveMenuItem>

          <Container
            as={Media}
            lessThan='computer'
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              flexDirection: 'column',
              position: 'relative',
            }}>
            <div
              onClick={() => setSidebarOpened(!sidebarOpened)}
              style={{
                display: 'flex',
                marginTop: '10px',
                marginBottom: '10px',
              }}>
              <Icon name={sidebarOpened ? 'close' : 'bars'} size={'big'} />
            </div>

            {sidebarOpened && (
              <Menu.Menu
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}>
                {menuContents()}
              </Menu.Menu>
            )}
          </Container>

          <Menu.Menu as={Media} position='right' greaterThanOrEqual='computer'>
            {menuContents()}
          </Menu.Menu>
        </Menu>

        <Divider />
      </MediaContextProvider>
    </>
  )
}
