import _ from 'lodash'
import React, { useEffect, useState, useContext } from 'react'
import { Header, Message, Image, Icon, Divider, Grid } from 'semantic-ui-react'

import TagLabels from './TagLabels'
import OfferList from './OfferList'
import ReviewList from './ReviewList'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'
import { OFFER_DISABLED_STATUS } from '../lib/constants'

import {
  responsiveStyle,
  Media,
  MediaContextProvider,
} from '../lib/responsiveStyle'

export default ({ match }) => {
  const { db, claims } = useContext(FirebaseContext)
  const [offers, setOffers] = useState({})
  const [coachReviews, setCoachReviews] = useState({})
  const [playerReviews, setPlayerReviews] = useState({})
  const [userPublic, setUserPublic] = useState({})
  useEffect(() => {
    const offersUnsub = db
      .collection('offers')
      .where('coach.id', '==', match.params.uid)
      .onSnapshot((snap) => setOffers(querySnapToObj(snap)))

    const userPublicUnsub = db
      .doc(`usersPublic/${match.params.uid}`)
      .onSnapshot((snap) => setUserPublic(snap.data()))

    const coachReviewsUnsub = db
      .collection('reviews')
      .where('isVisible', '==', true)
      .where('reviewee.id', '==', match.params.uid)
      .where('revieweeRole', '==', 'coach')
      .onSnapshot((snap) => setCoachReviews(querySnapToObj(snap)))

    if (
      !_.some([
        _.get(claims, 'isAdmin', false),
        _.get(claims, 'isCoach', false),
      ])
    ) {
      setPlayerReviews({})
      return
    }
    const playerReviewsUnsub = db
      .collection('reviews')
      .where('isVisible', '==', true)
      .where('reviewee.id', '==', match.params.uid)
      .where('revieweeRole', '==', 'player')
      .onSnapshot((snap) => setPlayerReviews(querySnapToObj(snap)))

    return () => {
      offersUnsub()
      userPublicUnsub()
      coachReviewsUnsub()
      playerReviewsUnsub()
    }
  }, [db, match.params.uid, claims])

  const snsIcons = (
    <div style={{ marginBottom: 10 }}>
      {_.get(userPublic, 'sns.twitter', '') && (
        <a
          rel='noopener noreferrer'
          target='_blank'
          style={{ paddingRight: 10 }}
          href={`https://www.twitter.com/${_.get(
            userPublic,
            'sns.twitter',
            ''
          )}`}>
          <Icon name='twitter' size='big' />
        </a>
      )}
      {_.get(userPublic, 'sns.twitch', '') && (
        <a
          rel='noopener noreferrer'
          target='_blank'
          style={{ paddingRight: 10 }}
          href={_.get(userPublic, 'sns.twitch', '')}>
          <Icon name='twitch' size='big' color='purple' />
        </a>
      )}
      {_.get(userPublic, 'sns.youtube', '') && (
        <a
          rel='noopener noreferrer'
          target='_blank'
          style={{ paddingRight: 10 }}
          href={_.get(userPublic, 'sns.youtube', '')}>
          <Icon name='youtube' size='big' color='red' />
        </a>
      )}
      {_.get(userPublic, 'sns.discord', '') && (
        <span style={{ color: '#fff' }}>
          <Icon name='discord' size='big' color='blue' />
          {_.get(userPublic, 'sns.discord', '')}
        </span>
      )}
    </div>
  )

  const userInfo = (
    <>
      <Header style={{ color: '#fff' }} as='h2'>
        {_.get(userPublic, 'nickname', '---')}
      </Header>
      {snsIcons}
      <TagLabels tags={_.get(userPublic, 'interested', {})} />
      <Message style={{ whiteSpace: 'pre-wrap' }}>
        {_.get(userPublic, 'description', '---')}
      </Message>
    </>
  )

  return (
    <>
      <style>{responsiveStyle}</style>
      <MediaContextProvider>
        <Grid as={Media} at='mobile'>
          <Grid.Row centered>
            <Grid.Column style={{ maxWidth: '200px' }}>
              <Image src={_.get(userPublic, 'image', '')} rounded />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>{userInfo}</Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid as={Media} at='tablet'>
          <Grid.Row>
            <Grid.Column width={6}>
              <Image src={_.get(userPublic, 'image', '')} rounded />
            </Grid.Column>
            <Grid.Column width={10}>{userInfo}</Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid as={Media} greaterThanOrEqual='computer'>
          <Grid.Row>
            <Grid.Column width={4}>
              <Image src={_.get(userPublic, 'image', '')} rounded />
            </Grid.Column>
            <Grid.Column width={12}>{userInfo}</Grid.Column>
          </Grid.Row>
        </Grid>
      </MediaContextProvider>

      <Header as='h2' color='teal'>
        <Icon name='discourse' />
        オファー一覧
      </Header>
      <OfferList
        offers={_(offers)
          .filter((offer) => offer.status !== OFFER_DISABLED_STATUS)
          .orderBy('createdAt', 'desc')
          .value()}
      />

      <Divider hidden />

      <Header as='h2' color='teal'>
        <Icon name='write' />
        レビュー
      </Header>
      <ReviewList
        reviews={_.orderBy(
          { ...coachReviews, ...playerReviews },
          'createdAt',
          'desc'
        )}
      />
    </>
  )
}
