import _ from 'lodash'
import React, { useEffect, useState, useContext, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Image, Header, Icon, List, Divider } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'

export default ({ match }) => {
  const { db } = useContext(FirebaseContext)
  const [tags, setTags] = useState({})

  useEffect(() => {
    const tagsUnsub = db
      .collection('tags')
      .where('category.id', '==', match.params.categoryId)
      .onSnapshot((snap) => {
        setTags(querySnapToObj(snap))
      })
    return () => tagsUnsub()
  }, [db, match.params.categoryId])

  const listItems = _(tags)
    .sortBy({ label: 'その他' })
    .map((tag) => (
      <Fragment key={tag.label}>
        <List.Item>
          <Image src={tag.category.image} size='mini' />
          <List.Content style={{ marginLeft: 20 }}>
            <Link
              to={`/categories/${match.params.categoryId}/game-title-tags/${tag.id}`}>
              {_.get(tag, 'pictureUrl') ? (
                <>
                  <Image src={tag.pictureUrl} size='medium' />
                  <Header
                    style={{ textAlign: 'center' }}
                    subheader={tag.rightNotation}
                  />
                </>
              ) : (
                <List.Header style={{ color: '#fff' }} as='h1'>
                  {tag.label}
                </List.Header>
              )}
            </Link>
          </List.Content>
        </List.Item>
        <Divider hidden />
      </Fragment>
    ))
    .value()

  return (
    <>
      <Header as='h2' color='teal' textAlign='center' style={{ marginTop: 10 }}>
        <Icon name='search' />
        <Header.Content>オファーを探す</Header.Content>
      </Header>
      <Divider hidden />
      <List
        selection
        verticalAlign='middle'
        style={{ marginLeft: 50, marginBottom: 100 }}>
        {listItems}
      </List>
    </>
  )
}
