import _ from 'lodash'
import Ajv from 'ajv'
import Path from 'path'
import numeral from 'numeral'
import Dropzone from 'react-dropzone'
import React, { useState, useContext, useEffect, Fragment } from 'react'
import {
  Header,
  Form,
  Message,
  Modal,
  Button,
  Checkbox,
  Grid,
  Divider,
  Dimmer,
  Loader,
} from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'
import { REQUIRE_TAG, GAMETITLE_TAG_NAME } from '../lib/constants'
import { OFFER_VALIDATION_SCHEMA } from '../lib/validationSchema'

import {
  responsiveStyle,
  Media,
  MediaContextProvider,
} from '../lib/responsiveStyle'

const ajv = new Ajv({ removeAdditional: true })

const SUCCESS_MESSAGE = 'オファーを作成しました。'
const WARNING_MESSAGE = 'JPG,PNGファイルを添付してください。'
const ERROR_MESSAGE =
  'オファーの作成に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'
const PRICE_WARNING_MESSAGE = '100円未満は入力できません。'

export default () => {
  const { db, functions, storage, currentUser } = useContext(FirebaseContext)
  const [tags, setTags] = useState({})
  const [title, setTitle] = useState('')
  const [price, setPrice] = useState(0)
  const [duration, setDuration] = useState(0)
  const [description, setDescription] = useState('')
  const [imageFile, setImageFile] = useState('')
  const [checkedTags, setCheckedTags] = useState({})
  const [resultMessage, setResultMessage] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const tagsUnsub = db
      .collection('tags')
      .onSnapshot((snap) => setTags(querySnapToObj(snap)))
    return () => tagsUnsub()
  }, [db])

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [WARNING_MESSAGE]: <Message warning content={resultMessage} />,
    [PRICE_WARNING_MESSAGE]: <Message warning content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const priceWarning = (e) => {
    // 下二桁が00以外の場合warning表示
    if (Number(e.target.value) % 100 !== 0) {
      setResultMessage(PRICE_WARNING_MESSAGE)
      return
    }
    setResultMessage(null)
  }

  const isReady = () => {
    const validate = ajv.compile(OFFER_VALIDATION_SCHEMA)
    if (!_.some(_.map(checkedTags, (tag) => tag.type === GAMETITLE_TAG_NAME)))
      return false

    if (!imageFile) return false
    return validate({ title, description, price, duration })
  }

  const onImageFileDrop = (acceptedFiles) => {
    const attatchedFile = acceptedFiles[0]

    if (Path.extname(attatchedFile.name).match(/\.(png|jpg)$/i)) {
      setImageFile(attatchedFile)
      setResultMessage(null)
    } else setResultMessage(WARNING_MESSAGE)
  }

  const fileDropZone = (
    <Message>
      <Dropzone onDrop={onImageFileDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => {
          let messageBody
          if (imageFile) {
            messageBody = (
              <p>
                {imageFile.name} / {numeral(imageFile.size).format('0.0 ib')}
              </p>
            )
          } else {
            messageBody = (
              <p>
                ここにJPG,PNGファイルをドラッグ＆ドロップするか、クリックして選択してください。<br></br>
                <span style={{color:'red'}}>※公式のロゴ・画像などの使用は、著作権法に基づき禁止されています。</span>
              </p>
            )
          }

          return (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Message.Header>オファー画像</Message.Header>
              {messageBody}
            </div>
          )
        }}
      </Dropzone>
    </Message>
  )

  const onSubmit = async () => {
    setIsBusy(true)
    try {
      const { data } = await functions.httpsCallable('submitOffer')({
        title,
        description,
        tagCheckList: checkedTags,
        price,
        duration,
      })
      const extension = Path.extname(imageFile.name)
      const uploadPath = `customers/${currentUser.uid}/offers/`
      const fileName = `${data.offerId}${extension}`
      await storage.ref().child(`${uploadPath}/${fileName}`).put(imageFile)
      const downloadUrl = await storage
        .ref()
        .child(`${uploadPath}/${fileName}`)
        .getDownloadURL()
      await db.doc(`/offers/${data.offerId}`).update({
        image: downloadUrl,
      })
      setTitle('')
      setDescription('')
      setPrice(0)
      setDuration(0)
      setCheckedTags({})
      setImageFile('')
      setResultMessage(SUCCESS_MESSAGE)
    } catch (e) {
      console.error('オファー作成失敗 : ', e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusy(false)
  }

  const tagCheckList = (tags) =>
    _.map(tags, (tag) => (
      <Grid.Column key={tag.id}>
        <Checkbox
          label={tag.label}
          checked={_.includes(_.keys(checkedTags), tag.id)}
          onChange={() => {
            const selectedTags = _.includes(_.keys(checkedTags), tag.id)
              ? _.omit(checkedTags, [tag.id])
              : { ...checkedTags, [tag.id]: tag }
            setCheckedTags(selectedTags)
          }}
        />
      </Grid.Column>
    ))

  const handleChangedOnlyNumber = (e) => {
    if (!e.target.value.match(/^([0-9\\s])*$/)) return false
    if (
      e.target.name === 'price' &&
      e.target.value <= OFFER_VALIDATION_SCHEMA.properties.price.maximum
    )
      setPrice(_.toNumber(e.target.value))
    if (
      e.target.name === 'duration' &&
      e.target.value <= OFFER_VALIDATION_SCHEMA.properties.duration.maximum
    )
      setDuration(_.toNumber(e.target.value))
  }

  return (
    <div style={{backgroundColor:'white',padding:'10px',borderRadius:'5px'}}>
      {isBusy && (
        <Dimmer active page>
          <Loader>オファー作成中</Loader>
        </Dimmer>
      )}

      {messageType}

      <Form>
        <Form.Input
          placeholder={`オファー名(${OFFER_VALIDATION_SCHEMA.properties.title.maxLength}文字まで)`}
          name='title'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          maxLength={OFFER_VALIDATION_SCHEMA.properties.title.maxLength}
        />
        <Form.TextArea
          style={{ height: 200 }}
          placeholder={`説明(${OFFER_VALIDATION_SCHEMA.properties.description.maxLength}文字まで)`}
          name='description'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          maxLength={OFFER_VALIDATION_SCHEMA.properties.description.maxLength}
        />
        <Form.Group widths='equal'>
          <Form.Input
            label={`単価(円)(${numeral(
              OFFER_VALIDATION_SCHEMA.properties.price.minimum
            ).format('0,0')}〜${numeral(
              OFFER_VALIDATION_SCHEMA.properties.price.maximum
            ).format('0,0')}円)`}
            name='price'
            value={price}
            onChange={(e) => {
              handleChangedOnlyNumber(e)
              priceWarning(e)
            }}
          />
          <Form.Input
            label={`時間(分)(${numeral(
              OFFER_VALIDATION_SCHEMA.properties.duration.maximum
            ).format('0,0')}分まで)`}
            name='duration'
            value={duration}
            onChange={(e) => handleChangedOnlyNumber(e)}
          />
        </Form.Group>
        <p>※100円未満は入力できません</p>
        <Divider hidden />

        <Button onClick={() => setIsModalOpen(!isModalOpen)}>タグを選択</Button>
        {fileDropZone}
        <Form.Button
          disabled={!isReady()}
          primary
          type='submit'
          content='作成'
          onClick={onSubmit}
        />
      </Form>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)}>
        <style>{responsiveStyle}</style>
        <MediaContextProvider>
          <Modal.Header>タグ選択</Modal.Header>
          <Modal.Content style={{ overflow: 'auto', maxHeight: 400 }}>
            <Header as='h4'>{REQUIRE_TAG}(必須)</Header>
            {_(tags)
              .filter((tag) => tag.type === REQUIRE_TAG)
              .groupBy((tag) => tag.category.name)
              .map((group, key) => (
                <Fragment key={key}>
                  <Header as='h4'>{key}</Header>
                  <Grid as={Media} columns={3} greaterThanOrEqual='computer'>
                    {tagCheckList(_.sortBy(group, { label: 'その他' }))}
                  </Grid>
                  <Grid as={Media} columns={1} lessThan='computer'>
                    {tagCheckList(_.sortBy(group, { label: 'その他' }))}
                  </Grid>
                </Fragment>
              ))
              .value()}

            <Header as='h4'>その他(任意)</Header>
            <Grid as={Media} columns={1} lessThan='computer'>
              {tagCheckList(_.filter(tags, (tag) => tag.type !== REQUIRE_TAG))}
            </Grid>
            <Grid as={Media} columns={3} greaterThanOrEqual='computer'>
              {tagCheckList(_.filter(tags, (tag) => tag.type !== REQUIRE_TAG))}
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              icon='checkmark'
              labelPosition='right'
              content='決定'
              onClick={() => setIsModalOpen(!isModalOpen)}
            />
          </Modal.Actions>
        </MediaContextProvider>
      </Modal>
    </div>
  )
}
