import _ from 'lodash'
import React, { useState, useContext, useEffect } from 'react'
import {
  Header,
  Segment,
  Button,
  Form,
  Icon,
  Message,
  Loader,
  Dimmer,
} from 'semantic-ui-react'
import {
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement,
} from 'react-stripe-elements'

import { FirebaseContext } from '../lib/context'
import { useStripe } from '../lib/stripeHookProvider'

const SUCCESS_MESSAGE = 'クレジットカード情報の登録が完了しました。'
const ERROR_MESSAGE =
  '処理に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default ({ uid }) => {
  const stripe = useStripe()
  const { db, functions } = useContext(FirebaseContext)
  const [user, setUser] = useState({})
  const [isEditMode, setIsEditMode] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [isValidCardNumber, setIsValidCardNumber] = useState(false)
  const [isValidCardExpiry, setIsValidCardExpiry] = useState(false)
  const [isValidCardCvc, setIsValidCardCvcy] = useState(false)
  const [resultMessage, setResultMessage] = useState(null)
  const [
    creditCardValidationErrorMessage,
    setCreditCardValidationErrorMessage,
  ] = useState(null)

  useEffect(() => {
    const userUnsub = db
      .doc(`users/${uid}`)
      .onSnapshot((snap) => setUser(snap.data()))

    return () => userUnsub()
  }, [db, uid])

  const isReady = _.every([
    isValidCardCvc,
    isValidCardExpiry,
    isValidCardNumber,
  ])

  const handleChange = (data) => {
    if (data.error) {
      setCreditCardValidationErrorMessage(data.error.message)
      setValidationResult(data.elementType, false)
    } else {
      setCreditCardValidationErrorMessage(null)
      setValidationResult(data.elementType, data.complete)
    }
  }

  const setValidationResult = (elementType, isValid) => {
    switch (elementType) {
      case 'cardNumber':
        setIsValidCardNumber(isValid)
        break
      case 'cardExpiry':
        setIsValidCardExpiry(isValid)
        break
      case 'cardCvc':
        setIsValidCardCvcy(isValid)
        break
      default:
        console.error('存在しないパターンです')
    }
  }

  const onClickCreditCardTokenUpdateButton = async () => {
    setIsBusy(true)
    try {
      const { token } = await stripe.createToken()
      await functions.httpsCallable('generateStripeCustomerId')({
        token,
      })
      setIsEditMode(false)
      setResultMessage(SUCCESS_MESSAGE)
    } catch (e) {
      console.error(e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusy(false)
  }

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const cardInfoContent = () => {
    return isEditMode ? (
      <>
        {creditCardValidationErrorMessage && (
          <Message error content={creditCardValidationErrorMessage} />
        )}
        <Form>
          <p style={{ fontWeight: 'bold' }}>カード番号</p>
          <CardNumberElement onChange={handleChange} />
          <p style={{ fontWeight: 'bold' }}>有効期限</p>
          <CardExpiryElement onChange={handleChange} />
          <p style={{ fontWeight: 'bold' }}>セキュリティコード</p>
          <CardCVCElement onChange={handleChange} />

          <div style={{ textAlign: 'right' }}>
            <Button
              primary
              disabled={!isReady}
              onClick={onClickCreditCardTokenUpdateButton}>
              変更を保存
            </Button>
          </div>
        </Form>
      </>
    ) : (
      <div style={{ display: 'inline-flex' }}>
        <p
          style={{
            color: 'black',
            fontWeight: 'bold',
            marginRight: 20,
            marginLeft: 20,
            overflow: 'hidden',
          }}>
          {_.get(user, 'stripeCustomerId', '')
            ? '登録済みです'
            : '登録されていません'}
        </p>
        <Icon
          name='edit'
          style={{ cursor: 'pointer' }}
          onClick={() => setIsEditMode(true)}
        />
      </div>
    )
  }

  return (
    <>
      {messageType}

      <Header as='h4' style={{ color: 'black' }}>
        <Icon name='credit card outline' />
        クレジットカード情報
      </Header>
      <Segment secondary>
        {isBusy && (
          <Dimmer active>
            <Loader>登録中</Loader>
          </Dimmer>
        )}

        <p style={{ color: 'black' }}>クレジットカード情報</p>
        {cardInfoContent()}
      </Segment>
    </>
  )
}
