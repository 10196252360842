import _ from 'lodash'
import Ajv from 'ajv'
import React, { useState, useContext, useEffect } from 'react'

import { Header, Form, Message, Dimmer, Loader, Icon } from 'semantic-ui-react'

import { querySnapToObj } from '../lib/misc'
import { FirebaseContext } from '../lib/context'
import { CONTACT_VALIDATION_SCHEMA } from '../lib/validationSchema'

const ajv = new Ajv({ removeAdditional: true })

const SUCCESS_MESSAGE = 'お問い合わせを送信しました。'
const ERROR_MESSAGE =
  'お問い合わせの送信に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default () => {
  const { functions, db } = useContext(FirebaseContext)
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [category, setCategory] = useState('')
  const [template, setTemplate] = useState({})
  const [resultMessage, setResultMessage] = useState('')
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    const contactTempleteUnsub = db
      .collection('contactTemplate')
      .orderBy('displayOrder')
      .onSnapshot((snap) => setTemplate(querySnapToObj(snap)))
    return () => contactTempleteUnsub()
  }, [db])

  const categoryOptions = _.map(template, (v) => ({
    key: v.id,
    value: v.category,
    text: v.category,
  }))

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const isReady = () => {
    const validate = ajv.compile(CONTACT_VALIDATION_SCHEMA)
    return validate({ email, category, message })
  }

  const onSubmit = async () => {
    setIsBusy(true)
    try {
      await functions.httpsCallable('sendContact')({
        email,
        category,
        message,
      })
      setResultMessage(SUCCESS_MESSAGE)
    } catch (e) {
      console.error('お問い合わせ送信失敗 : ', e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusy(false)
  }

  return (
    <>
      <div
        style={{
          borderTop: 'thick solid lightblue',
          marginTop: 5,
          marginBottom: 20,
          width: '100%',
          height: 0,
          paddingTop:
            'calc(300 / 1280 * 100%)' /* calc(画像高さ ÷ 画像横幅 × 100%) */,
          background:
            'url(https://images.unsplash.com/photo-1471107340929-a87cd0f5b5f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&h=300&q=80) center center / cover no-repeat',
        }}
      />

      {isBusy && (
        <Dimmer active page>
          <Loader>送信中</Loader>
        </Dimmer>
      )}

      {messageType}

      <Header as='h2' color='teal' id='contactHeader'>
        <Icon name='envelope outline' />
        お問い合わせフォーム
      </Header>

      <Form>
        <Form.Input
          id='inputID'
          placeholder='メールアドレス'
          name='email'
          value={email}
          maxLength={CONTACT_VALIDATION_SCHEMA.properties.email.maxLength}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
        />
        <Form.Select
          id='inputID'
          placeholder='カテゴリを選択'
          selection
          name='category'
          value={category}
          options={categoryOptions}
          onChange={(e, { value }) => {
            setCategory(value)
            // Firestoreから取得してきた文字列は改行文字がエスケープされているのでreplaceAll('\\n','\n')
            setMessage(
              _.find(template, { category: value })
                .template.split('\\n')
                .join('\n')
            )
          }}
        />
        <Form.TextArea
          id='inputID'
          style={{ height: 200, whiteSpace: 'pre-wrap' }}
          placeholder={`お問い合わせ内容をご記入ください。(${CONTACT_VALIDATION_SCHEMA.properties.message.maxLength}文字まで)`}
          name='message'
          value={message}
          maxLength={CONTACT_VALIDATION_SCHEMA.properties.message.maxLength}
          onChange={(e) => {
            setMessage(e.target.value)
          }}
        />
        <Form.Button
          disabled={!isReady()}
          primary
          type='submit'
          content='送信'
          onClick={onSubmit}
          style={{ marginBottom: 50 }}
        />
      </Form>
    </>
  )
}
