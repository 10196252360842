import _ from 'lodash'
import numeral from 'numeral'
import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import {
  Header,
  Image,
  Grid,
  Icon,
  Divider,
  Message,
  Dimmer,
  Loader,
  Form,
  Segment,
} from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { querySnapToObj } from '../lib/misc'
import { OFFER_DISABLED_STATUS } from '../lib/constants'
import { RESERVE_TICKET_VALIDATION_SCHEMA } from '../lib/validationSchema'

import {
  responsiveStyle,
  Media,
  MediaContextProvider,
} from '../lib/responsiveStyle'

import ReviewList from './ReviewList'
import TagLabels from './TagLabels'

const SUCCESS_MESSAGE = 'チケットを予約しました。'
const ERROR_MESSAGE =
  'チケットの予約に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'
const OFFER_DISABLED_MESSAGE = '予約を受け付けていません。'
const ADMIN_TICKET_RESERVE_MESSAGE = '管理者アカウントでは予約を行えません。'

const NO_LOGIN_MESSAGE = (
  <div>
    予約をするには
    <Link to='/sign-up'>新規登録</Link>
    を行ってください。
  </div>
)
const NON_CREDITCARD_INFO_MESSAGE = (uid) => {
  return (
    <div style={{ color: 'black' }}>
      予約をするには
      <Link to={`/users/${uid}/settings`}>
        {`「マイページ > 設定 > 決済情報」`}
      </Link>
      からクレジットカードを登録してください。
    </div>
  )
}

export default ({ match }) => {
  const { db, currentUser, functions, claims } = useContext(FirebaseContext)
  const [offer, setOffer] = useState({})
  const [reviews, setReviews] = useState({})
  const [user, setUser] = useState({})
  const [number, setNumber] = useState(0)
  const [resultMessage, setResultMessage] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    if (_.get(claims, 'isAdmin', false)) {
      setResultMessage(ADMIN_TICKET_RESERVE_MESSAGE)
    }

    const offerUnsub = db
      .doc(`offers/${match.params.offerId}`)
      .onSnapshot(async (snap) => {
        setOffer(snap.data())
        const reviewSnap = await db
          .collection('reviews')
          .where('isVisible', '==', true)
          .where('reviewee.id', '==', _.get(snap.data(), 'coach.id'))
          .where('revieweeRole', '==', 'coach')
          .get()
        setReviews(querySnapToObj(reviewSnap))
        if (snap.data().status === OFFER_DISABLED_STATUS) {
          setResultMessage(OFFER_DISABLED_MESSAGE)
        }
      })

    if (!currentUser) {
      setResultMessage(NO_LOGIN_MESSAGE)
      return
    }
    const userUnsub = db
      .doc(`users/${_.get(currentUser, 'uid', '')}`)
      .onSnapshot((snap) => {
        setUser(snap.data())
        if (!snap.data().stripeCustomerId) {
          setResultMessage(NON_CREDITCARD_INFO_MESSAGE(currentUser.uid))
        }
      })

    return () => {
      offerUnsub()
      userUnsub()
    }
  }, [db, currentUser, match.params.offerId, claims])

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
    [OFFER_DISABLED_MESSAGE]: <Message warning content={resultMessage} />,
    [ADMIN_TICKET_RESERVE_MESSAGE]: <Message warning content={resultMessage} />,
    [NO_LOGIN_MESSAGE]: <Message warning content={resultMessage} />,
    [NON_CREDITCARD_INFO_MESSAGE(_.get(currentUser, 'uid', ''))]: (
      <Message warning content={resultMessage} />
    ),
  }[resultMessage]

  const isReady = () => {
    if (
      !currentUser ||
      !number ||
      _.get(offer, 'status', '') === OFFER_DISABLED_STATUS ||
      !user.stripeCustomerId
    ) {
      return false
    }
    return true
  }

  const onSubmit = async () => {
    setIsBusy(true)
    try {
      await functions.httpsCallable('reserveTicket')({
        offerId: match.params.offerId,
        number,
      })
      setResultMessage(SUCCESS_MESSAGE)
    } catch (e) {
      console.error('チケット予約失敗 : ', e)
      setResultMessage(ERROR_MESSAGE)
    }
    setIsBusy(false)
  }

  const handleChangedOnlyNumber = (e) => {
    if (
      !e.target.value.match(/^([0-9\\s])*$/) ||
      e.target.value >
        RESERVE_TICKET_VALIDATION_SCHEMA.properties.number.maximum
    )
      return false
    if (e.target.name === 'number') setNumber(_.toNumber(e.target.value))
  }

  const mainInfo = (
    <>
      <Image src={_.get(offer, 'image', '')} rounded fluid />
      <Divider />

      <Header as='h2' color='teal'>
        <Icon name='discourse' />
        オファーの詳細
      </Header>
      <p style={{ color: '#fff', whiteSpace: 'pre-wrap' }}>
        {_.get(offer, 'description', '')}
      </p>

      <Divider />

      <Header as='h2' color='teal'>
        <Icon name='write' />
        レビュー
      </Header>
      <ReviewList reviews={_.orderBy(reviews, 'createdAt', 'desc')} />
    </>
  )

  const sideInfo = (
    <>
      <Segment>
        <Header as='h3'>
          {`${numeral(offer.price).format('0,0')}円 / ${numeral(
            offer.duration
          ).format('0,0')}分`}
        </Header>
        <Form.Input
          fluid
          label={`予約数(${RESERVE_TICKET_VALIDATION_SCHEMA.properties.number.maximum}個まで)`}
          name='number'
          value={number}
          onChange={(e) => handleChangedOnlyNumber(e)}
          style={{ marginBottom: 10 }}
        />

        <Form.Button
          color='teal'
          fluid
          size='huge'
          disabled={!isReady()}
          onClick={onSubmit}>
          予約する
        </Form.Button>
        <Header as='h5' textAlign='center'>
          {_.get(offer, 'bookedCount', 0)}回、予約・購入されています
        </Header>
      </Segment>

      <Divider hidden />

      <Link to={`/users/${_.get(offer, 'coach.id', '')}`}>
        <Image
          src={_.get(offer, 'coach.image', '')}
          rounded
          style={{ cursor: 'pointer', width: '100%' }}
        />
      </Link>
      <Message>
        <Message.Header>
          <Link to={`/users/${_.get(offer, 'coach.id', '')}`}>
            {_.get(offer, 'coach.nickname', '---')}
          </Link>
        </Message.Header>
        <p style={{ color: 'black', whiteSpace: 'pre-wrap' }}>
          {_.get(offer, 'coach.description', '---')}
        </p>
      </Message>
    </>
  )

  return (
    <>
      {isBusy && (
        <Dimmer active page>
          <Loader>チケット作成中</Loader>
        </Dimmer>
      )}

      {messageType}

      <Header style={{ color: 'rgb(6, 180, 180)' }} as='h1'>
        {' '}
        <Icon name='chess queen' />
        {_.get(offer, 'title', '---')}
      </Header>
      <TagLabels tags={_.get(offer, 'tags', {})} />
      <style>{responsiveStyle}</style>
      <MediaContextProvider>
        <Grid
          as={Media}
          style={{ marginTop: 5, display: 'flex' }}
          greaterThanOrEqual='computer'>
          <Grid.Column floated='left' style={{ flex: 1 }}>
            {mainInfo}
          </Grid.Column>
          <Grid.Column floated='right' style={{ width: 280 }}>
            {sideInfo}
          </Grid.Column>
        </Grid>
        <Grid as={Media} style={{ marginTop: 5 }} lessThan='computer'>
          <Grid.Column floated='left' width={16}>
            {mainInfo}
            {sideInfo}
          </Grid.Column>
        </Grid>
      </MediaContextProvider>
      <div style={{ height: '50px' }} />
    </>
  )
}
