import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Divider, Image } from 'semantic-ui-react'

import {
  responsiveStyle,
  Media,
  MediaContextProvider,
} from '../lib/responsiveStyle'

export default () => {
  const menuContent = (
    <>
      <Menu.Item
        style={{ color: '#fff' }}
        as='a'
        href='https://faq.joz.jp'
        rel='noopener noreferrer'
        target='_blank'>
        Q&A
      </Menu.Item>
      <Menu.Item
        style={{ color: '#fff' }}
        as='a'
        href='https://kiyaku.joz.jp'
        rel='noopener noreferrer'
        target='_blank'>
        利用規約
      </Menu.Item>
      <Menu.Item
        style={{ color: '#fff' }}
        as='a'
        href='/personal-info'
        target='_blank'
        rel='noopener noreferrer'>
        JOZにおける個人情報の取り扱いについて
      </Menu.Item>
      <Menu.Item
        style={{ color: '#fff' }}
        as='a'
        href='/specified-commercial'
        target='_blank'
        rel='noopener noreferrer'>
        特定商取引法に基づく表示
      </Menu.Item>
      <Menu.Item
        style={{ color: '#fff' }}
        as='a'
        href='https://www.bewith.net/company/outline/'
        rel='noopener noreferrer'
        target='_blank'>
        運営会社
      </Menu.Item>
    </>
  )

  return (
    <>
      <Divider hidden />
      <Divider />
      <Link to='/'>
        <Image
          src='/assets/joz_logo.png'
          size='small'
          style={{ cursor: 'pointer' }}
        />
      </Link>
      <style>{responsiveStyle}</style>
      <MediaContextProvider>
        <Menu vertical text as={Media} lessThan='computer'>
          {menuContent}
        </Menu>
        <Menu text as={Media} greaterThanOrEqual='computer'>
          {menuContent}
        </Menu>
      </MediaContextProvider>
      <p style={{ fontSize: 12, color: '#fff' }}>
        本サイトに掲載されている製品またはサービス等の名称は、各社の商標または登録商標です。
        <br />
        「THE KING OF FIGHTERS」は、株式会社SNKの商標または登録商標です。
        <br />
        「FORTNITE」は、Epic Games, Inc.の商標または登録商標です。
        <br />
        「FIFA19」「FIFA20」「Apex Legends」「Battle Field V」は、ElectronicArts
        Inc.の商標または登録商標です。
        <br />
        「ぷよぷよeスポーツ」は株式会社セガの商標または登録商標です。
        <br />
        このサイトは、Electronic Arts
        Inc.またはそのライセンサーと提携またはスポンサーされていません。
        <br />
        This site is not affiliated with or sponsored by Electronic Arts Inc. or
        its licensors.
      </p>
       <Divider hidden />
      <p style={{ color: '#fff' }}>
        Copyright:2019 Bewith,Inc. All rights reserved.
      </p>
    </>
  )
}
