import _ from 'lodash'
import Ajv from 'ajv'
import React, { useContext, useState, useEffect } from 'react'
import { Form, Message, Dimmer, Loader } from 'semantic-ui-react'

import { FirebaseContext } from '../lib/context'
import { TAG_TYPE, GAMETITLE_TAG_NAME } from '../lib/constants'
import { TAG_VALIDATION_SCHEMA } from '../lib/validationSchema'
import { querySnapToObj } from '../lib/misc'

const ajv = new Ajv({ removeAdditional: true })

const TAG_TYPE_OPTIONS = _.map(TAG_TYPE, (s) => ({
  key: s,
  value: s,
  text: s,
}))

const SUCCESS_MESSAGE = 'タグを作成しました。'
const DUPLICATE_WARNING_MESSAGE = '同一タグ名が存在します。'
const ERROR_MESSAGE =
  'タグの作成に失敗しました。しばらく待ってから再度行うか、運営までご連絡下さい。'

export default () => {
  const { db } = useContext(FirebaseContext)
  const [tags, setTags] = useState({})
  const [categories, setCategories] = useState('')

  const [type, setType] = useState('')
  const [label, setLabel] = useState('')
  const [category, setCategory] = useState('')
  const [resultMessage, setResultMessage] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    const tagsUnsub = db
      .collection('tags')
      .onSnapshot((snap) => setTags(querySnapToObj(snap)))
    const categoriesUnsub = db
      .collection('categories')
      .onSnapshot((snap) => setCategories(querySnapToObj(snap)))
    return () => {
      tagsUnsub()
      categoriesUnsub()
    }
  }, [db])

  const messageType = {
    [SUCCESS_MESSAGE]: <Message success content={resultMessage} />,
    [DUPLICATE_WARNING_MESSAGE]: <Message warning content={resultMessage} />,
    [ERROR_MESSAGE]: <Message error content={resultMessage} />,
  }[resultMessage]

  const categoryOptions = _.map(categories, (s) => ({
    key: s.id,
    value: s.name,
    text: s.name,
  }))

  const isReady = () => {
    const validate = ajv.compile(TAG_VALIDATION_SCHEMA)
    return validate(
      type === GAMETITLE_TAG_NAME ? { type, label, category } : { type, label }
    )
  }

  const onSubmit = async () => {
    setIsBusy(true)
    if (!_.find(tags, { category, label })) {
      try {
        await db.collection('tags').add({
          type,
          label,
          category: category ? _.find(categories, { name: category }) : '',
        })
        setCategory('')
        setLabel('')
        setType('')
        setResultMessage(SUCCESS_MESSAGE)
      } catch (e) {
        console.error('タグ作成失敗 : ', e)
        setResultMessage(ERROR_MESSAGE)
      }
    } else setResultMessage(DUPLICATE_WARNING_MESSAGE)
    setIsBusy(false)
  }

  return (
    <>
      {isBusy && (
        <Dimmer active page>
          <Loader>タグ作成中</Loader>
        </Dimmer>
      )}

      {messageType}

      <Form>
        <Form.Select
          placeholder='タグ種別'
          name='type'
          value={type}
          options={TAG_TYPE_OPTIONS}
          onChange={(e, { value }) => setType(value)}
        />
        {type === GAMETITLE_TAG_NAME && (
          <Form.Select
            placeholder={'カテゴリ'}
            name='category'
            value={category}
            options={categoryOptions}
            onChange={(e, { value }) => setCategory(value)}
          />
        )}
        <Form.Input
          icon='tag'
          iconPosition='left'
          placeholder={`タグ名${TAG_VALIDATION_SCHEMA.properties.label.maxLength}文字まで)`}
          name='label'
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          maxLength={TAG_VALIDATION_SCHEMA.properties.label.maxLength}
        />
        <Form.Button
          style={{ marginTop: 20 }}
          disabled={!isReady()}
          primary
          type='submit'
          content='タグ作成'
          onClick={onSubmit}
        />
      </Form>
    </>
  )
}
